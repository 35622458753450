import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CircleIcon from '@mui/icons-material/Circle';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  CardActions,
  Button,
  Stack,
  Grid,
  Chip,
  TextField,
} from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { applicationSelector } from "src/store/slices/applicationFormSlice";
import mixpanel from "src/services/mixpanel";
import { userSelector, saveValue } from "src/store/slices/userSlice";

const headerBackgroundColors = {
  0: "#e0f2f1",
  1: "#f1f8e9",
  2: "#fffde7",
  3: "#fff3e0"
}

export default function Product({ productDetails, screenSize }) {
  // console.log("product")
  const dispatch = useDispatch()
  const user = useSelector(userSelector);
  const [expanded, setExpanded] = useState(false);
  const [feesValue, setFeesValue] = useState(0);
  const { _id } = useSelector(applicationSelector);

  const handleExpandClick = () => {
    mixpanel.track(`Quote: Expand more`, {
      applicationId: _id,
      value: expanded,
      lender: productDetails?.lender,
      productName: productDetails?.productName,
    })
    setExpanded(!expanded);
    if (user.status === "new" && !expanded) {
      dispatch(saveValue({ walkthroughIndex: 4 }))
      mixpanel.track(`Demo: Step 5 - Criteria`)
    }
  };

  const fit = productDetails?.fit
  const qualification = productDetails?.qualification


  function handleCriteriaColor(result) {
    if (result) return "#2e7d32";
    if (result === undefined) return "#999999";
    return "#c62828";
  }

  function extrasStyle(result) {
    if (result) return { color: "inherit" };
    if (result === undefined) return { color: "#999999" };
    return { color: "inherit", textDecoration: "line-through" };
  }

  // function setHeaderBackground() {
  //   // console.log(productDetails.fit);
  //   if (fit === 100) return "#2e7d32";
  //   if (fit < 100 && fit > 80) return "#827717";
  //   if (fit <= 80 && fit > 60) return "#f57f17";
  //   if (fit <= 60 && fit > 40) return "#e65100";

  //   return "#b71c1c";
  // }

  function setHeaderBackground() {
    // console.log(productDetails.fit);
    return headerBackgroundColors[qualification] || "#fbe9e7";
  }


  // function setHeaderBackground() {
  //   // console.log(productDetails.fit);
  //   if (fit === 100) return "#e0f2f1";
  //   if (fit < 100 && fit > 80) return "#f9fbe7";
  //   if (fit <= 80 && fit > 60) return "#fffde7";
  //   if (fit <= 60 && fit > 40) return "#fff3e0";

  //   return "#fbe9e7";
  // }

  const chipColor = {
    ["Standard"]: "#4527a0",
    ["Low doc"]: "#ba68c8",
    ["Light doc"]: "#64b5f6",
    ["Full doc"]: "#607d8b",
    ["Adverse"]: "#3f51b5",
  }

  const dollarStringOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }

  function handleCriteriaIcons(criteria) {
    const iconStyle = { fontSize: "14px", color: handleCriteriaColor(criteria.result) }
    if (criteria.result) return <CheckCircleIcon style={iconStyle} />
    if (criteria.result === undefined) return <RemoveCircleIcon style={iconStyle} />

    return <CancelIcon style={iconStyle} />
  }

  return (
    <>{productDetails &&
      <Card
        className="tenth-step"
        elevation={1}
        style={{
          margin: "0 0 12px",
          // margin: "0 0 -1px",
          // margin: 0,
          background: setHeaderBackground(),
          // background: "white",
          // boxShadow: "none",
          // borderRadius: 0,
          // borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
          // background: setBackground(),
          borderRadius: "8px",
        }}
      // style={{
      //   margin: "0 0 8px",
      //   // margin: "0 0 -1px",
      //   // margin: 0,
      //   background: setHeaderBackground(),
      //   // background: "white",
      //   boxShadow: "none",
      //   borderRadius: 0,
      //   // borderBottom: "1px solid rgba(0, 0, 0, 0.15)"
      //   // background: setBackground()
      // }}
      // style={{ background: productDetails.score ? "#dcedc8" : "#fce4ec" }}
      >


        <CardContent
          style={{
            padding: "0 20px",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ padding: "7px 5px 5px 0" }}
          >
            <Typography
              variant="body2"
              style={{
                marginRight: "20px",
                // display: "flex",
                // justifyContent: "end",
                fontWeight: 600,
                textAlign: "left",
                // padding: "5px 16px",
                // color: setHeaderBackground()
              }}
            >
              {/* <strong> */}
              {/* Fit: */}
              <span>Criteria: {productDetails?.criteria}</span>
              {/* </strong> */}
              {/* Fit: {productDetails.fit * 100}% */}
            </Typography>

            <Stack
              direction="row"
              spacing={1}
            >
              {
                productDetails?.labels.map(label => <Chip
                  key={nanoid()}
                  size="small"
                  label={label}
                  // color={label}
                  // variant="outlined"
                  style={{ padding: "0 2px", height: "22px", color: "#fff", background: chipColor[label] }}
                />)
              }
            </Stack>
          </Stack>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // height: "40px",
              // marginBottom: "10px",
            }}
          >
            <Grid item container justifyContent="space-between" alignItems="center">
              <Grid item xs={screenSize.dynamicWidth < 900 ? 5 : 8} alignItems="center">
                <Stack direction={screenSize.dynamicWidth < 900 ? "column" : "row"} justifyContent="center" alignItems={screenSize.dynamicWidth < 900 ? "start" : "center"}>
                  <Grid item xs={screenSize.dynamicWidth < 900 ? 6 : 4} style={{}} alignItems="center">
                    <img
                      src={productDetails?.logo}
                      style={{
                        width: screenSize.dynamicWidth < 900 ? "100%" : "60%",
                        height: "auto",
                        margin: "auto",
                        display: "flex"
                      }} />
                  </Grid>
                  <Grid item xs={screenSize.dynamicWidth < 900 ? 12 : 8}>
                    <Stack direction="column">
                      {screenSize.dynamicWidth > 900 &&
                        <><Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="subtitle"
                          style={{ fontSize: "14px", fontWeight: "400" }}
                        >
                          {productDetails?.productName}
                        </Typography>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="subtitle"
                            style={{ marginRight: "5px", fontSize: "16px", fontWeight: "700" }}
                          >
                            {productDetails?.lender}
                          </Typography>
                        </>
                      }
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="h5"
                        style={{ marginRight: "5px", marginBottom: "-5px", fontWeight: "700" }}
                      >
                        {productDetails?.repayments?.toLocaleString("en-US", dollarStringOptions)}/mth
                      </Typography>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="caption"
                        style={{ marginRight: "5px", fontSize: "11px" }}
                      >
                        {productDetails?.monthlyFees &&
                          `incl. ${productDetails?.monthlyFees?.value?.toLocaleString("en-US", dollarStringOptions)} monthly fees`
                        }
                      </Typography>
                      {screenSize.dynamicWidth > 900 && <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body1"
                        style={{ marginRight: "5px" }}
                      >
                        {(productDetails?.repayments / 4.33)?.toLocaleString("en-US", dollarStringOptions)}/wk
                      </Typography>}
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={screenSize.dynamicWidth < 900 ? 7 : 4} alignItems="center" >
                {/* <Typography
                  sx={{ display: "inline" }}
                  // component="span"
                  variant="body2"
                  style={{
                    marginRight: "5px",

                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >

                  Finance amount:

                  <span>{productDetails?.financeAmount?.toLocaleString("en-US", dollarStringOptions)}</span>

                </Typography> */}
                <Typography
                  sx={{ display: "inline" }}
                  // component="span"
                  variant="body2"
                  style={{
                    marginRight: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <strong>Rate:</strong> */}
                  Lender rate: <span>{(1 * productDetails?.rate).toFixed(2)}%</span>
                </Typography>
                {productDetails?.comparisonRate && <Typography
                  sx={{ display: "inline" }}
                  // component="span"
                  variant="body2"
                  style={{
                    marginRight: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <strong>Rate:</strong> */}
                  Comparison rate: <span>{(1 * productDetails?.comparisonRate).toFixed(2)}%</span>
                </Typography>
                }


                <Typography
                  sx={{ display: "inline" }}
                  // component="span"
                  variant="body2"
                  style={{
                    marginRight: "5px",

                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Finance amount:
                  <span>{productDetails?.financeAmount?.toLocaleString("en-US", dollarStringOptions)}</span>

                </Typography>
                <Typography
                  sx={{ display: "inline" }}
                  // component="span"
                  variant="body2"
                  style={{
                    marginRight: "5px",

                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >

                  Total loan amount:

                  <span>{productDetails?.loanAmount?.toLocaleString("en-US", dollarStringOptions)}</span>

                </Typography>
                <Typography
                  sx={{ display: "inline" }}
                  variant="body2"
                  color={productDetails?.score ? "#222222" : "aaaaaa"}
                  style={{
                    marginRight: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack direction="column" style={{ justifyContent: "space-between", width: "100%" }}>
                    <Stack direction="row" justifyContent="space-between">
                      <span>Total upfront fees:</span>
                      <span>
                        {productDetails?.feesTotal?.toLocaleString("en-US", dollarStringOptions)}
                      </span>
                    </Stack>
                  </Stack>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* <Divider style={{ margin: "10px 0" }} /> */}

          {/* <Typography variant="body2">Fit: {productDetails.fit}%</Typography> */}
          {/* <Typography
            variant="body2"
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong>Fit:</strong>{" "}
            <span>{productDetails.criteria} criteria</span>
           
          </Typography> */}
          {/* <Typography
            variant="body2"
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <strong>Completion:</strong>{" "}
          </Typography> */}
        </CardContent>
        {/* <Divider style={{ margin: "0 0 10px" }} />
        <Stack spacing={1} direction="row" justifyContent="center">
          <LoadingButton
            color="secondary"
            endIcon={<FileCopyOutlinedIcon />}
            // loading={loadingDownloadUrl}
            loadingPosition="end"
            variant="outlined"
            size="small"
            disabled={
              !application?.entities?.[0] || !application?.customers?.[0]
            }
            onClick={async () => {
              // setLoadingDownloadUrl(true);
              if (!url) {
                await saveQuote();
                await dispatch(
                  getApplicationDownloadUrl(application._id)
                ).unwrap();
              }
              // setLoadingDownloadUrl(false);
            }}
            href={url}
          >
            Download
          </LoadingButton>

          <LoadingButton
            color="secondary"
            endIcon={<SendOutlinedIcon />}
            // loading={loadingSubmitApplication}
            loadingPosition="end"
            variant="contained"
            size="small"
            disabled={
              !application?.entities?.[0] || !application?.customers?.[0]
            }
            onClick={async () => {
              // dispatch(saveValue({ loadingSubmitApplication: true }));
              await saveQuote();
              await dispatch(submitApplication(application._id)).unwrap();
              // setSubmitSuccess(true);
            }}
          >
            Submit
          </LoadingButton>
        </Stack> */}
        {/* <Divider style={{ margin: "10px 0 0" }} /> */}
        <CardActions
          disableSpacing
          style={{
            justifyContent: "end",
            padding: "3px 20px",

          }}
        >
          <Button
            className="fifth-step"
            size="small"
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            // variant="text"
            // color="#424242"
            style={{ padding: "0 0 0 6px", minWidth: "auto", fontWeight: "bold", }}
          >
            {/* {expanded ? "Hide criteria " : "Show criteria"} */}
            {expanded ? <>Hide <ExpandLessIcon /></> : <>Show criteria  <ExpandMoreIcon /></>}

          </Button>
          {/* <Typography
            variant="caption"
            style={{
              // marginRight: "20px",
              // display: "flex",
              // justifyContent: "end",
              // fontWeight: 600,
              textAlign: "left",
              // padding: "5px 16px",
              // color: setHeaderBackground()
            }}
          >
            Last updated: {productDetails?.date}
          </Typography> */}
          {/* <Button
            size="small"
            expand={expanded}
            onClick={() => {
              if (isEditMode) saveQuote();
              setIsEditMode(!isEditMode);
            }}
            aria-expanded={expanded}
            aria-label="show more"
            // variant="text"
            // color="#424242"
            style={{ color: "#4a148c", padding: 0, minWidth: "auto" }}
          >
            {isEditMode ? "Done" : "Edit"}
          </Button> */}
        </CardActions>
        {/* <Divider style={{ margin: "0" }} /> */}
        <Collapse in={expanded} timeout="auto" unmountOnExit>

          <CardContent style={{ padding: "5px 20px 10px" }}>
            <Grid item container spacing={3} >
              <Grid item className="sixth-step" xs={screenSize.dynamicWidth < 900 ? 7 : 4}>

                <Typography variant="subtitle">
                  <strong>Criteria</strong>
                </Typography>
                {/* 
                  <ul
                    style={{
                      marginLeft: "24px",
                    }}
                  > 
                */}
                {productDetails?.criteriaResults
                  ?.sort((x, y) => {
                    if (x.result && x.result !== y.result) return -1;
                    if (x.result === undefined && y.result) return 1;
                    if (y.result === undefined && !x.result) return 1;
                    if (x.result === y.result) return 0;
                    if (!y.result) return -1;
                  }) // sort from true to false
                  ?.map((criteria) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                    >
                      {handleCriteriaIcons(criteria)}
                      <Typography
                        variant="body2"
                        style={{ marginLeft: "5px" }}
                      >
                        {criteria.label}
                      </Typography>
                    </Stack>
                  ))}
              </Grid>
              {(productDetails?.loading?.loadingList?.[0] ||
                // (!productDetails?.isCommercial && productDetails?.fees?.[0]))
                productDetails?.fees?.[0])
                && (<Grid item xs={screenSize.dynamicWidth < 900 ? 5 : 4}>
                  <Typography variant="subtitle">
                    <strong>Extras</strong>
                  </Typography>

                  <>
                    {/* <ul style={{ marginLeft: "24px" }}> */}
                    {productDetails?.loading?.loadingList
                      ?.sort((x, y) => {
                        if (x.result && x.result !== y.result) return -1;
                        if (x.result === undefined && y.result) return 1;
                        if (y.result === undefined && !x.result) return 1;
                        if (x.result === y.result) return 0;
                        if (!y.result) return -1;
                      })
                      ?.map((load) => (
                        <Stack
                          // key={fee?.name}
                          direction="row"
                          justifyContent="space-between"
                          style={extrasStyle(load.result)}
                        >
                          <Typography
                            // key={load?.name}
                            variant="body2"
                          // color={handleCriteriaColor(load.result)}

                          >

                            {load?.name}:
                          </Typography>
                          <Typography
                            // key={load?.name}
                            variant="body2"
                          // color={handleCriteriaColor(load.result)}
                          >

                            {load?.value}%
                          </Typography>
                        </Stack>
                      ))}
                    {/* {!productDetails?.isCommercial && productDetails?.fees?.map((fee) => */}
                    {productDetails?.fees?.map((fee) =>
                      <Stack
                        // key={fee?.name}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body2"
                        // color={handleCriteriaColor(load.result)}
                        >{fee?.name}:</Typography>
                        <Typography
                          variant="body2"
                        // color={handleCriteriaColor(load.result)}
                        >{fee?.value?.toLocaleString("en-US", dollarStringOptions)}</Typography>
                      </Stack>)}
                    {/* </ul> */}
                    {/* <Divider style={{ margin: "10px" }} /> */}
                  </>


                </Grid>)}

              <Grid item xs={productDetails?.loading?.loadingList?.[0] || productDetails?.fees?.[0] ? screenSize.dynamicWidth < 900 ? 12 : 4 : screenSize.dynamicWidth < 900 ? 12 : 7}>
                {productDetails?.info?.[0] && (
                  <>
                    <Typography variant="subtitle">
                      <strong>Info & Requirements</strong>
                    </Typography>
                    <ul style={{ fontSize: "12.5px", listStyle: "inside", textAlign: "justify" }}>
                      {productDetails?.info?.map(info =>
                        // <Typography
                        //   variant="body2"
                        //   style={{ fontSize: "0.75rem" }}
                        // >
                        //   - {info}
                        // </Typography>
                        <li>{info}</li>
                      )}
                    </ul>
                  </>
                )}
              </Grid>


              {/* </ul> */}

            </Grid>
            <Box style={{
              margin: "10px 0 0",
              // display: "flex",
              // justifyContent: "end",
              // fontWeight: 600,
              textAlign: "left",
              // padding: "5px 16px",
              // color: setHeaderBackground()
            }}>
              <Typography
                variant="caption"

              >
                Lender rates as of: {productDetails?.date}
              </Typography>
            </Box>
          </CardContent>
        </Collapse>
      </Card>}
    </>

  );
}
