import { typeOfSaleList, livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

const {
  PROPERTY_OWNER,
  RENTING,
  BOARDING,
  LIVING_WITH_PARENTS
} = livingArrangementsTypes

const {
  SELF_EMPLOYED,
  FULL_TIME,
  PART_TIME,
  CONTRACT,
  CASUAL,
  BENEFITS
} = employmentTypes

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  ageOfAssetNewOrDemo,
  typeOfSale,
  hasDeposit,
  dateOfBirthBetween,
  dateOfBirthGreaterThan,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  compound
} = criteria

const base = [
  {
    name: "Auto <12 months",
    rate: 6.84,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 7.35,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 7.70,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(36, 155),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },


  {
    name: "Auto <12 months",
    rate: 7.15,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 7.75,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 8.30,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(36, 155),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },


  {
    name: "Auto <12 months",
    rate: 7.50,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 8.50,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 9.05,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(36, 155),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },





  {
    name: "Auto <12 months",
    rate: 8.00,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 9.40,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 9.95,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(36, 155),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },


  {
    name: "Auto <12 months",
    rate: 8.60,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(10),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 10.00,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(10),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 10.55,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(36, 155),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(10),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },


  {
    name: "Auto <12 months",
    rate: 9.35,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 10.75,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 11.30,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(36, 155),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
]

const otherAssetTypes = [
  {
    name: "Auto <12 months",
    rate: 6.80,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 7.40,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 7.95,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(36, 155),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },


  {
    name: "Auto <12 months",
    rate: 8.25,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 9.65,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(12, 36),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
  {
    name: "Auto <12 months",
    rate: 10.20,
    productType: "Standard",
    criteria: [
      ageOfAssetBetween(36, 155),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
]

const luxuryBase = [
  {
    name: "Auto <12 months",
    rate: 6.84,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },


  {
    name: "Auto <12 months",
    rate: 7.15,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(10),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },


  {
    name: "Auto <12 months",
    rate: 7.50,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([PROPERTY_OWNER]),
      creditScoreGreaterThan(499)
    ],
  },


  {
    name: "Auto <12 months",
    rate: 8.00,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(20),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },


  {
    name: "Auto <12 months",
    rate: 8.60,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      hasDeposit(10),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },


  {
    name: "Auto <12 months",
    rate: 9.35,
    productType: "Standard",
    criteria: [
      ageOfAssetLessThan(12),
      ageOfAssetAtEndLessThan(13),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      creditScoreGreaterThan(499)
    ],
  },
]

const auto18_21 = base.map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthBetween(18, 21),
      loanAmountBetween(10000, 40000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike",]),]
  }
})

const auto21_24 = base.map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthBetween(21, 24),
      loanAmountBetween(10000, 50000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike",]),
    ]
  }
})

const auto24Up = base.map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthGreaterThan(24),
      loanAmountBetween(10000, 100000),
      assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike",]),
    ]
  }
})

const luxury = luxuryBase.map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthGreaterThan(24),
      loanAmountBetween(10000, 250000),
      assetType(["Luxury Vehicle"]),
    ]
  }
})

const otherAssetTypes18_21 = otherAssetTypes.map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthBetween(18, 21),
      loanAmountBetween(10000, 40000),
      assetType(["Trailer", "Caravan", "Motor home"]),
    ]
  }
})

const otherAssetTypes21_24 = otherAssetTypes.map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthBetween(21, 24),
      loanAmountBetween(10000, 50000),
      assetType(["Trailer", "Caravan", "Motor home"]),
    ]
  }
})

const otherAssetTypes24Up = otherAssetTypes.map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      dateOfBirthGreaterThan(24),
      loanAmountBetween(10000, 100000),
      assetType(["Trailer", "Caravan", "Motor home"]),
    ]
  }
})

const fullTimePartTime = [
  ...auto18_21,
  ...auto21_24,
  ...auto24Up,
  ...luxury,
  // ...otherAssetTypes18_21,
  // ...otherAssetTypes21_24,
  // ...otherAssetTypes24Up
].map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      employmentType([FULL_TIME, PART_TIME])
    ]
  }
})

const casual = [
  ...auto18_21,
  ...auto21_24,
  ...auto24Up,
  ...luxury,
  // ...otherAssetTypes18_21,
  // ...otherAssetTypes21_24,
  // ...otherAssetTypes24Up
].map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      employmentType([CASUAL]), timeOfEmploymentGreaterThan(6)
    ]
  }
})

const SEContractor = [
  ...auto18_21,
  ...auto21_24,
  ...auto24Up,
  ...luxury,
  // ...otherAssetTypes18_21,
  // ...otherAssetTypes21_24,
  // ...otherAssetTypes24Up
].map(product => {
  product.criteria.push()

  return {
    ...product,
    criteria: [
      ...product.criteria,
      employmentType([SELF_EMPLOYED, CONTRACT]), timeOfEmploymentGreaterThan(24)
    ]
  }
})

export default {
  date: "26 Jul 2023",
  lender: "Macquarie",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fmacquarie_logo.gif",
  fees: [
    {
      name: "Establishment fee",
      value: 250,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Monthly account fee",
      value: 8.25,
      capitalised: true,
      frequency: "monthly",
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    `
     Maximum net amount financed within a 6 month period of the 
     settlement date (or approval date if not settled) - $100,000 
    `,
    ` Maximum net amount financed $200,000 for Existing customer 
      with current account including non property owner) to be eligible:
    `,
    `Must have a minimum of 24 months history, with gaps of no more than 6 months between contracts and; 
      must have current exposure or have finalised a contract no more than 6 months prior to submission `,
    `Luxury motor vehicles: new/demo Ferrari, Maserati, McLaren, Porsche, Bentley, 
Lamborghini, Tesla Motor Vehicles and Mercedes)`
  ],
  productTiers: [
    ...fullTimePartTime,
    ...casual,
    ...SEContractor
  ]
};

