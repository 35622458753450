import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, TextField } from "@mui/material";
import { employmentTypes } from "src/constants";
import { customersSelector, saveEmployer, updateEmployer } from "src/store/slices/applicationFormSlice";
import { useState } from "react";
import { nanoid } from "@reduxjs/toolkit";

import { useParams } from "react-router-dom";
import { handleMixpanelQuoteEvent } from "src/utils/mixPanelTrackEvents";
import { saveValue, userSelector } from "src/store/slices/userSlice";
import mixpanel from "src/services/mixpanel";

export default function EmploymentType() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector)
  const { applicationId } = useParams();
  const customers = useSelector(customersSelector);
  // const [employmentType, setEmploymentType] = useState(customers?.[0]?.employers[0]?.employmentType);


  function handleUpdateEmployment(event) {
    handleMixpanelQuoteEvent(applicationId, "employment type", event.target.value)

    dispatch(saveEmployer({ employmentType: event.target.value }))
    dispatch(
      updateEmployer({
        employerId: customers[0]?.employers[0]?._id,
        employmentType: event.target.value
      })
    );
    if (user?.status === "new" && event.target.value === "Full-time") {
      dispatch(saveValue({ walkthroughIndex: 8 }))
      mixpanel.track(`Demo: Step 9 - Finish`)
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 400)
    }
  };

  return <TextField
    fullWidth
    className="ninth-step"
    style={{ background: "#ffffff" }}
    select
    value={customers?.[0]?.employers[0]?.employmentType || ""}
    label="Employment type"
    size="small"
    onChange={handleUpdateEmployment}
  >
    {Object.keys(employmentTypes).map((type, i) => (
      <MenuItem
        key={nanoid()}
        // default={i === 0 && employmentTypes[type]} 
        value={employmentTypes[type]}
      >
        {employmentTypes[type]}
      </MenuItem>
    ))
    }
  </TextField >
}
