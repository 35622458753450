import React, { useEffect, useState } from "react";
// import io from "socket.io-client";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import store from "./store";
// routes
import Router from "./routes";
// theme
// components
import { version } from '../package.json';

import { purple, cyan, blueGrey } from "@mui/material/colors";
import CacheBuster from "react-cache-buster";

// import colours from "./style/color";

// ----------------------------------------------------------------------
let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "rgb(10,18,30)",
    },
    secondary: {
      main: "#6A0BE3",
    },

    background: {
      default: "#fbfbfb",
      paper: "#ffffff",
    },
  },

  typography: {
    body1: {
      letterSpacing: "0.5px",
    },
    h1: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h2: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h3: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h4: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h5: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    h6: {
      fontFamily: [
        "Cabin",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  // buttons: {
  //   fontFamily: [
  //     "Cabin",
  //     "-apple-system",
  //     "BlinkMacSystemFont",
  //     '"Segoe UI"',
  //     "Roboto",
  //     '"Helvetica Neue"',
  //     "Arial",
  //     "sans-serif",
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(","),
  // },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderWidth: "10px",
          borderColor: "rgba(0, 0, 0, 0.9)",
        },
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       textTransform: "lowercase",
    //       fontWeight: 500,
    //       fontSize: "1rem",
    //       letterSpacing: "0.1px"
    //     },
    //   },
    // },
  },
});

theme = responsiveFontSizes(theme);

export default function App() {
  // const [socket, setSocket] = useState(null);

  // useEffect(() => {
  // const newSocket = io(process.env.REACT_APP_API_URL, {
  //   transports: ["websocket"],
  // });
  // setSocket(newSocket);
  // return () => newSocket.close();
  // }, [setSocket]);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Router

              //  socket={socket}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </CacheBuster>
  );
}
