import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
// import MuiAppBar from "@mui/material/AppBar";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import MoreIcon from "@mui/icons-material/MoreVert";
import color from "src/style/color";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Box, Button, Divider, Link, styled } from "@mui/material";
import StartApplication from "src/components/StartApplication";
import { useDispatch, useSelector } from "react-redux";
import {
  saveValue,
  resetState,
  userSelector,
} from "src/store/slices/userSlice";
import { resetState as resetOrganisationState } from "src/store/slices/organisationSlice"
import { resetState as resetListsState } from "src/store/slices/listsSlice"
import {
  AccountCircle,
  ArrowDownward,
  ArrowDropDown,
  ExpandMore,
} from "@mui/icons-material";

import afsLogo from "../afs-blue.png";
import mixpanel from "mixpanel-browser";

// import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  title: {
    display: "block",
    // [theme.breakpoints.up("sm")]: {
    //   display: "block",
    // },
  },
  // search: {
  //   position: "relative",
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   "&:hover": {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  //   marginRight: theme.spacing(2),
  //   marginLeft: 0,
  //   width: "100%",
  //   [theme.breakpoints.up("sm")]: {
  //     marginLeft: theme.spacing(3),
  //     width: "auto",
  //   },
  // },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: "20ch",
    // },
  },
  sectionDesktop: {
    display: "none",
    // [theme.breakpoints.up("md")]: {
    //   display: "flex",
    // },
  },
  sectionMobile: {
    display: "flex",
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
  },
}));

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

export default function TopMenu({ open }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const user = useSelector(userSelector);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleDrawerOpen() {
    dispatch(saveValue({ open: true }));
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    ></Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        open={open}
        position="fixed"
        elevation={1}
        style={{
          background: "#fff",
          borderBottom: "1px solid rgba(213, 213, 213, 1)",
          zIndex: 10,
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow: "none"
        }}
      // background="primary"
      >
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}

          {/* <img
            style={{
              height: "44px",
              imageRendering: "-webkit-optimize-contrast",
            }}
            src={afsLogo}
          /> */}

          <Typography
            noWrap
            className={classes.title}
            variant="h6"
            style={{ fontWeight: 500, marginRight: "70px", letterSpacing: "-0.2px" }}
          >
            Financeable <span style={{ verticalAlign: "sub", fontSize: 12 }}>(in beta)</span>
          </Typography>
          {window.innerWidth > 700 && <>
            <List
              component="nav"
              aria-label="main mailbox folders"
              style={{ display: "flex", flexDirection: "row", padding: 0 }}
            >
              <ListItem
                button
                // style={{ padding: 0 }}
                onClick={() => navigate("/quotes")}
              >
                {/* <ListItemIcon><InboxIcon /></ListItemIcon> */}
                <ListItemText primary="Quotes" primaryTypographyProps={{ style: { fontWeight: 500 } }} />
              </ListItem>
              
              {/* <ListItem button>
              {/* <ListItemIcon><DraftsIcon /></ListItemIcon> */}
              {/* <ListItemText
                primary="Customers"
                onClick={() => navigate("/dashboard/customers")}
              /> */}
              {/* </ListItem> */}
            </List>
            {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div> */}
            <div className={classes.grow} />
            {/* <Button onClick={() => navigate("/subscribe/start")}>
            Subscribe
          </Button> */}
            {/* <div className={classes.sectionDesktop}> */}
            {/* <Button
              color="secondary"
              variant="contained"
              size="small"
              disabled
              // onClick={async () => {
              //   console.log("start application");
              //   dispatch(startApplication());
              // }}
              style={{ marginRight: "30px" }}
            >
              Quick referral
            </Button> */}
            <StartApplication />
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              color="#444746"
              style={{ marginRight: "20px" }}
            />
            <Link
              id="menu-button"
              aria-controls={isMenuOpen ? "menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? "true" : undefined}
              onClick={handleProfileMenuOpen}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#444746",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <AccountCircle style={{ marginRight: "5px" }} />
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Typography style={{ marginRight: "5px", lineHeight: 1.4 }}>
                    {user?.firstName}
                  </Typography>
                  <Typography style={{ lineHeight: 1.4 }}>
                    {user?.lastName}
                  </Typography>
                </Box>
                <Typography style={{ fontSize: "10px", lineHeight: 1 }}>
                  {user?.organisation?.name}
                </Typography>
              </Box>
              {/* <IconButton
              // size="large"
              edge="end"
              aria-label="account of current user"
              // aria-controls={menuId}
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              color="inherit"
            > */}

              <ArrowDropDown />

              {/* </IconButton> */}
            </Link>
            <Menu
              id="basic-menu"
              aria-labelledby="menu-button"
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              // transformOrigin={{
              //   vertical: "bottom",
              //   horizontal: "center",
              // }}
              // MenuListProps={{
              //   "aria-labelledby": "menu-button",
              // }}
              style={{ zIndex: 1000000000 }}
            >
              <NavLink to="/account/profile">
                <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
              </NavLink>

              {/* {user.userType === "admin" && (
              <NavLink to="/account/organisation">
                <MenuItem onClick={handleMenuClose}>Organisation</MenuItem>
              </NavLink>
            )} */}
              {/* <NavLink to="/account/billing">
              <MenuItem onClick={handleMenuClose}>Billing</MenuItem>
            </NavLink>
            <NavLink to="/account/settings">
              <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
            </NavLink> */}
              <MenuItem
                onClick={() => {
                  localStorage.removeItem("token");
                  mixpanel.track('User logout: User logged out successfully');
                  mixpanel.reset();
                  navigate("/login");
                  dispatch(resetState());
                  dispatch(resetOrganisationState())
                  dispatch(resetListsState())
                }}
              >
                Logout
              </MenuItem>

            </Menu>
          </>}
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {/* {renderMenu} */}
    </div>
  );
}
