import {
  createSlice,
  configureStore,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import _, { merge } from "lodash";

console.log(process.env.REACT_APP_API_URL);

function headers(token) {
  if (token) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

export const startApplication = createAsyncThunk(
  "application/start",
  async (applicationType, { rejectWithValue, getState }) => {
    // const token = localStorage.token;
    const token = localStorage.token;
    // console.log("user", user.user);
    try {
      const newApplication = await axios({
        url: `${process.env.REACT_APP_API_URL}/application/start/quote/${applicationType}`,
        method: "GET",
        headers: headers(token),
      });
      // console.log("newApplication:", newApplication);
      if (newApplication.status === "error") {
        console.log("userResponse", newApplication);
        return rejectWithValue(newApplication);
      }
      return newApplication;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getApplication = createAsyncThunk(
  "application/",
  async (applicationId, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    try {
      const application = await axios({
        url: `${process.env.REACT_APP_API_URL}/application/${applicationId}`,
        method: "GET",
        headers: headers(token),
      });
      // console.log("application:", application);
      if (application.status === "error") {
        console.log("application", application);
        return rejectWithValue(application);
      }
      return application;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const submitApplication = createAsyncThunk(
  "application/submit",
  async (applicationId, { rejectWithValue, getState }) => {
    try {
      const token = localStorage.token;
      const application = await axios({
        url: `${process.env.REACT_APP_API_URL}/application/submit/${applicationId}`,
        method: "GET",
        headers: headers(token),
      });
      // console.log("application:", application);
      if (application.status === "error") {
        console.log("application", application);
        return rejectWithValue(application);
      }
      return application;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const referQuote = createAsyncThunk(
  "application/submit",
  async (data, { rejectWithValue, getState }) => {
    try {
      const token = localStorage.token;
      const application = await axios({
        url: `${process.env.REACT_APP_API_URL}/application`,
        method: "PUT",
        headers: headers(token),
        data
      });
      // console.log("application:", application);
      if (application.status === "error") {
        console.log("application", application);
        return rejectWithValue(application);
      }
      return application;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getApplicationDownloadUrl = createAsyncThunk(
  "application/url",
  async (applicationId, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    try {
      console.log("getting download URL");
      const application = await axios({
        url: `${process.env.REACT_APP_API_URL}/application/url/${applicationId}`,
        method: "GET",
        headers: headers(token),
      });
      // console.log("application:", application);
      if (application.status === "error") {
        console.log("application url", application);
        return rejectWithValue(application);
      }
      return application;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateAsset = createAsyncThunk(
  "asset/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    console.log("update asset: ", data);
    try {
      const asset = await axios({
        url: `${process.env.REACT_APP_API_URL}/asset/`,
        method: "POST",
        headers: headers(token),
        data,
      });
      console.log("asset:", asset);
      if (asset.status === "error") {
        // console.log("asset", asset);
        return rejectWithValue(asset);
      }

      return asset;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateLoanDetails = createAsyncThunk(
  "loanDetails/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    console.log("update loanDetails: ", data);
    try {
      const loanDetails = await axios({
        url: `${process.env.REACT_APP_API_URL}/loanDetails/`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("loanDetails:", loanDetails);
      if (loanDetails.status === "error") {
        console.log("loanDetails", loanDetails);
        return rejectWithValue(loanDetails);
      }

      return loanDetails;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  "entity/create",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    try {
      const application = await axios({
        url: `${process.env.REACT_APP_API_URL}/entity/`,
        method: "POST",
        headers: headers(token),
        data,
      });
      console.log("application:", application);
      if (application.status === "error") {
        console.log("application", application);
        return rejectWithValue(application);
      }

      return application;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(
  "entity/get",
  async (entityId, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    try {
      const entity = await axios({
        url: `${process.env.REACT_APP_API_URL}/entity/${entityId}`,
        method: "GET",
        headers: headers(token),
      });
      console.log("entity:", entity);
      if (entity.status === "error") {
        console.log("entity", entity);
        return rejectWithValue(entity);
      }

      return entity;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getASICData = createAsyncThunk(
  "entity/asic-data",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    try {
      const entity = await axios({
        url: `${process.env.REACT_APP_API_URL}/entity/asic-data`,
        method: "POST",
        headers: headers(token),
        data,
      });
      console.log("entity:", entity);
      if (entity.status === "error") {
        console.log("entity", entity);
        return rejectWithValue(entity);
      }

      return entity;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateEntity = createAsyncThunk(
  "entity/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    console.log(data);
    try {
      const entity = await axios({
        url: `${process.env.REACT_APP_API_URL}/entity`,
        method: "PUT",
        headers: headers(token),
        data,
      });
      console.log("entity:", entity);
      if (entity.status === "error") {
        console.log("entity", entity);
        return rejectWithValue(entity);
      }

      return entity;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getCompanyProfile = createAsyncThunk(
  "entity/update",
  async ({ ACN }, { rejectWithValue, getState }) => {
    console.log(ACN);
    const token = localStorage.token;
    try {
      const entity = await axios({
        url: `${process.env.REACT_APP_API_URL}/entity/search?acn=${ACN}`,
        method: "GET",
        headers: headers(token),
      });
      console.log("entity:", entity);
      if (entity.status === "error") {
        console.log("entity", entity);
        return rejectWithValue(entity);
      }

      return entity;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateEmployer = createAsyncThunk(
  "employer/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    console.log(data);
    try {
      const employer = await axios({
        url: `${process.env.REACT_APP_API_URL}/employer`,
        method: "PUT",
        headers: headers(token),
        data,
      });
      console.log("employer:", employer);
      if (employer.status === "error") {
        console.log("employer", employer);
        return rejectWithValue(employer);
      }

      return employer;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customer/create",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("user_id:", data);
    // console.log("url:", process.env.REACT_APP_API_URL);
    try {
      const newCustomer = await axios({
        url: `${process.env.REACT_APP_API_URL}/customer/`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("newCustomer:", newCustomer);
      if (newCustomer.status === "error") {
        // console.log("newCustomer", newCustomer);
        return rejectWithValue(newCustomer);
      }
      return newCustomer;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getCustomer = createAsyncThunk(
  "customer/get",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      const customer = await axios({
        url: `${process.env.REACT_APP_API_URL}/customer/${data}`,
        method: "GET",
        headers: headers(token),
      });
      // console.log("customer:", customer);
      if (customer.status === "error") {
        // console.log("customer", customer);
        return rejectWithValue(customer);
      }
      return customer;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      const customer = await axios({
        url: `${process.env.REACT_APP_API_URL}/customer/`,
        method: "PUT",
        headers: headers(token),
        data,
      });
      // console.log("customer:", customer);
      if (customer.status === "error") {
        // console.log("customer", customer);
        return rejectWithValue(customer);
      }
      return customer;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const removeCustomer = createAsyncThunk(
  "customer/remove",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      const customer = await axios({
        url: `${process.env.REACT_APP_API_URL}/customer/remove`,
        method: "PUT",
        headers: headers(token),
        data,
      });
      // console.log("customer:", customer);
      if (customer.status === "error") {
        // console.log("customer", customer);
        return rejectWithValue(customer);
      }
      return customer;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const startVerification = createAsyncThunk(
  "identity-verification/start",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("user_id:", data);
    // console.log("url:", process.env.REACT_APP_API_URL);
    try {
      const newCustomer = await axios({
        url: `${process.env.REACT_APP_API_URL}/identity-verification/send`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("newCustomer:", newCustomer);
      if (newCustomer.status === "error") {
        // console.log("newCustomer", newCustomer);
        return rejectWithValue(newCustomer);
      }
      return newCustomer;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getIdentityVerification = createAsyncThunk(
  "identity-verification/get",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      const customer = await axios({
        url: `${process.env.REACT_APP_API_URL}/identity-verification/search`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("customer:", customer);
      if (customer.status === "error") {
        // console.log("customer", customer);
        return rejectWithValue(customer);
      }
      return customer;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getDocuments = createAsyncThunk(
  "document/get",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      const document = await axios({
        url: `${process.env.REACT_APP_API_URL}/document/${data}`,
        method: "GET",
        headers: headers(token),
      });
      // console.log("document:", document);
      if (document.status === "error") {
        // console.log("document", document);
        return rejectWithValue(document);
      }
      return document;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getFileUrl = createAsyncThunk(
  "document/file",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("document data:", data);
    try {
      const url = await axios({
        url: `${process.env.REACT_APP_API_URL}/document/file`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("url:", url);
      if (url.status === "error") {
        // console.log("url", url);
        return rejectWithValue(url);
      }
      return url;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getCreditFile = createAsyncThunk(
  "credit-profile/file",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      const file = await axios({
        url: `${process.env.REACT_APP_API_URL}/credit-profile/file`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("file:", file);
      if (file.status === "error") {
        // console.log("file", file);
        return rejectWithValue(file);
      }
      return file;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getCreditProfile = createAsyncThunk(
  "credit-profile/get",
  async ({ customerId, applicationId }, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log({ customerId, applicationId });
    try {
      const file = await axios({
        url: `${process.env.REACT_APP_API_URL}/credit-profile/?cid=${customerId}&aid=${applicationId}`,
        method: "GET",
        headers: headers(token),
      });
      // console.log("file:", file);
      if (file.status === "error") {
        // console.log("file", file);
        return rejectWithValue(file);
      }
      return file;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const submitPrivacy = createAsyncThunk(
  "document/privacy",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      const privacy = await axios({
        url: `${process.env.REACT_APP_API_URL}/document/privacy`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("privacy:", privacy);
      if (privacy.status === "error") {
        // console.log("privacy", privacy);
        return rejectWithValue(privacy);
      }
      return privacy;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const sendPrivacyLink = createAsyncThunk(
  "document/privacy/link",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      const privacy = await axios({
        url: `${process.env.REACT_APP_API_URL}/document/privacy/link`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("privacy:", privacy);
      if (privacy.status === "error") {
        // console.log("privacy", privacy);
        return rejectWithValue(privacy);
      }
      return privacy;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const sendBankStatementsLink = createAsyncThunk(
  "bankStatements/link",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      const privacy = await axios({
        url: `${process.env.REACT_APP_API_URL}/bank-statements/link`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("privacy:", privacy);
      if (privacy.status === "error") {
        // console.log("privacy", privacy);
        return rejectWithValue(privacy);
      }
      return privacy;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getAddress = createAsyncThunk(
  "address/get",
  async (addressId, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("addressId:", addressId);
    try {
      const address = await axios({
        url: `${process.env.REACT_APP_API_URL}/address/${addressId}`,
        method: "GET",
        headers: headers(token),
      });
      // console.log("address:", address);
      if (address.status === "error") {
        // console.log("address", address);
        return rejectWithValue(address);
      }
      return address;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const addAddress = createAsyncThunk(
  "address/add",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      if (data) {
        const address = await axios({
          url: `${process.env.REACT_APP_API_URL}/address`,
          method: "POST",
          headers: headers(token),
          data,
        });
        // console.log("address:", address);
        if (address.status === "error") {
          // console.log("address", address);
          return rejectWithValue(address);
        }
        return address;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const addPhoneNumber = createAsyncThunk(
  "phoneNumber/add",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      const customer = await axios({
        url: `${process.env.REACT_APP_API_URL}/phone-number`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("customer:", customer);
      if (customer.status === "error") {
        // console.log("customer", customer);
        return rejectWithValue(customer);
      }
      return customer;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const addEmail = createAsyncThunk(
  "email/add",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      if (data) {
        const customer = await axios({
          url: `${process.env.REACT_APP_API_URL}/email`,
          method: "POST",
          headers: headers(token),
          data,
        });
        // console.log("customer:", customer);
        if (customer.status === "error") {
          // console.log("customer", customer);
          return rejectWithValue(customer);
        }
        return customer;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const saveCustomerProfile = createAsyncThunk(
  "customerProfile/add",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    console.log("data:", data);
    try {
      if (data) {
        const customerProfile = await axios({
          url: `${process.env.REACT_APP_API_URL}/customer-profile`,
          method: "POST",
          headers: headers(token),
          data,
        });
        // console.log("customer:", customer);
        if (customerProfile.status === "error") {
          // console.log("customerProfile", customerProfile);
          return rejectWithValue(customerProfile);
        }
        return customerProfile;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const saveCreditProfile = createAsyncThunk(
  "creditProfile/add",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    console.log("data:", data);
    try {
      if (data) {
        const creditProfile = await axios({
          url: `${process.env.REACT_APP_API_URL}/credit-profile`,
          method: "POST",
          headers: headers(token),
          data,
        });
        // console.log("customer:", customer);
        if (creditProfile.status === "error") {
          // console.log("customerProfile", customerProfile);
          return rejectWithValue(creditProfile);
        }
        return creditProfile;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateAssets = createAsyncThunk(
  "assets/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      if (data) {
        const assets = await axios({
          url: `${process.env.REACT_APP_API_URL}/customer-assets`,
          method: "PUT",
          headers: headers(token),
          data,
        });
        // console.log("customer:", customer);
        if (assets.status === "error") {
          // console.log("assets", assets);
          return rejectWithValue(assets);
        }
        return assets;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateNotes = createAsyncThunk(
  "notes/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    console.log("data:", data);
    try {
      if (data) {
        const notes = await axios({
          url: `${process.env.REACT_APP_API_URL}/notes`,
          method: "POST",
          headers: headers(token),
          data,
        });
        // console.log("customer:", customer);
        if (notes.status === "error") {
          // console.log("notes", notes);
          return rejectWithValue(notes);
        }
        return notes;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const uploadInvoice = createAsyncThunk(
  "asset/upload",
  async ({ formData, assetId }, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    console.log("data:", formData);
    const formDataHeaders = headers(token);
    formDataHeaders[
      "Content-Type"
    ] = `multipart/form-data; boundary=${formData._boundary}`;
    delete formDataHeaders.Accept;
    // console.log(formDataHeaders);
    try {
      if (formData) {
        const asset = await axios({
          url: `${process.env.REACT_APP_API_URL}/asset/upload/${assetId}`,
          method: "POST",
          headers: formDataHeaders,
          data: formData,
        });
        // console.log("customer:", customer);
        if (asset.status === "error") {
          // console.log("asset", asset);
          return rejectWithValue(asset);
        }
        return asset;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateLiabilities = createAsyncThunk(
  "liabilities/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      if (data) {
        const liabilities = await axios({
          url: `${process.env.REACT_APP_API_URL}/liabilities`,
          method: "PUT",
          headers: headers(token),
          data,
        });
        // console.log("customer:", customer);
        if (liabilities.status === "error") {
          // console.log("liabilities", liabilities);
          return rejectWithValue(liabilities);
        }
        return liabilities;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateExpenses = createAsyncThunk(
  "expenses/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      if (data) {
        const expenses = await axios({
          url: `${process.env.REACT_APP_API_URL}/expenses`,
          method: "PUT",
          headers: headers(token),
          data,
        });
        // console.log("customer:", customer);
        if (expenses.status === "error") {
          // console.log("expenses", expenses);
          return rejectWithValue(expenses);
        }
        return expenses;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateIncome = createAsyncThunk(
  "income/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      if (data) {
        const expenses = await axios({
          url: `${process.env.REACT_APP_API_URL}/income`,
          method: "PUT",
          headers: headers(token),
          data,
        });
        // console.log("customer:", customer);
        if (expenses.status === "error") {
          // console.log("expenses", expenses);
          return rejectWithValue(expenses);
        }
        return expenses;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const createFinanceItem = createAsyncThunk(
  "finance-item/create",
  async ({ data, endpoint }, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      if (data) {
        const expenses = await axios({
          url: `${process.env.REACT_APP_API_URL}/${endpoint}`,
          method: "POST",
          headers: headers(token),
          data,
        });
        // console.log("customer:", customer);
        if (expenses.status === "error") {
          // console.log("liabilities", liabilities);
          return rejectWithValue(expenses);
        }
        return expenses;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const deleteFinanceItem = createAsyncThunk(
  "finance-item/delete",
  async ({ data, endpoint }, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    // console.log("data:", data);
    try {
      if (data) {
        const expenses = await axios({
          url: `${process.env.REACT_APP_API_URL}/${endpoint}`,
          method: "DELETE",
          headers: headers(token),
          data,
        });
        // console.log("customer:", customer);
        if (expenses.status === "error") {
          // console.log("liabilities", liabilities);
          return rejectWithValue(expenses);
        }
        return expenses;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const uploadDocument = createAsyncThunk(
  "document/upload",
  async (formData, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    console.log("data:", formData);
    const formDataHeaders = headers(token);
    formDataHeaders[
      "Content-Type"
    ] = `multipart/form-data; boundary=${formData._boundary}`;
    delete formDataHeaders.Accept;
    console.log(formDataHeaders);
    try {
      if (formData) {
        const privacy = await axios({
          url: `${process.env.REACT_APP_API_URL}/document/upload`,
          method: "POST",
          headers: formDataHeaders,
          data: formData,
        });
        // console.log("customer:", customer);
        if (privacy.status === "error") {
          // console.log("privacy", privacy);
          return rejectWithValue(privacy);
        }
        return privacy;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateDeclaration = createAsyncThunk(
  "declaration/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    console.log("data:", data);
    try {
      if (data) {
        const declaration = await axios({
          url: `${process.env.REACT_APP_API_URL}/declaration`,
          method: "POST",
          headers: headers(token),
          data,
        });

        if (declaration.status === "error") {
          // console.log("declaration", declaration);
          return rejectWithValue(declaration);
        }
        return declaration;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateSupplier = createAsyncThunk(
  "supplier/update",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    console.log("data:", data);
    try {
      if (data) {
        const supplier = await axios({
          url: `${process.env.REACT_APP_API_URL}/supplier`,
          method: "POST",
          headers: headers(token),
          data,
        });

        if (supplier.status === "error") {
          // console.log("declaration", declaration);
          return rejectWithValue(supplier);
        }
        return supplier;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  loadingSubmitApplication: false,
  linkSent: false,
  sendingIDLink: null,
  gettingASICData: null,
  gotASICData: null,
  gettingEntityDetails: false,
  gettingCreditProfile: false,
  canCalculateQuote: false,
  directors: [],
  shareHolders: [],
  beneficiaries: [],
  shares: [],
  businessAddresses: [],
  applicants: [],
  entityId: "",
  entity: {},
  identityVerification: [],
  identityDocument: [],
  address: {},
  customer: {},
  document: {},
  creditProfile: {},
  open: false,
  uploadPrivacyLoading: "",
  uploadIDLoading: "",
  documentUploadLoding: "",
  isUpdatedLoanDetails: null,
  customerUpdated: null,
  phoneNumberUpdated: null,
  emailUpdated: null,
  page: 0,
  gettingQuote: null,
  loadingProducts: false,
  hasReferedQuote: false,
  application: {
    asset: {
      // assetValue: 10000,
      // ageOfAsset: (new Date().getFullYear() + 1).toString(),
      // assetType: "Vehicle (under 3.5 tonnes)",
    },
    loanDetails: {
      // deposit: 0,
      // fees: 320,
      // balloon: 0,
      // term: 60,
      // repayments: 0,
      // loanAmount: 0,
      // brokerage: 0,
      // rate: 12,
    },
  },
};

export const formdataSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    clearState: (state) => state = initialState,
    saveValue: (state, action) => {
      // console.log(action.payload);
      // console.log(attr);
      // console.log(action.payload[attr]);
      return Object.assign({}, state, action.payload);
    },
    saveAsset: (state, action) => {
      // console.log(action.payload);

      // console.log(attr);
      // console.log(action.payload[attr]);
      state.application.asset = Object.assign(
        {},
        state.application.asset,
        action.payload
      );
    },
    saveLoanDetails: (state, action) => {
      // console.log("save loan details", action.payload);

      // console.log(attr);
      // console.log(action.payload[attr]);
      state.application.loanDetails = _.merge(
        state.application.loanDetails,
        action.payload
      );
      // updateLoanDetails(action.payload)
    },
    saveCustomer: (state, action) => {
      console.log("action.payload", action.payload);

      // console.log(attr);
      merge(state.application.customers[0], action.payload)

      // console.log(
      //   "customer",
      //   JSON.stringify(
      //     state.application.customers.filter(
      //       (customer) => customer._id === action.payload.customerId
      //     )[0],
      //     null,
      //     4
      //   )
      // );
      // console.log(JSON.stringify(state.application.customers[0]), null, 4)
      // updateLoanDetails(action.payload)
    },

    saveEmployer: (state, action) => {
      state.application.customers[0].employers[0] = merge(state.application.customers[0].employers[0], action.payload)
    },
  },
  extraReducers: {
    [startApplication.fulfilled]: (state, { payload }) => {
      console.log("start application", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.isNewApplication = true;
      // state.successMessage = payload.message;
      state.application = payload.data.application
    },
    [startApplication.pending]: (state) => {
      state.isFetching = true;
    },
    [startApplication.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [getApplication.fulfilled]: (state, { payload }) => {
      // console.log("application", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // state.successMessage = payload.message;
      state.application = _.merge(state.application, payload.data);
      state.gettingQuote = false
    },
    [getApplication.pending]: (state) => {
      state.isFetching = true;
    },
    [getApplication.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [submitApplication.fulfilled]: (state, { payload }) => {
      // console.log("application", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // state.successMessage = payload.message;
      state.loadingSubmitApplication = false;
    },
    [submitApplication.pending]: (state) => {
      state.isFetching = true;
    },
    [submitApplication.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [referQuote.fulfilled]: (state, { payload }) => {
      // console.log("application", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // state.successMessage = payload.message;
      state.application = _.merge(state.application, payload.data);
      state.hasReferedQuote = true
      state.loadingSubmitApplication = false;
    },
    [referQuote.pending]: (state) => {
      state.isFetching = true;
    },
    [referQuote.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [getApplicationDownloadUrl.fulfilled]: (state, { payload }) => {
      // console.log("application url", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      state.url = payload.data.url;
    },
    [getApplicationDownloadUrl.pending]: (state) => {
      state.isFetching = true;
    },
    [getApplicationDownloadUrl.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [updateAsset.fulfilled]: (state, { payload }) => {
      // console.log("asset", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      // state.successMessage = payload.message;
      state.application.asset = payload.data;
    },
    [updateAsset.pending]: (state) => {
      state.isFetching = true;
    },
    [updateAsset.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [uploadInvoice.fulfilled]: (state, { payload }) => {
      // console.log("asset", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // state.successMessage = payload.message;
      state.uploadInvoiceLoading = false;
      _.merge(state.application.asset, payload.data);
      // state.application.asset = Object.assign(
      //   {},
      //   state.application.asset,
      //   payload.data
      // );
    },
    [uploadInvoice.pending]: (state) => {
      state.isFetching = true;
    },
    [uploadInvoice.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [updateLiabilities.fulfilled]: (state, { payload }) => {
      // console.log("liabilities", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // state.application.asset = Object.assign(
      //   {},
      //   state.application.asset,
      //   payload.data
      // );
    },
    [updateLiabilities.pending]: (state) => {
      state.isFetching = true;
    },
    [updateLiabilities.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [updateLoanDetails.fulfilled]: (state, { payload }) => {
      // console.log("loanDetails", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      state.application.loanDetails = payload.data;
      state.isUpdatedLoanDetails = true;
    },

    [updateLoanDetails.pending]: (state) => {
      state.isFetching = true;
      // state.isUpdatedLoanDetails = false;
    },
    [updateLoanDetails.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [createCustomer.fulfilled]: (state, { payload }) => {
      // console.log("application", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      state.application = payload.data;
    },
    [createCustomer.pending]: (state) => {
      state.isFetching = true;
    },
    [createCustomer.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [getCustomer.fulfilled]: (state, { payload }) => {
      // console.log("get customer", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      state.customer[payload.data._id] = payload.data;
    },
    [getCustomer.pending]: (state) => {
      // state.isFetching = true;
    },
    [getCustomer.rejected]: (state, { payload }) => {
      // state.isFetching = false;
      // state.isError = true;
      // state.errorMessage = payload.message;
    },

    [updateCustomer.fulfilled]: (state, { payload }) => {
      console.log("get customer", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      // state.successMessage = payload.message;
      // state.customer[payload.data._id] = payload.data;
      // state.application = payload.data;
      state.customerUpdated = true
    },
    [updateCustomer.pending]: (state) => {
      state.isFetching = true;
    },
    [updateCustomer.rejected]: (state, { payload }) => {
      state.isFetching = false;
      // state.isError = true;
      // state.errorMessage = payload.message;
    },

    [removeCustomer.fulfilled]: (state, { payload }) => {
      // console.log("get customer", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      // state.successMessage = payload.message;
      // state.customer[payload.data._id] = payload.data;
      state.application = payload.data;
    },
    [removeCustomer.pending]: (state) => {
      state.isFetching = true;
    },
    [removeCustomer.rejected]: (state, { payload }) => {
      state.isFetching = false;
      // state.isError = true;
      // state.errorMessage = payload.message;
    },

    [getAddress.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload?.message;
      // console.log(payload.data);
      // state.customer[payload.data._id] = payload.data
      return Object.assign({}, state.customer[payload.data._id], payload.data);
    },
    [getAddress.pending]: (state) => {
      state.isFetching = true;
    },
    [getAddress.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [addAddress.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("customer", payload.data);
      state.customer[payload.data._id] = Object.assign(
        {},
        state.customer[payload.data._id],
        payload.data
      );
    },
    [addAddress.pending]: (state) => {
      state.isFetching = true;
    },
    [addAddress.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [addPhoneNumber.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("customer", payload.data);
      state.application.customers.filter(
        (customer) => customer._id === payload.data[0].customer
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data[0].customer
        )[0].phoneNumbers,
        payload.data
      );
      state.phoneNumberUpdated = true

      // console.log(
      //   JSON.stringify(
      //     state.application.customers.filter(
      //       (customer) => customer._id === payload.data._id
      //     )[0],
      //     null,
      //     2
      //   )
      // );
    },
    [addPhoneNumber.pending]: (state) => {
      state.isFetching = true;
    },
    [addPhoneNumber.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [addEmail.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log(payload.data);

      state.application.customers.filter(
        (customer) => customer._id === payload.data[0].customer
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data[0].customer
        )[0].emails,
        payload.data
      );

      state.emailUpdated = true

    },
    [addEmail.pending]: (state) => {
      state.isFetching = true;
    },
    [addEmail.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [saveCustomerProfile.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log(payload.data);

      state.application.customers.filter(
        (customer) => customer._id === payload.data.customer
      )[0].customerProfile = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data.customer
        )[0].customerProfile,
        payload.data
      );

    },
    [saveCustomerProfile.pending]: (state) => {
      state.isFetching = true;
    },
    [saveCustomerProfile.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [saveCreditProfile.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log(payload.data);

      state.application.customers.filter(
        (customer) => customer._id === payload.data.customer
      )[0].creditProfile = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data.customer
        )[0].creditProfile,
        payload.data
      );

      // console.log(
      //   JSON.stringify(
      //     state.application.customers.filter(
      //       (customer) => customer._id === payload.data.customer
      //     )[0],
      //     null,
      //     2
      //   )
      // );
      //   state.customer[payload.data._id] = Object.assign(
      //     {},
      //     state.customer[payload.data._id],
      //     payload.data
      //   );
    },
    [saveCreditProfile.pending]: (state) => {
      state.isFetching = true;
    },
    [saveCreditProfile.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [createEntity.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;

      state.successMessage = payload.message;
      // console.log("application", payload.data);
      // console.log("customers", payload.data.customers);

      state.application.entity = _.merge(
        state.application.entities,
        payload.data.entities
      );

      state.gettingEntityDetails = false;
    },
    [createEntity.pending]: (state) => {
      state.isFetching = true;
    },
    [createEntity.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [getASICData.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;

      state.successMessage = payload.message;
      // console.log("application", payload.data);
      // console.log("customers", payload.data.customers);

      state.application.entity = _.merge(
        state.application.entities,
        payload.data.entities
      );
      state.application.customers = _.merge(
        [],
        state.application.customers,
        payload.data.customers
      );
      state.gettingASICData = false;
      state.gotASICData = true;
    },
    [getASICData.pending]: (state) => {
      // state.gotASICData = false;
    },
    [getASICData.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [updateEntity.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;

      state.successMessage = payload.message;
      // console.log("entity", payload.data.entity);
      state.application.entity = _.merge(
        state.application.entity,
        payload.data.entity
      );
    },
    [updateEntity.pending]: (state) => {
      state.isFetching = true;
    },
    [updateEntity.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [getEntity.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("entity", payload.data);
      // state.entity = payload.data;
      state.entity = Object.assign({}, state.entity, payload.data);
    },
    [getEntity.pending]: (state) => {
      state.isFetching = true;
    },
    [getEntity.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [getCompanyProfile.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("companyProfile", payload.data);
      // state.entity = payload.data;
      state.companyProfile = _.merge(state.companyProfile, payload.data);
      state.getCompanyProfileSuccess = true;
    },
    [getCompanyProfile.pending]: (state) => {
      state.isFetching = true;
    },
    [getCompanyProfile.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [getIdentityVerification.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("customer", payload.data);
      state.application.customers.filter(
        (customer) => customer._id === payload.data._id
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        payload.data
      );

      JSON.stringify(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        null,
        2
      );
    },
    [getIdentityVerification.pending]: (state) => {
      state.isFetching = true;
    },
    [getIdentityVerification.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [getDocuments.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("customer", payload.data);
      state.application.customers.filter(
        (customer) => customer._id === payload.data._id
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        payload.data
      );
    },
    [getDocuments.pending]: (state) => {
      state.isFetching = true;
    },
    [getDocuments.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [getCreditFile.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("credit file", payload.data);
      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) => customer._id === payload.data._id
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        payload.data
      );
    },
    [getCreditFile.pending]: (state) => {
      state.isFetching = true;
    },
    [getCreditFile.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [getCreditProfile.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("credit profile", payload.data);
      if (payload.data?._id)
        state.creditProfile[payload.data?.customer] = payload?.data;
    },
    [getCreditProfile.pending]: (state) => {
      state.isFetching = true;
    },
    [getCreditProfile.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [sendPrivacyLink.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("privacy url", payload.data);
      state.privacyLoading = false;
      state.document[payload.data.documentId] = payload.data.url;
    },
    [sendPrivacyLink.pending]: (state) => {
      state.isFetching = true;
    },
    [sendPrivacyLink.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [sendBankStatementsLink.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // state.successMessage = payload.message;
      // console.log("privacy url", payload.data);
      state.document[payload.data.documentId] = payload.data.url;
      state.bankStatementsLoading = false;
    },
    [sendBankStatementsLink.pending]: (state) => {
      state.isFetching = true;
    },
    [sendBankStatementsLink.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
      state.bankStatementsLoading = false;
    },

    [getFileUrl.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("document url", payload.data);
      state.document[payload.data.documentId] = payload.data.url;
    },
    [getFileUrl.pending]: (state) => {
      state.isFetching = true;
    },
    [getFileUrl.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload?.message;
    },

    [updateNotes.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      // console.log("document url", payload.data);
      state.application.notes = payload.data;
    },
    [updateNotes.pending]: (state) => {
      state.isFetching = true;
    },
    [updateNotes.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },

    [uploadDocument.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.application.customers.filter(
        (customer) => customer._id === payload.data._id
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        payload.data
      );
      if (state.uploadPrivacyLoading === true)
        state.uploadPrivacyLoading = false;

      if (state.documentUploadLoading === true) {
        state.documentUploadLoading = false;
      }
      if (state.uploadIDLoading === true) state.uploadIDLoading = false;
    },
    [uploadDocument.pending]: (state) => {
      state.isFetching = true;
      state.documentUploadLoding = true;
    },
    [uploadDocument.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.documentUploadLoding = false;
      state.uploadPrivacyLoading = false;
      state.isFetching = false;
      state.isError = true;
      state.uploadIDLoading = false;
    },

    [updateExpenses.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;

      // console.log("finance items", payload.data);
      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) => customer._id === payload.data._id
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        payload.data
      );
    },
    [updateExpenses.pending]: (state) => {
      state.isFetching = true;
    },
    [updateExpenses.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
    },

    [updateIncome.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;

      // console.log("finance items", payload.data);
      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) => customer._id === payload.data._id
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        payload.data
      );
    },
    [updateIncome.pending]: (state) => {
      state.isFetching = true;
    },
    [updateIncome.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
    },

    [createFinanceItem.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;

      // console.log("finance items", payload.data[Object.keys(payload.data)[0]]);

      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) =>
          customer._id === payload.data[Object.keys(payload.data)[0]].customer
      )[0][Object.keys(payload.data)[0]] =
        payload.data[Object.keys(payload.data)[0]];
    },
    [createFinanceItem.pending]: (state) => {
      state.isFetching = true;
    },
    [createFinanceItem.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
    },

    [deleteFinanceItem.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;

      // console.log("finance items", payload.data[Object.keys(payload.data)[0]]);

      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) =>
          customer._id === payload.data[Object.keys(payload.data)[0]].customer
      )[0][Object.keys(payload.data)[0]] =
        payload.data[Object.keys(payload.data)[0]];
    },
    [deleteFinanceItem.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteFinanceItem.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
    },

    [startVerification.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.sendingIDLink = false
    },
    [startVerification.pending]: (state) => {
      state.isFetching = true;
    },
    [startVerification.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
    },

    [updateSupplier.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);

    },
    [updateSupplier.pending]: (state) => {
      state.isFetching = true;
    },
    [updateSupplier.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
    },
  },
});

export const {
  saveValue,
  saveAsset,
  saveLoanDetails,
  saveCustomer,
  saveEmployer,
  clearState,
} = formdataSlice.actions;

export const store = configureStore({
  reducer: {
    userDetails: formdataSlice.reducer,
  },
});

export const stateSelector = (state) => state;
export const userDetailsSelector = (state) => state.userDetails;
export const userSelector = (state) => state.userDetails.user;
export const applicationSelector = (state) => state.userDetails.application;
export const assetSelector = (state) => state.userDetails.application.asset;
export const loanDetailsSelector = (state) =>
  state.userDetails.application.loanDetails;
export const entitySelector = (state) =>
  state.userDetails.application?.entities?.[0];
export const customersSelector = (state) =>
  state.userDetails.application.customers;
