import { typeOfSaleList, livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

const {
  PROPERTY_OWNER,
  RENTING,
  BOARDING,
  LIVING_WITH_PARENTS
} = livingArrangementsTypes

const {
  SELF_EMPLOYED,
  FULL_TIME,
  PART_TIME,
  CONTRACT,
  CASUAL,
  BENEFITS
} = employmentTypes

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  termIsBetween,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  termIsLessThanOrEqualTo,
  compound
} = criteria

export default [
  {
    name: "ACL - Auto",
    rate: 9.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(60001, 100000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreGreaterThan(399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 13.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(50001, 60000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        FULL_TIME,
        PART_TIME,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreGreaterThan(399),
    ],
  }, {
    name: "ACL - Auto",
    rate: 15.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(40001, 50000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreGreaterThan(399),
    ],
  }, {
    name: "ACL - Auto",
    rate: 17.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(30001, 40000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreGreaterThan(399),
    ],
  }, {
    name: "ACL - Auto",
    rate: 20.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(25001, 30000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreGreaterThan(399),
    ],
  }, {
    name: "ACL - Auto",
    rate: 24.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(20001, 25000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreGreaterThan(399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 28.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(10000, 20000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreGreaterThan(399),
    ],
  },




  {
    name: "ACL - Auto",
    rate: 9.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(60001, 100000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 13.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(50001, 60000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 399),
    ],
  }, {
    name: "ACL - Auto",
    rate: 15.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(40001, 50000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 399),
    ],
  }, {
    name: "ACL - Auto",
    rate: 17.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(30001, 40000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 399),
    ],
  }, {
    name: "ACL - Auto",
    rate: 20.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(25001, 30000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 399),
    ],
  }, {
    name: "ACL - Auto",
    rate: 24.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(20001, 25000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 399),
    ],
  },
  {
    name: "ACL - Auto",
    rate: 28.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(10000, 20000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      ageOfAssetLessThan(36),
      ageOfAssetAtEndLessThan(21),
      termIsBetween(24, 60),
      hasDeposit(30),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(3),
      creditScoreBetween(300, 399),
    ],
  },
]
