import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography } from "@mui/material";

import { useParams } from "react-router-dom";
import { handleMixpanelQuoteEvent } from "src/utils/mixPanelTrackEvents";

import {
  customersSelector,
  saveCreditProfile,
  saveCustomer,
  userDetailsSelector,
} from "../store/slices/applicationFormSlice";
import { saveValue, userSelector } from "src/store/slices/userSlice";
import mixpanel from "src/services/mixpanel";

export default function CreditScore() {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const customers = useSelector(customersSelector);
  const user = useSelector(userSelector)
  const { walkthroughIndex } = user
  const { isNewApplication } = useSelector(userDetailsSelector);
  const [scoreValue, setScoreValue] = useState(customers?.[0]?.creditProfile?.score);

  function handleScore(event) {
    setScoreValue(event.target.value)
    console.log(walkthroughIndex, event.target.value)
    if (user?.status === "new" && walkthroughIndex < 7 && event.target.value * 1 === 650) {
      mixpanel.track(`Demo: Step 8 - Employment type`)
      dispatch(saveValue({ walkthroughIndex: 7 }))
      event.target.blur()
    }
  }

  function updateCreditScore(value) {
    handleMixpanelQuoteEvent(applicationId, "credit score", value)
    console.log({ value })

    dispatch(saveCustomer({
      customerId: customers[0]?._id,
      creditProfile: {
        score: value
      }
    }))

    dispatch(
      saveCreditProfile({
        score: value,
        customer: customers[0]?._id,
      })
    );
  }

  useEffect(() => {
    if (customers?.[0]?.creditProfile?.score) setScoreValue(customers?.[0]?.creditProfile?.score)
  }, [customers?.[0]?.creditProfile?.score]);

  useEffect(() => {
    if (isNewApplication) setScoreValue("")
  }, [isNewApplication])

  return <>
    {/* <Typography variant="subtitle2" style={{ marginBottom: 5 }}>
    Credit score
  </Typography> */}
    <TextField
      className="eight-step"
      fullWidth
      style={{ background: "#ffffff" }}
      // variant="filled"
      defaultValue=""
      type="number"
      name="creditscore"
      label="Score"
      size="small"
      placeholder="650"
      value={scoreValue || ""}
      onChange={handleScore}
      // InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
      onBlur={async (event) => {
        // handleAssetValueMinMax();
        updateCreditScore(event.target.value);
      }}
    />
  </>
}
