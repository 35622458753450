import criteria from "src/utils/criteria";
import { livingArrangementsTypes, employmentTypes } from "src/constants";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes
const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  compound,
  termIsLessThan,
  ageOfAssetGreaterThan
} = criteria;

const base = [
  {
    name: "Premium Tier New/Demo",
    rate: 9.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetLessThan(12),
      livingArrangements([PROPERTY_OWNER, RENTING]),

      creditScoreGreaterThan(1080),

      assetType([
        "Jetski/PWC"
      ]),
    ],
  },


  {
    name: "Tier 1 New/Demo",
    rate: 10.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetLessThan(12),
      livingArrangements([PROPERTY_OWNER, RENTING]),


      creditScoreGreaterThan(800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 1 Asset less than 24 months",
    rate: 10.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetBetween(12, 24),
      livingArrangements([PROPERTY_OWNER, RENTING]),


      creditScoreGreaterThan(800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 1 Asset 25 - 59 months",
    rate: 11.59,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetBetween(25, 59),
      livingArrangements([PROPERTY_OWNER, RENTING]),


      creditScoreGreaterThan(800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 1 Asset greater than 59 months",
    rate: 12.89,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetBetween(60, 216),
      livingArrangements([PROPERTY_OWNER, RENTING]),


      creditScoreGreaterThan(800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },

  {
    name: "Tier 2 New/Demo",
    rate: 12.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetLessThan(12),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),


      creditScoreGreaterThan(800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 2 Asset less than 24 months",
    rate: 12.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetBetween(12, 24),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),


      creditScoreGreaterThan(800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 2 Asset 25 - 59 months",
    rate: 12.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetBetween(25, 59),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),


      creditScoreGreaterThan(800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 2 Asset greater than 59 months",
    rate: 13.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetBetween(60, 216),
      ageOfAssetAtEndLessThan(21),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),


      creditScoreGreaterThan(800),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },

  {
    name: "Tier 2 New/Demo",
    rate: 12.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetLessThan(12),
      livingArrangements([PROPERTY_OWNER, RENTING]),


      creditScoreBetween(660, 800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 2 Asset less than 24 months",
    rate: 12.19,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetBetween(12, 24),
      livingArrangements([PROPERTY_OWNER, RENTING]),


      creditScoreBetween(660, 800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 2 Asset 25 - 59 months",
    rate: 12.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetBetween(25, 59),
      livingArrangements([PROPERTY_OWNER, RENTING]),


      creditScoreBetween(660, 800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 2 Asset greater than 59 months",
    rate: 13.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 50000),
      ageOfAssetBetween(60, 216),
      ageOfAssetAtEndLessThan(21),
      livingArrangements([PROPERTY_OWNER, RENTING]),


      creditScoreBetween(660, 800),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },



  {
    name: "Tier 3 New/Demo",
    rate: 13.89,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 30000),
      ageOfAssetLessThan(12),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),


      creditScoreBetween(660, 800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 3 Asset less than 24 months",
    rate: 13.89,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 30000),
      ageOfAssetBetween(12, 24),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),


      creditScoreBetween(660, 800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 3 Asset 25 - 59 months",
    rate: 14.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 30000),
      ageOfAssetBetween(25, 59),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),


      creditScoreBetween(660, 800),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 3 Asset greater than 59 months",
    rate: 15.89,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 30000),
      ageOfAssetBetween(60, 216),
      ageOfAssetAtEndLessThan(21),
      livingArrangements([BOARDING, LIVING_WITH_PARENTS]),


      creditScoreBetween(660, 800),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },

  {
    name: "Tier 3 New/Demo",
    rate: 13.89,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 30000),
      ageOfAssetLessThan(12),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),


      creditScoreBetween(499, 659),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 3 Asset less than 24 months",
    rate: 13.89,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 30000),
      ageOfAssetBetween(12, 24),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),


      creditScoreBetween(499, 659),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 3 Asset 25 - 59 months",
    rate: 14.69,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 30000),
      ageOfAssetBetween(25, 59),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),


      creditScoreBetween(499, 659),
      ageOfAssetAtEndLessThan(21),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
  {
    name: "Tier 3 Asset greater than 59 months",
    rate: 15.89,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(1500, 30000),
      ageOfAssetBetween(60, 216),
      ageOfAssetAtEndLessThan(21),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),


      creditScoreBetween(499, 659),
      assetType([
        "Jetski/PWC"
      ]),
    ],
  },
]

const selfEmployed = base.map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      employmentType([SELF_EMPLOYED]),
      timeOfEmploymentGreaterThan(24),]
  }
})

const perm = base.map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      employmentType([FULL_TIME, PART_TIME, CONTRACT]),
      timeOfEmploymentGreaterThan(3),
    ]
  }
})

const casual = base.map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      employmentType([CASUAL]),
      timeOfEmploymentGreaterThan(6),
    ]
  }
})

const final = [...selfEmployed, ...perm, ...casual].map(product => {

  return {
    ...product,
    criteria: [
      ...product.criteria,
      termIsLessThan(85),
      ageOfAssetAtEndLessThan(21),
    ]
  }
})

export default [
  ...final,
]