import { Button, Typography } from "@mui/material";
import React from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { saveValue, updateUser, userSelector } from "src/store/slices/userSlice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import mixpanel from "src/services/mixpanel";

const fontStyle = {
  fontSize: "15px",
}

export default function Walkthrough() {
  const dispatch = useDispatch()

  const { walkthroughIndex, runWalkthrough, status } = useSelector(userSelector)
  const steps = [
    // {
    //   title: <Typography variant="h6" textAlign="left" marginLeft="10px" >Let's start with we want to borrow</Typography>,
    //   target: '.first-step',
    //   content: <>
    //     <Typography textAlign="left" style={fontStyle}>
    //       Asset details
    //     </Typography>
    //     <Typography textAlign="left" style={fontStyle}>Notice the quotes change on the right.
    //       They update in real time as you change values of the loan profile.
    //     </Typography>
    //   </>,
    //   disableBeacon: true,
    //   placement: "right"
    // },
    {
      title: <Typography variant="h6" textAlign="left" marginLeft="10px" >Asset details (1/9)</Typography>,
      target: '.second-step',
      content: <>
        <Typography
          textAlign="left"
          style={fontStyle}
          marginBottom="10px"
        >
          Best place to start a quote is with the Asset details.
          What's there already looks good but let's change the <strong>Asset type</strong>.
        </Typography>
        <Typography
          textAlign="left"
          style={fontStyle}
        >
          Select <strong>Motorbike</strong> from the drop down.
        </Typography>
      </>
      ,
      disableBeacon: true,
      placement: "right"
    },
    {
      title: <Typography variant="h6" textAlign="left" marginLeft="10px">Customer details (2/9)</Typography>,
      target: '.third-step',
      content: <>
        <Typography
          textAlign="left"
          marginBottom="10px"
          style={fontStyle}>
          Nice job!👌
        </Typography>

        <Typography
          textAlign="left"
          marginBottom="10px"
          style={fontStyle}>
          We're skipping loan details (term, balloon) as they're already set.
          So next we'll add Customer details.
        </Typography>

        <Typography
          textAlign="left"
          style={fontStyle}>
          Select <strong>Property
            owner</strong> in the <strong>Living arrangements</strong> drop down.
        </Typography>
      </>,
      placement: "right"
    },
    {
      title: <Typography variant="h6" textAlign="left" marginLeft="10px">Sorting quotes (3/9)</Typography>,
      target: '.four-step',
      content: <>
        <Typography textAlign="left" marginBottom="10px" style={fontStyle}>
          Great! Seems like you've done that before.😉
        </Typography>
        <Typography textAlign="left" marginBottom="10px" style={fontStyle}>
          We can see the quotes have changed but they don't look the best.
        </Typography>
        <Typography textAlign="left" style={fontStyle}>
          Use <strong>Order by</strong> to sort the list by rate.
          Select <strong>Rate</strong> in the drop down.
        </Typography>

      </>,
      placement: "left"
    },
    {
      title: <Typography variant="h6" textAlign="left" marginLeft="10px">Show criteria (4/9)</Typography>,
      target: '.fifth-step',
      content: <>
        <Typography textAlign="left" marginBottom="10px" style={fontStyle}>
          Good work! 🤘
        </Typography>
        <Typography textAlign="left" marginBottom="10px" style={fontStyle}>
          This product has a great price but it's red which means the profile doesn't match the criteria.
        </Typography>
        {/* <Typography textAlign="left" marginBottom="10px" style={fontStyle}>
          The colour of the background shows how well it matches the loan profile on the left. It is a
          range from <span>red</span> {`>`} <span>yellow</span> {`>`} <span>green</span > meaning lowest to highest match.
        </Typography> */}
        <Typography textAlign="left" style={fontStyle}>
          Click <strong>Show criteria</strong> to see the full criteria and what's missing.
        </Typography>
      </>,
      placement: "left"
    },
    {
      title: <Typography variant="h6" textAlign="left" marginLeft="10px">Criteria (5/9)</Typography>,
      target: '.sixth-step',
      content: <div style={{ textAlign: "left" }}>
        <Typography textAlign="left" marginBottom="10px" style={fontStyle}>
          This is the criteria for this product and the icons
          show how the profile matches.
          <ul style={{ padding: "10px 20px", }}>
            <li><CheckCircleIcon
              style={{
                fontSize: "18px",
                color: "#2e7d32",
                verticalAlign: "middle"
              }} /> is a match</li>
            <li><CancelIcon
              style={{
                fontSize: "18px",
                color: "#c62828",
                verticalAlign: "middle"
              }} /> is a fail</li>
            <li><CancelIcon
              style={{
                fontSize: "18px",
                color: "#999999",
                verticalAlign: "middle"
              }} /> the value has not been added yet</li>
          </ul>
          Looks like we're missing <strong>Employment type</strong> and <strong>Credit
            score</strong>. We'll add those in a minute.
        </Typography>
        <Typography textAlign="left" style={fontStyle} marginBottom="10px">
          However, this product doesn't match the <strong>Asset type</strong> we want.
          Good thing we can filter the list to only products that match.
        </Typography>
        <Typography textAlign="left" style={fontStyle} marginBottom="10px">Click continue to see how.</Typography>
        <Button variant="contained" onClick={() => {
          dispatch(saveValue({ walkthroughIndex: 5 }))
          mixpanel.track(`Demo: Step 6 - Must match`)
        }}
        >Continue</Button>
      </div>,
      placement: "right"
    },
    {
      title: <Typography variant="h6" textAlign="left" marginLeft="10px">Filtering (6/9)</Typography>,
      target: '.seventh-step',
      content: <>
        <Typography
          textAlign="left" style={fontStyle} marginBottom="10px"
        >
          You're a natural at this quoting stuff. Keep it up! 👍
        </Typography>
        <Typography
          textAlign="left" style={fontStyle} marginBottom="10px"
        >
          The <strong>Must match</strong> drop down will filter the products to only those
          that match the selected option.
        </Typography>
        <Typography
          textAlign="left" style={fontStyle} marginBottom="10px"
        >
          Select <strong>Asset type</strong> from the drop down.
        </Typography>
      </>,
      placement: "right"
    },
    {
      title: <Typography variant="h6" textAlign="left" marginLeft="10px">Credit score (7/9)</Typography>,
      target: '.eight-step',
      content: <>
        <Typography
          textAlign="left" style={fontStyle} marginBottom="10px"
        >
          Awesome stuff!👏
        </Typography>
        <Typography
          textAlign="left" style={fontStyle} marginBottom="10px"
        >
          The quotes look better but we're still missing a couple of things.
        </Typography>
        <Typography
          textAlign="left" style={fontStyle} marginBottom="10px"
        >
          Add <strong>650</strong> to the <strong>Score</strong> input.
        </Typography>
      </>,
      placement: "right"
    },
    {
      title: <Typography variant="h6" textAlign="left" marginLeft="10px">Employment type (8/9)</Typography>,
      target: '.ninth-step',
      content: <>
        <Typography
          textAlign="left" style={fontStyle} marginBottom="10px"
        >
          You're killing it!💪
        </Typography>
        <Typography
          textAlign="left" style={fontStyle} marginBottom="10px"
        >
          Just one more to go.
        </Typography>
        <Typography
          textAlign="left" style={fontStyle} marginBottom="10px"
        >
          Select <strong>Full-time</strong> from the <strong>Employment type</strong> drop down.
        </Typography>
      </>,
      placement: "right"
    },
    {
      title: <Typography variant="h6" textAlign="left" marginLeft="10px">Product matches (9/9)</Typography>,
      target: '.tenth-step',
      content: <>
        <div style={{ textAlign: "left" }}>
          <Typography
            textAlign="left" style={fontStyle} marginBottom="10px"
          >
            🎉🎉Woohoo!!🎉🎉 You completed a quote!👏👏
          </Typography>
          <Typography
            textAlign="left" style={fontStyle} marginBottom="10px"
          >
            Check it out, we've got a bunch of products that match now with much better prices.
          </Typography>
          <Typography
            textAlign="left" style={fontStyle} marginBottom="10px"
          >
            Thanks for taking the time you did great. You've got the basics now
            and you're ready to start quoting!
          </Typography>
          <Button variant="contained" onClick={() => {
            dispatch(saveValue({ runWalkthrough: false }))
            dispatch(updateUser({
              status: "active"
            }))
          }}>Finish</Button>
        </div>
      </>,
      placement: "left"
    },
  ]

  function handleCallback(data) {
    const { action, index, status, type } = data;
    console.log({ action, walkthroughIndex })

    if (action === "prev") dispatch(saveValue({ walkthroughIndex: walkthroughIndex - 1 }))

    if (["skip", "close"].includes(action)) {
      dispatch(updateUser({
        status: "active"
      }))
    }
  }

  return <>
    <Joyride
      styles={{
        buttonNext: {
          display: 'none',
        }
      }}
      run={runWalkthrough && status === "new"}
      stepIndex={walkthroughIndex}
      callback={handleCallback}
      hideBackButton
      // disableScrolling
      disableOverlayClose
      spotlightClicks
      continuous
      showProgress
      showSkipButton
      steps={steps}
    />
  </>
}
