import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography } from "@mui/material";

import { useParams } from "react-router-dom";
import { handleMixpanelQuoteEvent } from "src/utils/mixPanelTrackEvents";

import {
  customersSelector,
  saveCreditProfile,
  saveCustomer,
  updateCustomer,
} from "../store/slices/applicationFormSlice";
import { DateField } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";

export default function DateOfBirth() {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const customers = useSelector(customersSelector);
  const [dateOfBirth, setDateOfBirth] =
    useState(customers?.[0]?.dateOfBirth && dayjs(customers?.[0]?.dateOfBirth));

  function updateDateOfBirth(value) {
    handleMixpanelQuoteEvent(applicationId, "date of birth", moment(value)?.format("YYYY-MM-DD"))

    dispatch(saveCustomer({
      customerId: customers[0]?._id,
      dateOfBirth: moment(value)?.format("YYYY-MM-DD")
    }))

    dispatch(updateCustomer({
      customerId: customers[0]?._id,
      dateOfBirth: moment(value)?.format("YYYY-MM-DD")
    }))
  }

  useEffect(() => {
    if (customers?.[0]?.dateOfBirth) setDateOfBirth(dayjs(customers?.[0]?.dateOfBirth))
  }, [customers?.[0]?.dateOfBirth]);

  return <>
    <DateField
      label="Date of birth"
      size="small"
      format="DD-MM-YYYY"
      value={dateOfBirth || null}
      style={{ background: "#ffffff" }}
      onChange={(event) => {
        // console.log(moment(event.$d))
        setDateOfBirth(event?.$d || null)
      }}
      onBlur={() => updateDateOfBirth(dateOfBirth)}
    />
  </>
}
