import { typeOfSaleList, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";
import platinum from "./platinum";
import gold from "./gold";
import silver from "./silver";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } = employmentTypes

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  compound
} = criteria

export default {
  date: "31 Aug 2023",
  lender: "Affordable Car Loans",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/ACL.png",
  fees: [
    {
      name: "Establishment fee",
      value: 395,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE), assetType(["Motor Vehicle", "Electric Motor Vehicle",])],
    },
    {
      name: "Private sale",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE), assetType(["Motor Vehicle", "Electric Motor Vehicle",])],
    },
    {
      name: "Origination fee",
      value: 1100,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        employmentType([
          SELF_EMPLOYED,
          FULL_TIME,
          PART_TIME,
          CONTRACT,
          CASUAL])
      ],
    },
    // {
    //   name: "Risk fee",
    //   value: 2,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [assetValueBetween(60001, 100000)],
    // },
    {
      name: "Risk fee",
      value: 2,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(50001, 60000)],
    },
    {
      name: "Risk fee",
      value: 4,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(40001, 50000)],
    },
    {
      name: "Risk fee",
      value: 6,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(30001, 40000)],
    },
    {
      name: "Risk fee",
      value: 8,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(25001, 30000)],
    },
    {
      name: "Risk fee",
      value: 10,
      calc: "percent",
      capitalised: true,
      frequency: "upfront",
      criteria: [assetValueBetween(10000, 25000)],
    },
    
  ],
  brokerage: [],
  loading: [
    {
      name: "Age of asset at start 36-95 mths",
      value: 3,
      valueType: "percentage",
      criteria: [ageOfAssetBetween(36, 95)],
    },
    {
      name: "Age of asset at start >95 mths",
      value: 3,
      valueType: "percentage",
      criteria: [ageOfAssetGreaterThan(95)],
    },
  ],
  info: [
    `All income types accepted - cannot be 100 % benefits only`,
    `Bank Statements required for<500 score`,
    `Repayment Amount<20% net income per month`,
    `Total loans liability(monthly) < 50 % of net income`,
    `EXCLUSIONS`,
    `Bankruptcy Undischarged or multiple`,
    `Equifax Apply Negative credit score less than 300`,
    `Combined income less than $600 net per week`,
    `1 active payday loan`
  ],
  productTiers: [
    ...platinum,
    ...gold,
    ...silver,

  ],
};