import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isNumber } from "lodash";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  createEntity,
  entitySelector,
  updateEntity,
  saveValue,
  userDetailsSelector,
} from "../store/slices/applicationFormSlice";

import { getBusinessByABN, getBusinessByName } from "../services/abr";
import readableABNString from "../utils/readableABNString";
import readableACNString from "../utils/readableACNString";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Skeleton,
  Stack,
} from "@mui/material";
import { isDigitsOnly } from "src/utils/isDigitsOnly";
import { LoadingButton } from "@mui/lab";

export default function Entity({ application }) {
  const dispatch = useDispatch();

  const { gettingASICData, gettingEntityDetails } = useSelector(userDetailsSelector);

  const entity = useSelector(entitySelector);
  // const { turnover, industryType } = useSelector(entitySelector);

  // console.log(entity);

  // const { turnover, industryType } = entity;

  const [businessSearchValue, setBusinessSearchValue] = useState("");
  const [businessSearchInputText, setBusinessSearchInputText] = useState("");
  const [newBusinessValue, setNewBusinessValue] = useState("");
  const [turnoverValue, setTurnoverValue] = useState({});
  const [industryTypeValue, setIndustryTypeValue] = useState({});
  const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);
  const [ABREntityDetails, setABREntityDetails] = useState({});

  // const parseTimeInBusiness = moment(entity?.timeInBusiness, "DD-MM-YYYY");

  const handleEntity = (update) => {
    // console.log("update phoneNumber");
    dispatch(updateEntity(update));
  };

  const debounced = useMemo(
    () => debounce(async (inputValue) => {
      // console.log("inputValue", inputValue);
      if (inputValue) {
        console.log(inputValue?.replace(/\s/g, '') * 1)
        if (isNumber(inputValue?.replace(/\s/g, '') * 1)) {

          const businessByABN = await getBusinessByABN(inputValue.replace(/\s/g, '') * 1);
          console.log({ businessByABN })
          setLoadingBusinessSearch(false);
          if (businessByABN.Abn.length > 1) {
            return setEntityOptions([businessByABN]);
          }
        }

        setLoadingBusinessSearch(true);
        const businesses = await getBusinessByName(inputValue);
        console.log(businesses)
        if (businesses?.Names?.length > 0) {
          setLoadingBusinessSearch(false);
          return setEntityOptions(
            businesses.Names.map((i) => {
              // return { label: `${i.Name} ${i.Abn}`, Abn: i.Abn, value: i };
              return i;
            })
          );
        }



        console.log(entityOptions)


        setEntityOptions([
          {
            Name: "No results found",
          },
        ]);
      }
    }, 1000), []);

  const handleEntityDetails = async (details) => {
    dispatch(saveValue({ gettingEntityDetails: true }));
    console.log("details", details);
    const abrData = await getBusinessByABN(details.Abn);
    // console.log("ABREntityDetails", abrData);
    setABREntityDetails(abrData);
    // console.log(abrData.EntityName !== entity?.entityName);
    // console.log(abrData.EntityName, entity?.entityName);

    if (abrData.EntityName !== entity?.entityName) {
      // await dispatch(getCompanyProfile({ ACN: abrData.Acn })).unwrap();
      dispatch(createEntity({ abrData, applicationId: application._id }));
    } else {
      dispatch(saveValue({ gettingEntityDetails: false }));
    }
  };

  const [getASICDataButtonBackground, setGetASICDataButtonBackground] =
    useState("secondary");
  const [getASICDataButtonText, setGetASICDataButtonText] =
    useState("Get ASIC data");

  function handleGetASICData() {
    setGetASICDataButtonBackground("success");
    setGetASICDataButtonText("ASIC Data retrieved");

    setTimeout(() => {
      setGetASICDataButtonBackground("secondary");
      setGetASICDataButtonText("Get ASIC data");
      dispatch(saveValue({ gotASICData: false }))
    }, 4000);
  }

  useEffect(() => {
    // console.log({ gettingASICData })
    if (gettingASICData === false) {
      handleGetASICData();
      dispatch(saveValue({ gettingASICData: null }));
    }

  }, [gettingASICData]);

  useEffect(() => {
    debounced(businessSearchInputText);

    // return () => debounced.cancel();
  }, [businessSearchValue, businessSearchInputText]);

  useEffect(() => {
    if (!gettingEntityDetails) {
      setBusinessSearchInputText("");
      setBusinessSearchValue("");
    }
  }, [gettingEntityDetails]);

  return (
    <>
      {application.entities &&
        application.entities.map((entity) => (

          <Stack direction="column">
            {entity?.entityName && (
              <Grid item lg={12} md={12} sm={12} style={{ margin: "0 0 7px 0" }}>
                {/* <Typography variant="subtitle2">Entity name</Typography> */}
                <Typography variant="subtitle2">Entity name</Typography>
                <Typography variant="body2">
                  {entity?.entityName}
                </Typography>
                {/* {entityNameErr ? (
                        <span style={{ color: "red" }}>{entityNameErr}</span>
                      ) : null} */}
              </Grid>
            )}

            {entity?.entityType && (
              <Grid item lg={12} md={12} sm={12} style={{ margin: "0 0 7px 0" }}>
                <Typography variant="subtitle2">Entity type</Typography>
                <Typography variant="body2">
                  {entity?.entityType}
                </Typography>
              </Grid>
            )}

            <Stack direction="row">
              {entity?.abn && (
                <Grid item lg={6} md={6} sm={12} style={{ margin: "0 0 7px 0" }}>
                  <Typography variant="subtitle2">ABN</Typography>
                  <Typography variant="body2">
                    {readableABNString(entity?.abn)}
                  </Typography>
                </Grid>
              )}

              {entity?.acn && (
                <Grid item lg={6} md={6} sm={12}>
                  <Typography variant="subtitle2">ACN</Typography>
                  <Typography variant="body2">
                    {readableACNString(entity?.acn)}
                  </Typography>
                </Grid>
              )}
            </Stack>


            <Stack direction="row">
              {entity?.timeInBusiness && (
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 7px 0" }}>
                  <Typography variant="subtitle2">
                    Trading for
                  </Typography>
                  <Typography variant="body2">
                    {`${moment().diff(moment(entity?.timeInBusiness, "YYYY-MM-DD"),
                      "months"
                    )} months`}

                    {/* {moment().diff(moment(entity?.timeInBusiness, "yyyy-mm-dd"))} */}
                  </Typography>
                </Grid>
              )}


              {entity?.gst && (
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 7px 0" }}>
                  <Typography variant="subtitle2">GST</Typography>
                  <Typography variant="body2">
                    {entity?.gst
                      ? `${moment().diff(
                        moment(entity?.gst),
                        "months"
                      )} months`
                      : "No"}
                  </Typography>
                </Grid>
              )}
            </Stack>

            {/* </Grid> */}
            {/* <Grid container style={{ margin: "0 0 20px 0" }}>
                <Grid item xs={12}>
                  {entity?.businessNames?.length > 0 && (
                    <>
                      <Typography variant="subtitle2">
                        Business names
                      </Typography>
                      <Typography variant="body2">
                        {entity?.businessNames.map((name, i) => (
                          <span>
                            {entity?.businessNames.length - 1 === i
                              ? `${name}`
                              : `${name}, `}
                          </span>
                        ))}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid container style={{ margin: "0 0 20px 0" }}>
                {entity?.addresses?.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Business addresses
                    </Typography>
                    {entity?.addresses.map((address) => (
                      <Typography
                        variant="body2"
                        style={{ margin: "0 0 10px" }}
                      >
                        {address.fullAddress}
                      </Typography>
                    ))}
                  </Grid>
                )}
              </Grid> */}
            {/* <Grid container spacing={2}>
                <Grid item md={4} sm={5}>
                  <TextField
                    id="outlined-basic"
                    type="text"
                    name="applicant_firstname"
                    label="Turnover (yearly)"
                    variant="outlined"
                    value={
                      turnoverValue?.[entity._id] || entity?.turnover || ""
                    }
                    onChange={(event) =>
                      setTurnoverValue({ [entity._id]: event.target.value })
                    }
                    onBlur={(event) => {
                      handleEntity({
                        entityId: entity?._id,
                        turnover: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item md={8} sm={7}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    type="text"
                    name="applicant_firstname"
                    label="Industry or business type"
                    variant="outlined"
                    value={
                      industryTypeValue?.[entity._id] ||
                      entity?.industryType ||
                      ""
                    }
                    onChange={(event) =>
                      setIndustryTypeValue({ [entity._id]: event.target.value })
                    }
                    onBlur={(event) => {
                      handleEntity({
                        entityId: entity?._id,
                        industryType: event.target.value,
                      });
                    }}
                  />
                </Grid> */}
            {/* <Grid item md={12} sm={12}>
                  <LoadingButton
                    loading={gettingASICData}
                    color={getASICDataButtonBackground}
                    variant="outlined"
                    // style={{ marginBottom: "5px" }}
                    // disabled={
                    //   !customer?.phoneNumbers?.filter(
                    //     (a) => a.status === "current"
                    //   )[0]?.number
                    // }
                    onClick={() => {
                      dispatch(saveValue({ gettingASICData: true }))
                      dispatch(getASICData({
                        acn: entity.acn,
                        applicationId: application._id,
                        entityId: entity._id,
                      })
                      )
                    }}
                  >
                    {getASICDataButtonText}
                  </LoadingButton>
                </Grid> */}
          </Stack>

        ))
      }

      {
        gettingEntityDetails && (

          <Grid item container spacing={2} style={{ marginBottom: "15px" }}>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            {/* </Grid>
              <Grid item container spacing={2} style={{ marginBottom: "15px" }}> */}
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            <Grid item sm={4}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
            {/* </Grid>
              <Grid item container spacing={2}> */}
            <Grid item container>
              <Grid item sm={4}>
                <Skeleton variant="text" />
              </Grid>
              <Grid item sm={12}>
                <Skeleton variant="text" />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item sm={4}>
                <Skeleton variant="text" />
              </Grid>
              <Grid item sm={12}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>
          </Grid>

        )
      }

      {!entity && <Grid container style={{ margin: "7px 0 20px 0" }}>
        <Grid item lg={12} md={12} sm={12}>
          {/* <Paper
            style={{
              marginTop: "10px",
              marginBottom: "15px",
              padding: "15px 35px 30px 35px",
              // display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "stretch",
              justifyContent: "space-between",
            }}
          > */}

          <Autocomplete
            autoComplete
            autoSelect={true}
            name="Business Search"
            // {...props}
            value=""
            noOptionsText="Type to start search"
            loadingText="Searching..."
            defaultValue=""
            clearOnBlur={true}
            blurOnSelect={true}
            onChange={(event, newValue, reason) => {
              console.log(newValue, entity?.entityName, reason);
              if (reason === "select-option") {
                if (newValue) {
                  setNewBusinessValue(newValue);
                  setBusinessSearchValue(
                    newValue?.Name || newValue?.entityName
                  );

                  return;
                }

                setBusinessSearchValue("");
                setBusinessSearchInputText("");
              }

              if (reason === "blur") {
                setBusinessSearchInputText("");
                handleEntityDetails(newValue);
              }
            }}
            filterOptions={(x) => x}
            onInputChange={(event, newInputValue) => {
              // console.log({
              //   newInputValue: Number(newInputValue * 1) ? newInputValue * 1 : newInputValue,
              //   businessSearchInputText,
              //   // newInputValue,
              // });
              const value = Number(newInputValue * 1) ? newInputValue * 1 : newInputValue
              setBusinessSearchInputText(value);
              if (newInputValue === "") setEntityOptions([]);
            }}
            getOptionLabel={(option) => {
              if (option.EntityName) {
                return option.EntityName
                  ? `${option.EntityName} ${readableABNString(option.Abn)}`
                  : "";
              }

              return option.Name
                ? `${option.Name} ${readableABNString(option.Abn)}`
                : "";
            }}
            // inputValue={businessSearchInputText}
            options={entityOptions}
            loading={loadingBusinessSearch}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                type="text"
                // label="Asynchronous"
                label="Add a business"
                value=""
                placeholder="Search with ABN or business name"
                style={{ background: "#ffffff" }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingBusinessSearch ? <CircularProgress /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

        </Grid>
      </Grid >
      }
    </>
  );
}
