import { typeOfSaleList } from "src/constants";
import criteria from "src/utils/criteria";

const {
  livingArrangements,
  typeOfSale,
  hasDeposit,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetAtEndLessThan,
  ageOfAssetAtEndGreaterThan,
  loanAmountBetween,
  gstRegistrationGreaterThan,
  compound,
  creditScoreGreaterThan,
  ageOfAssetLessThan
} = criteria;

export default {
  date: "06 Jul 2023",
  lender: "Metro",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FMetro-Finance-2022.gif",
  fees: [
    {
      name: "Document fee",
      value: 250,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Origination fee",
      value: 200,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 8,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 1000000)],
    },
  ],
  loading: [
    {
      name: "Non property owner",
      value: 1,
      valueType: "percentage",
      criteria: [livingArrangements(["Renting", "Boarding", "Living w/ parents"]),],
    },
    {
      name: "Private sale",
      value: 0.75,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: typeOfSaleList.SALE_BUYBACK_EQUITY,
      value: 0.75,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.SALE_BUYBACK_EQUITY)],
    },
    {
      name: "Age of asset at start >5 yrs ",
      value: 0.75,
      valueType: "percentage",
      criteria: [ageOfAssetGreaterThan(5)],
    },
    {
      name: "Age at end of term >10 years",
      value: 1.50,
      valueType: "percentage",
      criteria: [ageOfAssetAtEndGreaterThan(10)],
    }
  ],
  info: [
    `Balloon/Residual Refinance max lend $150,000`,
    `A replacement policy which allows for the limit for a Authorise Seller limit to be extended to $300,000`,
    `Credit reference required`,
    `Auto & Other wheeled & Tracked assets - if existing Metro customer over 12 months max 400K`,
    `Auto & Other wheeled & Tracked assets - If existing Metro customer and asset backed max 250K`,
  ],
  productTiers: [
    {
      name: "Passenger Vehicle Streamlined",
      rate: 9.30,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        gstRegistrationGreaterThan(24),
        
        ageOfAssetAtEndLessThan(12),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Passenger Vehicle Streamlined",
      rate: 10.40,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        gstRegistrationGreaterThan(24),
        
        ageOfAssetAtEndLessThan(12),
        hasDeposit(30),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Passenger Vehicle Streamlined",
      rate: 8.60,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 150000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
      ],
    },

    {
      name: "Passenger Vehicle Streamlined",
      rate: 8.60,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 100000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(30),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Primary Asset Streamlined",
      rate: 10.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Primary Asset Streamlined",
      rate: 10.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(30),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Primary Asset Streamlined",
      rate: 8.85,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 250000),
        typeOfSale(typeOfSaleList.DEALER_SALE),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Primary Asset Streamlined",
      rate: 8.85,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 250000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        hasDeposit(30),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Primary Asset Streamlined (Private sale)",
      rate: 8.85,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 150000),
        typeOfSale(typeOfSaleList.PRIVATE_SALE),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    
    {
      name: "Primary (other) Asset Streamlined",
      rate: 10.65,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment (wheeled or tracked)",
          "Trailers and Caravans",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Primary (other) Asset Streamlined",
      rate: 10.65,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(30),
        assetType([
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment (wheeled or tracked)",
          "Trailers and Caravans",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Primary (other) Asset Streamlined",
      rate: 8.90,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 250000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment (wheeled or tracked)",
          "Trailers and Caravans",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Primary (other) Asset Streamlined",
      rate: 8.90,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 250000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        hasDeposit(30),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        assetType([
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment (wheeled or tracked)",
          "Trailers and Caravans",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Secondary Asset Streamlined",
      rate: 12.65,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(8),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Office Furniture",
          "Restaurant & Catering Equipment",
          "Health & Beauty Equipment",
          "Solar"
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Secondary Asset Streamlined",
      rate: 10.90,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 100000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(8),
        
        livingArrangements(["Property owner"]), 
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Office Furniture",
          "Restaurant & Catering Equipment",
          "Health & Beauty Equipment",
          "Solar"
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Secondary Asset Streamlined",
      rate: 12.65,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(8),
        
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(30),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Office Furniture",
          "Restaurant & Catering Equipment",
          "Health & Beauty Equipment",
          "Solar"
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Secondary Asset Streamlined",
      rate: 10.90,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 100000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(8),
        
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(30),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Office Furniture",
          "Restaurant & Catering Equipment",
          "Health & Beauty Equipment",
          "Solar"
        ]),
        creditScoreGreaterThan(400)
      ],
    },


    {
      name: "Passenger Vehicle Full Doc",
      rate: 8.60,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(150000, 500000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Passenger Vehicle Full Doc",
      rate: 8.60,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(150000, 500000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        hasDeposit(30),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },


    {
      name: "Primary Asset Full Doc",
      rate: 8.85,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(250000, 500000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Primary Asset Full Doc",
      rate: 8.85,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(250000, 500000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        hasDeposit(30),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Primary (other) Asset Full Doc",
      rate: 8.90,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(250000, 500000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment (wheeled or tracked)",
          "Trailers and Caravans",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Primary (other) Asset Full Doc",
      rate: 8.90,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(250000, 500000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(12),
        
        hasDeposit(30),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        assetType([
          "Earth Moving & Construction (wheeled or tracked)",
          "Warehouse Equipment (wheeled or tracked)",
          "Trailers and Caravans",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Secondary Asset Full Doc",
      rate: 10.90,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(100000, 500000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(8),
        
        livingArrangements(["Property owner"]),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Office Furniture",
          "Restaurant & Catering Equipment",
          "Health & Beauty Equipment",
          "Solar"
        ]),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Secondary Asset Full Doc",
      rate: 10.90,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(100000, 500000),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(8),
        
        hasDeposit(30),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Office Furniture",
          "Restaurant & Catering Equipment",
          "Health & Beauty Equipment",
          "Solar"
        ]),
        creditScoreGreaterThan(400)
      ],
    },
    
  ],
};