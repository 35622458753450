/* eslint-disable import/no-anonymous-default-export */
import criteria from "src/utils/criteria";
import { typeOfSaleList, livingArrangementsTypes } from "src/constants";

const { PROPERTY_OWNER, RENTING, LIVING_WITH_PARENTS, BOARDING } =
  livingArrangementsTypes;

const {
  livingArrangements,
  noDeposit,
  hasDeposit,
  typeOfSale,
  assetValueBetween,
  assetValueGreaterThan,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountGreaterThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  ageOfAssetAtEndGreaterThan,
  gstRegistrationGreaterThan,
  termIsLessThan,
  termIsGreaterThan,
  creditScoreGreaterThan,
} = criteria;

export default {
  date: "05 Jul 2023",
  lender: "Flexi",
  type: "commercial",
  logoURL:
    "https://assets.financeable.com.au/lender-logos%2Fflexicommercial_TM_20210301_Orange.webp",
  fees: [
    {
      name: "Document fee",
      value: 495,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Private sale doc fee",
      value: 695,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 8,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 50000)],
    },
    {
      value: 4,
      max: 6,
      valueType: "percentage",
      criteria: [assetValueGreaterThan(49999)],
    },
  ],
  loading: [
    {
      name: "Private sale",
      value: 1,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
    {
      name: "Non property owner",
      value: 1,
      valueType: "percentage",
      criteria: [
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
      ],
    },
    {
      name: "Term <24",
      value: 1,
      valueType: "percentage",
      criteria: [termIsLessThan(25)],
    },
    {
      name: "Term >60",
      value: 1.25,
      valueType: "percentage",
      criteria: [termIsGreaterThan(60)],
    },
    {
      name: "Commercial (Heavy) Vehicles >12T",
      value: 1,
      valueType: "percentage",
      criteria: [assetType(["Commercial (Heavy) Vehicles (>12 Tonnes)"])],
    },
    {
      name: "Asset age >10 years at start",
      value: 1,
      valueType: "percentage",
      criteria: [
        ageOfAssetBetween(120, 180),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
      ],
    },
    {
      name: "Asset age at end >15 years",
      value: 2,
      valueType: "percentage",
      criteria: [
        ageOfAssetAtEndGreaterThan(15),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)",
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
      ],
    },
  ],
  info: [
    `Direct Debit*`,
    `Director’s Guarantees*`,
    `Used assets and Private Sale acceptable for
    Primary. Secondary assets on exception basis`,
    `Borrowers/Guarantors for transport operators/
    sub-contractors are to be asset backed with
    sufficient equity or ABN > 5 years
    (20% deposit required)`,
    `Maximum exposure $400K`,
    `*Not required for Public companies or Private companies that are required to lodge annual financial statements with ASIC.`,
  ],
  productTiers: [
    // {
    //   name: "Auto",
    //   rate: 10.60,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(10000, 50000),
    //     timeInBusinessGreaterThan(23),
    //     gstRegistrationGreaterThan(23),
    //     ageOfAssetAtEndLessThan(20),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     creditScoreGreaterThan(549)
    //   ],
    // },
    // {
    //   name: "Auto",
    //   rate: 9.60,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(50001, 150000),
    //     timeInBusinessGreaterThan(23),
    //     gstRegistrationGreaterThan(23),
    //     ageOfAssetAtEndLessThan(20),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     livingArrangements(["Property owner"]),
    //     creditScoreGreaterThan(549)
    //   ],
    // },
    // {
    //   name: "Auto",
    //   rate: 9.60,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(50001, 150000),
    //     timeInBusinessGreaterThan(23),
    //     gstRegistrationGreaterThan(23),
    //     ageOfAssetAtEndLessThan(20),
    //     assetType([
    //       "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
    //     ]),
    //     hasDeposit(20),
    //     livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
    //     creditScoreGreaterThan(549)
    //   ],
    // },
    {
      name: "Primary Asset",
      rate: 13.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 13.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 11.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 11.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.3,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.3,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.3,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.3,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 8.8,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 8.8,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements(["Property owner"]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 8.8,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 8.8,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Primary Asset",
      rate: 14.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 14.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 12.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 12.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 10.3,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 10.3,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Primary Asset",
      rate: 9.8,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset",
      rate: 9.8,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Secondary Asset",
      rate: 14.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 11.3,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        livingArrangements(["Property owner"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 11.3,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        hasDeposit(20),
        livingArrangements([RENTING, LIVING_WITH_PARENTS, BOARDING]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 9.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        livingArrangements(["Property owner"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 9.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 9.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        livingArrangements(["Property owner"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 9.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Secondary Asset",
      rate: 10.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset",
      rate: 10.05,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(150000, 300000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    
    {
      name: "Tertiary Asset",
      rate: 16.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Tertiary Asset",
      rate: 15.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements(["Property owner"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Tertiary Asset",
      rate: 14.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements(["Property owner"]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    // {
    //   name: "Tertiary Asset",
    //   rate: 14.10,
    //   productType: "Low doc",
    //   criteria: [
    //     loanAmountBetween(150000, 250000),
    //     timeInBusinessGreaterThan(23),
    //     gstRegistrationGreaterThan(23),
    //     ageOfAssetAtEndLessThan(5),
    //     assetType([
    //       "IT, Computer & Audio Equipment",
    //       "Office Equipment",
    //       "Restaurant & Catering Equipment",
    //       "Office Furniture",
    //       "Health & Beauty Equipment",
    //       "Solar",
    //     ]),
    //     livingArrangements(["Property owner"]),
    //     termIsLessThan(61),
    //     creditScoreGreaterThan(549)
    //   ],
    // },
    {
      name: "Tertiary Asset",
      rate: 16.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Tertiary Asset",
      rate: 15.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20001, 50000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Tertiary Asset",
      rate: 14.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        hasDeposit(20),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Tertiary Asset",
      rate: 15.55,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(23),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },


    {
      name: "Primary Asset - Special",
      rate: 8.50,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 300000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([PROPERTY_OWNER]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset - Special",
      rate: 8.50,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 300000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([PROPERTY_OWNER]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset - Special",
      rate: 8.90,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 300000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        hasDeposit(20),
        livingArrangements([PROPERTY_OWNER]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },

    {
      name: "Primary Asset - Special",
      rate: 9.50,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 300000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(20),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Primary Asset - Special",
      rate: 9.50,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 300000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(30),
        assetType(["Trailers and Caravans"]),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        creditScoreGreaterThan(549),
      ],
    },
    {
      name: "Secondary Asset - Special",
      rate: 8.90,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(100000, 300000),
        timeInBusinessGreaterThan(47),
        gstRegistrationGreaterThan(47),
        ageOfAssetAtEndLessThan(5),
        assetType([
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        hasDeposit(20),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
        termIsLessThan(61),
        creditScoreGreaterThan(549),
      ],
    },
  ],
};
