import { typeOfSaleList, livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

const {
  PROPERTY_OWNER,
  RENTING,
  BOARDING,
  LIVING_WITH_PARENTS
} = livingArrangementsTypes

const {
  SELF_EMPLOYED,
  FULL_TIME,
  PART_TIME,
  CONTRACT,
  CASUAL,
  BENEFITS
} = employmentTypes

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  termIsLessThanOrEqualTo,
  compound
} = criteria

function permanentEmployment(l) {
  return l.map(product => {

    return {
      ...product,
      criteria: [
        ...product.criteria,
        employmentType([FULL_TIME, PART_TIME]),
        timeOfEmploymentGreaterThan(5)
      ]
    }
  })
}

function temporaryEmployment(l) {
  return l.map(product => {

    return {
      ...product,
      criteria: [
        ...product.criteria,
        employmentType([SELF_EMPLOYED, CASUAL, CONTRACT]),
        timeOfEmploymentGreaterThan(11)
      ]
    }
  })
}

export default [
  ...permanentEmployment(base()),
  ...temporaryEmployment(base())
]

function base() {
  return [
    {
      name: "Silver",
      rate: 25.50,
      info: [
        "Adverse more than 12 months",
        "Ex-bankkrupt more than 1 day"
      ],
      productType: "Adverse",
      criteria: [
        loanAmountBetween(8000, 75000),
        termIsLessThanOrEqualTo(72),
        assetType([
          "Motor Vehicle",
          "Electric Motor Vehicle",
          "Caravan",
          "Trailered boat",
          "Motorbike",
        ]),
      ],
    },
  ]
}
