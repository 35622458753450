import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Slider, Typography } from "@mui/material";

import { useParams } from "react-router-dom";
import { handleMixpanelQuoteEvent } from "src/utils/mixPanelTrackEvents";

import {
  customersSelector, saveEmployer, updateEmployer,
} from "../store/slices/applicationFormSlice";

export default function LengthOfEmployment() {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const customers = useSelector(customersSelector);
  const [timeOfEmployment, setTimeOfEmployment] = useState(customers?.[0]?.employers[0]?.timeOfEmployment && customers?.[0]?.employers[0]?.timeOfEmployment);

  function handleUpdateEmployment(value) {
    handleMixpanelQuoteEvent(applicationId, "length of employment", value)
    dispatch(saveEmployer({ timeOfEmployment: value }))
    dispatch(
      updateEmployer({
        employerId: customers[0]?.employers[0]?._id,
        timeOfEmployment: value
      })
    );
  };
  return <>
    <Typography variant="subtitle2">Time at current job {timeOfEmployment === 25 ? "24+" : timeOfEmployment} (mths)</Typography>
    <Box style={{ padding: "0 7px" }}>
      <Slider
        color="primary"
        size="small"
        marks={[
          { value: 0, label: 0 },
          { value: 6, label: 6 },
          { value: 12, label: 12 },
          { value: 18, label: 18 },
          { value: 24, label: 24 },
          { value: 30, label: "30+" },
        ]}
        step={1}
        min={0}
        max={30}
        value={timeOfEmployment}
        onChange={(event) => {
          setTimeOfEmployment(event.target.value)
          // updateQuote(event.target.value);
        }}
        onChangeCommitted={() => {
          handleUpdateEmployment(timeOfEmployment)
          // updateQuote(event.target.value);
        }}
        aria-label="Default"
        valueLabelDisplay="auto"
      />
    </Box>
  </>
}
