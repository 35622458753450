import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";

import { useParams } from "react-router-dom";
import { handleMixpanelQuoteEvent } from "src/utils/mixPanelTrackEvents";

import {
  updateLoanDetails,
  loanDetailsSelector,
  saveLoanDetails,
} from "../store/slices/applicationFormSlice";

import { InputAdornment } from "@mui/material";

export default function Deposit() {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const { deposit, _id } = useSelector(loanDetailsSelector);

  function handleDepositValue(event) {
    dispatch(saveLoanDetails({ deposit: event.target.value }));
  };

  function handleUpdateLoanDetails() {
    handleMixpanelQuoteEvent(applicationId, "deposit", deposit)
    if (_id) dispatch(updateLoanDetails({ _id, deposit }));
  };

  return <TextField
    fullWidth
    style={{ background: "#ffffff" }}
    // variant="filled"
    type="number"
    name="deposit"
    label="Deposit"
    size="small"
    value={deposit}
    onChange={handleDepositValue}
    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
    onBlur={async () => handleUpdateLoanDetails()}
  />
}