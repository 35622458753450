import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, userSelector } from "../store/slices/userSlice";
import _ from "lodash";
import { Typography } from "@mui/material";

export default function Login() {
  const navigate = useNavigate();

  const user = useSelector(userSelector);

  useEffect(() => {
    // if (user) navigate("/login");
  }, [user]);

  return (
    <>
      <Grid container style={{ padding: "30px 50px 100px" }}>
        <Grid container item xs={12} justifyContent="center">
          <Grid item direction="column" sm={12} md={6} >
            <Typography variant="h1" style={{ fontSize: "30px", marginBottom: "20px" }}>Whoops!</Typography>
            <Typography variant="h2" style={{ fontSize: "24px", marginBottom: "30px" }}>Looks like you've gotten lost.</Typography>
            <Typography style={{ marginBottom: "50px" }}>Head back to the <a href="https://financeable.com.au">homepage</a>.</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
