import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { useParams } from "react-router-dom";

import {
  updateAsset,
  assetSelector,
  saveAsset,
  loanDetailsSelector,
} from "../store/slices/applicationFormSlice";

import { handleMixpanelQuoteEvent } from "src/utils/mixPanelTrackEvents";

import generateArrayOfYears from "src/utils/generateArrayOfYears";

export default function AssetValue() {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const { ageOfAsset, _id } = useSelector(assetSelector);
  const { term } = useSelector(loanDetailsSelector);

  const handleAgeOfAsset = (event, newValue) => {
    console.log(newValue);
    const termInYears = term / 12;
    // console.log("termInYears", termInYears)
    const ageOfAssetMoment = moment(newValue, "YYYY");
    const endOfTermYear = moment().add(termInYears, "year");
    console.log(ageOfAssetMoment);
    console.log(endOfTermYear);
    // console.log(endOfTermYear.diff(ageOfAssetMoment, "years"));
    const ageOfAssetAtEnd = endOfTermYear.diff(ageOfAssetMoment, "years");
    console.log("ageOfAssetAtEnd", ageOfAssetAtEnd);
    dispatch(saveAsset({ ageOfAsset: newValue, ageOfAssetAtEnd }));
    dispatch(updateAsset({ _id, ageOfAsset: newValue, ageOfAssetAtEnd }));
  };

  return <Autocomplete
    value={ageOfAsset?.toString() || ""}
    options={generateArrayOfYears()}
    onChange={handleAgeOfAsset}
    onBlur={() => handleMixpanelQuoteEvent(applicationId, "age of asset", ageOfAsset)}
    size="small"
    getOptionLabel={(option) => option || ""}
    renderInput={(params) => (
      <TextField
        size="small"
        {...params}
        label="Year of manufacture"
        style={{ background: "#ffffff" }}
      />
    )}
  />
}
