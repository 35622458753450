import { typeOfSaleList, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

import a from "./a"
import b from "./b"
import c from "./c"

const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes

const {
    propertyOwner,
    nonPropertyOwner,
    nonGST,
    typeOfSale,
    hasDeposit,
    gstRegistrationBetween,
    gstRegistrationGreaterThan,
    termIsGreaterThan,
    assetValueBetween,
    assetType,
    ageOfAssetBetween,
    ageOfAssetLessThan,
    ageOfAssetAtEndLessThan,
    loanAmountBetween,
    timeInBusinessGreaterThan,
    creditScoreGreaterThan,
    employmentType,
    timeOfEmploymentGreaterThan,
    livingArrangements,
    creditScoreBetween,
    compound
} = criteria

function selfEmployedCasual(l) {
    return l.map(product => {

        return {
            ...product,
            criteria: [
                ...product.criteria,
                employmentType([SELF_EMPLOYED, CASUAL]),
                timeOfEmploymentGreaterThan(12),
            ]
        }
    })
}

function fullTime(l) {
    return l.map(product => {

        return {
            ...product,
            criteria: [
                ...product.criteria,
                employmentType([FULL_TIME]),
                timeOfEmploymentGreaterThan(3),
            ]
        }
    })
}

function partTimeContractor(l) {
    return l.map(product => {

        return {
            ...product,
            criteria: [
                ...product.criteria,
                employmentType([PART_TIME, CONTRACT]),
                timeOfEmploymentGreaterThan(6),
            ]
        }
    })
}

export default {
    date: "01 Jul 2023",
    lender: "Plenti",
    type: "consumer",
    logoURL: "https://assets.financeable.com.au/lender-logos%2Fplenti-logo.gif",
    fees: [
        {
            name: "Establishment fee",
            value: 499,
            capitalised: true,
            frequency: "upfront",
            criteria: [typeOfSale(typeOfSaleList.DEALER_SALE), assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"])],
        },
        {
            name: "Establishment fee",
            value: 299,
            capitalised: true,
            frequency: "upfront",
            criteria: [typeOfSale(typeOfSaleList.DEALER_SALE), assetType(["Motorbike"])],
        },
        {
            name: "Private sale",
            value: 599,
            capitalised: true,
            frequency: "upfront",
            criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE), assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"])],
        },
        {
            name: "Private sale",
            value: 399,
            capitalised: true,
            frequency: "upfront",
            criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE), assetType(["Motorbike"])],
        },
        {
            name: "Origination fee",
            value: 990,
            capitalised: true,
            frequency: "upfront",
        },
        {
            name: "Monthly account fee",
            value: 9.90,
            capitalised: false,
            frequency: "monthly",
        },
    ],
    brokerage: [
    ],
    loading: [
        {
            name: "EV discount",
            value: -0.5,
            valueType: "percentage",
            criteria: [assetType(["Electric Motor Vehicle"])],
        },
        {
            name: "Caravans",
            value: 0.75,
            valueType: "percentage",
            criteria: [assetType(["Caravan"])],
        },
        {
            name: "Motorbikes",
            value: 1,
            valueType: "percentage",
            criteria: [assetType(["Motorbike"])],
        },
    ],
    info: [
        `
      New 12 months or less from build date / Demo 24 months or less from build date and under 5000kms
    `,
        `
      "Asset backed customers with credit score <600 are subject to $40,000 max lend and 115% LVR"
    `
    ],
    productTiers: [
        ...selfEmployedCasual([...a, ...b, ...c]),
        ...fullTime([...a, ...b, ...c]),
        ...partTimeContractor([...a, ...b, ...c])
    ],
};
