import { livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes
const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes

const {
  assetType,
  loanAmountBetween,
  creditScoreGreaterThan,
  livingArrangements,
  creditScoreBetween,
  ageOfAssetBetween,
} = criteria;

const base = [
  {
    name: `Property Owner - Motor Vehicle New/Demo/Up To 24 Months`,
    rate: 10.09,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(0, 24),
      livingArrangements([PROPERTY_OWNER]),
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreGreaterThan(834)
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 24-95 months old",
    rate: 10.24,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(24, 95),
      livingArrangements([PROPERTY_OWNER]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreGreaterThan(834)
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 95-144 months old",
    rate: 10.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(95, 144),
      livingArrangements([PROPERTY_OWNER]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreGreaterThan(834)
    ],
  },


  {
    name: "Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 10.34,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(0, 24),
      livingArrangements([PROPERTY_OWNER]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(730, 834)
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 24-95 months old",
    rate: 11.34,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(24, 95),
      livingArrangements([PROPERTY_OWNER]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(730, 834)
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 95-144 months old",
    rate: 12.34,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(95, 144),
      livingArrangements([PROPERTY_OWNER]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(730, 834)
    ],
  },


  {
    name: "Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 11.94,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(0, 24),
      livingArrangements([PROPERTY_OWNER]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(579, 729)
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 24-95 months old",
    rate: 12.74,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(24, 95),
      livingArrangements([PROPERTY_OWNER]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(579, 729)
    ],
  },
  {
    name: "Property Owner - Motor Vehicle 95-144 months old",
    rate: 14.74,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(95, 144),
      livingArrangements([PROPERTY_OWNER]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(579, 729)
    ],
  },



  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 10.49,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(0, 24),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreGreaterThan(834)
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 10.84,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(24, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreGreaterThan(834)
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 11.04,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreGreaterThan(834)
    ],
  },


  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 11.34,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(0, 24),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(730, 834)
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 11.84,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(24, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(730, 834)
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 13.34,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(730, 834)
    ],
  },


  {
    name: "Non Property Owner - Motor Vehicle New/Demo/Up To 24 Months",
    rate: 12.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(0, 24),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(579, 729)
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 24-95 months old",
    rate: 14.39,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(24, 95),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(579, 729)
    ],
  },
  {
    name: "Non Property Owner - Motor Vehicle 95-144 months old",
    rate: 15.99,
    productType: "Low doc",
    criteria: [
      loanAmountBetween(5000, 100000),
      ageOfAssetBetween(95, 144),
      livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      
      assetType([
        "Motor Vehicle", "Electric Motor Vehicle"
      ]),
      creditScoreBetween(579, 729)
    ],
  },
]

export default base