import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, TextField } from "@mui/material";

import {
  updateAsset,
  assetSelector,
  saveAsset,
} from "../store/slices/applicationFormSlice";

import { handleMixpanelQuoteEvent } from "src/utils/mixPanelTrackEvents";

import { InputAdornment } from "@mui/material";

export default function AssetValue() {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const { assetValue, _id } = useSelector(assetSelector);

  function handleAssetValue(event) {
    dispatch(saveAsset({ assetValue: event.target.value }));
  };

  function handleUpdateAssetDetails() {
    handleMixpanelQuoteEvent(applicationId, "asset value", assetValue)
    if (_id) dispatch(updateAsset({ _id, assetValue }));
  };

  return <TextField
    className="first-step"
    fullWidth
    style={{ background: "#ffffff" }}
    // variant="filled"
    type="number"
    name="assetvalue"
    label="Asset value"
    size="small"
    value={assetValue}
    onChange={handleAssetValue}
    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
    onBlur={async () => handleUpdateAssetDetails()}
  />

}
