import criteria from "src/utils/criteria";

const {
  livingArrangements,
  termIsGreaterThan,
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetGreaterThanOrEqualTo,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan
} = criteria

export default {
  date: "01 Jul 2023",
  lender: "ANZ",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fanz-2-logo-png-transparent.gif",
  fees: [
    {
      name: "Document fee",
      value: 400,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 3.5,
      max: 3.5,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 1000000)],
    },
  ],
  loading: [
    // {
    //   name: "Non property owner",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [nonPropertyOwner],
    // },
    {
      name: "Private sale",
      value: 0.42,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale"), assetType(["Motor Vehicle (<4.5 tonnes)"])],
    },
    {
      name: "Term >60",
      value: 0.60,
      valueType: "percentage",
      criteria: [termIsGreaterThan(60)],
    }
  ],
  info: [
    "BAS statement and ATO portal access",
    "No debts or GST outstanding",
    "Non property owners accepted if an existing ANZ customer",
    "Max aggregate exposure $500k"
  ],
  productTiers: [
    {
      name: "Auto",
      rate: 7.65,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Auto",
      rate: 8.35,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThanOrEqualTo(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)",
        ]),
        creditScoreGreaterThan(400)
      ],
    },
    {
      name: "Primary",
      rate: 7.85,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(36),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Primary",
      rate: 8.65,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThanOrEqualTo(36),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
          "Commercial (Heavy) Vehicles (>12 Tonnes)",
          "Warehouse Equipment (wheeled or tracked)",
          "Earth Moving & Construction (wheeled or tracked)",
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
          "Mini Buses & Route Buses (<25 seats)",
          "Trailers and Caravans",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Secondary",
      rate: 7.95,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Motorbikes, ATVs",
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        creditScoreGreaterThan(400)
      ],
    },

    {
      name: "Secondary",
      rate: 9.25,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(10000, 150000),
        timeInBusinessGreaterThan(12),
        gstRegistrationGreaterThan(12),
        ageOfAssetGreaterThanOrEqualTo(12),
        ageOfAssetAtEndLessThan(13),
        livingArrangements(["Property owner"]),
        assetType([
          "Motorbikes, ATVs",
          "Industrial Plant & Printing",
          "Medical Equipment",
          "Manufacturing Equipment",
        ]),
        creditScoreGreaterThan(400)
      ],
    },
  ],
};