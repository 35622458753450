import { typeOfSaleList, livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

const {
  PROPERTY_OWNER,
  RENTING,
  BOARDING,
  LIVING_WITH_PARENTS
} = livingArrangementsTypes

const {
  SELF_EMPLOYED,
  FULL_TIME,
  PART_TIME,
  CONTRACT,
  CASUAL,
  BENEFITS
} = employmentTypes

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  termIsBetween,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  termIsLessThanOrEqualTo,
  compound
} = criteria

export default [
  {
    name: "Auto",
    rate: 24.50,
    productType: "Standard",
    criteria: [
      loanAmountBetween(5000, 25000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Motorbike"
      ]),
      termIsBetween(36, 60),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        CASUAL,
        CONTRACT,
      ]),
      timeOfEmploymentGreaterThan(1),
      creditScoreGreaterThan(0),
    ],
  },
]
