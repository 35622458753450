import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Autocomplete from '@mui/material/Autocomplete';
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Close from "@mui/icons-material/Close";
import { getBusinessByABN, getBusinessByName } from "../../services/abr";
import readableABNString from "../../utils/readableABNString";
import readableACNString from "../../utils/readableACNString";
import { isDigitsOnly } from "src/utils/isDigitsOnly";

import { userSelector } from "../../store/slices/userSlice";
import {
  createOrganisation,
  getOrganisation,
  organisationSelector,
  saveValue,
  requestToJoin,
  inviteUsers,
} from "../../store/slices/organisationSlice";
import {
  Box,
  Button,
  Fade,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Typography,
} from "@mui/material";

export default function Organisation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [invites, setInvites] = useState([]);

  const { inviteUsersSuccess } = useSelector(organisationSelector);
  const user = useSelector(userSelector);

  function handleAddEmail() {
    setInvites([...invites, { firstName, email }]);
    setEmail("");
    setFirstName("");
  }

  function handleInviteUsers() {
    dispatch(inviteUsers(invites));
  }

  useEffect(() => {
    if (inviteUsersSuccess) navigate("/dashboard/applications");
  }, [inviteUsersSuccess]);

  return <>
    <Grid
      container
      item
      height="100%"
      width="100%"
      // style={{ padding: "30px 50px 100px" }}
      justifyContent="center"
      alignItems="center"
      direction="row-reverse"
    >
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={8}
        height="100vh"
        // justifyContent="center"
        alignItems="center"
        direction="column"
        textAlign="center"
        style={{ padding: "10vh 0", marginTop: "10vh" }}
      >
        <Typography
          variant="h4"
          fontWeight="800"
          style={{ letterSpacing: "-1.8px" }}
        >
          Invite colleagues to your Business/Organisation
        </Typography>
        {/* <br />
        <br /> */}
        {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
          This is it.
        </Typography> */}
        <br />
        {/* <br /> */}
        <Typography
          variant="h6"
          fontWeight="400"
          style={{ margin: "0 0 20px 0", fontSize: "16px" }}
        >
          Invite by adding their work email addresses below.
        </Typography>
        <Box
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            marginBottom: "30px",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "20px",
            }}
          >
            <TextField
              size="small"
              label="First name"
              value={firstName}
              sx={{ bgcolor: "background.paper" }}
              onChange={(event) => setFirstName(event.target.value)}
              style={{ marginRight: "5px" }}
            />
            <TextField
              size="small"
              label="Email"
              value={email}
              sx={{ bgcolor: "background.paper" }}
              onChange={(event) => setEmail(event.target.value)}
              style={{ marginRight: "10px" }}
            />
            <Button variant="contained" color="secondary" onClick={handleAddEmail}>
              Add email
            </Button>
          </Box>
          {/* <Typography variant="h6">Invitees list</Typography> */}
          {invites && (
            <List dense={true} sx={{ width: "100%" }}>
              {invites.map((invite) => (
                <ListItem
                  sx={{ bgcolor: "background.paper" }}
                  style={{ marginBottom: "5px", padding: "10px" }}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" size="large">
                      <Close />
                    </IconButton>
                  }
                >
                  {/* <ListItemText
                    primary={`${invite.firstName} ${invite.email}`}
                    secondary={secondary ? "Secondary text" : null}
                  /> */}

                  <Typography fontWeight={600} style={{ width: "calc(50% - 42px)" }}>
                    {invite.firstName}
                  </Typography>
                  <Typography style={{ width: "calc(50% - 42px)" }}>
                    {invite.email}
                  </Typography>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <Button
          // fullWidth
          color="secondary"
          variant="contained"
          onClick={handleInviteUsers}
        >
          Send invites and continue
        </Button>
        <Button
          // fullWidth
          color="secondary"
          variant="link"
          onClick={navigate("/dashboard/applications")}
        >
          Skip
        </Button>
      </Grid>
    </Grid>
  </>;
}
