import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, Input } from "@mui/material";
import { startCase } from "lodash";
import { userSelector } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "75vh",
      width: 300,
    },
  },
};

export default function ProductSelectorFilterby({ list, handleFilterBy, value, title, multiple, screenSize }) {
  const user = useSelector(userSelector)

  return (
    <FormControl
      className={title === "Must match" ? "seventh-step" : ""}
      fullWidth
      size="small"
      sx={{ m: 1 }}
      style={{ margin: "0 0 10px 0", width: screenSize.dynamicWidth < 900 ? 120 : 160 }}
    >
      <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple={user?.status === "new" ? false : multiple}
        // multiple={false}
        value={value}
        onChange={handleFilterBy}
        style={{ marginTop: "10px", padding: "0 15px", flexDirection: "row" }}
        input={<Input id="select-multiple-chip" label="Name" />}
        renderValue={(selected) =>
          multiple ? `${selected.length} selected` || undefined : selected
        }
        MenuProps={MenuProps}
        size="small"
      >
        {/* <Stack direction="row"> */}
        {multiple && <MenuItem
          // value={null}
          onClick={(event) => {
            const emptyEvent = { target: { value: [] } }
            if (!value[0]) emptyEvent.target.value = list
            setTimeout(() => handleFilterBy(emptyEvent), 50)

          }}
        >
          {multiple && <Checkbox />}
          {value[0] && user.status !== "new" ? "Deselect all" : "Select all"}
        </MenuItem>}
        {list.map((param) => (
          <MenuItem
            key={param}
            value={param}

          //   style={getStyles(name, , theme)}
          >
            {multiple && <Checkbox checked={value?.includes(param)} />}
            {title === "Must match" ? startCase(param) : param}
          </MenuItem>
        ))}


        {/* </Stack> */}
      </Select>
    </FormControl>
  );
}
