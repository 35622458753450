import { typeOfSaleList, livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

const {
    PROPERTY_OWNER,
    RENTING,
    BOARDING,
    LIVING_WITH_PARENTS
} = livingArrangementsTypes

const {
    SELF_EMPLOYED,
    FULL_TIME,
    PART_TIME,
    CONTRACT,
    CASUAL,
    BENEFITS
} = employmentTypes


const {
    propertyOwner,
    nonPropertyOwner,
    nonGST,
    typeOfSale,
    hasDeposit,
    gstRegistrationBetween,
    gstRegistrationGreaterThan,
    termIsGreaterThan,
    assetValueBetween,
    assetType,
    ageOfAssetBetween,
    ageOfAssetLessThan,
    ageOfAssetGreaterThan,
    ageOfAssetAtEndLessThan,
    loanAmountBetween,
    timeInBusinessGreaterThan,
    creditScoreGreaterThan,
    employmentType,
    timeOfEmploymentGreaterThan,
    livingArrangements,
    creditScoreBetween,
    compound,
    dateOfBirthGreaterThan,
    termIsBetween
} = criteria

const base = [
    {
        name: "Silver - Motor Vehicle New/Demo",
        rate: 20.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetLessThan(12),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle <47 months",
        rate: 20.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetBetween(12, 47),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 21.99,
        productType: "Adverse",
        criteria: [

             ageOfAssetBetween(48, 95),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle >95 months",
        rate: 22.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetGreaterThan(95),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },

    {
        name: "Silver - Motor Vehicle New/Demo",
        rate: 20.99,
        productType: "Standard",
        criteria: [

            ageOfAssetLessThan(12),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle <47 months",
        rate: 20.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetBetween(12, 47),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 21.99,
        productType: "Adverse",
        criteria: [

             ageOfAssetBetween(48, 95),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 22.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetGreaterThan(95),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },


    {
        name: "Silver - Motor Vehicle New/Demo",
        rate: 20.99,
        productType: "Standard",
        criteria: [

            ageOfAssetLessThan(12),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle <47 months",
        rate: 20.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetBetween(12, 47),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 21.99,
        productType: "Adverse",
        criteria: [

             ageOfAssetBetween(48, 95),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 22.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetGreaterThan(95),
            hasDeposit(20),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },









    {
        name: "Silver - Motor Vehicle New/Demo",
        rate: 21.99,
        productType: "Standard",
        criteria: [
            ageOfAssetLessThan(12),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle <47 months",
        rate: 21.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetBetween(12, 47),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 22.99,
        productType: "Adverse",
        criteria: [

             ageOfAssetBetween(48, 95),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle >95 months",
        rate: 23.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetGreaterThan(95),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },


    {
        name: "Silver - Motor Vehicle New/Demo",
        rate: 21.99,
        productType: "Standard",
        criteria: [

            ageOfAssetLessThan(12),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle <47 months",
        rate: 21.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetBetween(12, 47),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 22.99,
        productType: "Adverse",
        criteria: [

             ageOfAssetBetween(48, 95),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 23.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetGreaterThan(95),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },

    {
        name: "Silver - Motor Vehicle New/Demo",
        rate: 21.99,
        productType: "Standard",
        criteria: [

            ageOfAssetLessThan(12),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle <47 months",
        rate: 21.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetBetween(12, 47),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 22.99,
        productType: "Adverse",
        criteria: [

             ageOfAssetBetween(48, 95),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 23.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetGreaterThan(95),
            hasDeposit(10),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },







    {
        name: "Silver - Motor Vehicle New/Demo",
        rate: 22.99,
        productType: "Standard",
        criteria: [

            ageOfAssetLessThan(12),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle <47 months",
        rate: 22.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetBetween(12, 47),

            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 23.99,
        productType: "Adverse",
        criteria: [

             ageOfAssetBetween(48, 95),

            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle >95 months",
        rate: 24.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetGreaterThan(95),

            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([FULL_TIME, PART_TIME]),
            timeOfEmploymentGreaterThan(3),
            creditScoreBetween(399, 499),
        ],
    },


    {
        name: "Silver - Motor Vehicle New/Demo",
        rate: 22.99,
        productType: "Standard",
        criteria: [

            ageOfAssetLessThan(12),

            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle <47 months",
        rate: 22.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetBetween(12, 47),

            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 23.99,
        productType: "Adverse",
        criteria: [

             ageOfAssetBetween(48, 95),

            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 24.99,
        productType: "Adverse",
        criteria: [

            ageOfAssetGreaterThan(95),

            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([CASUAL]),
            timeOfEmploymentGreaterThan(6),
            creditScoreBetween(399, 499),
        ],
    },

    {
        name: "Silver - Motor Vehicle New/Demo",
        rate: 22.99,
        productType: "Standard",
        criteria: [
            ageOfAssetLessThan(12),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle <47 months",
        rate: 22.99,
        productType: "Adverse",
        criteria: [
            ageOfAssetBetween(12, 47),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 23.99,
        productType: "Adverse",
        criteria: [
             ageOfAssetBetween(48, 95),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },
    {
        name: "Silver - Motor Vehicle 48-95 months",
        rate: 24.99,
        productType: "Adverse",
        criteria: [
            ageOfAssetGreaterThan(95),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType([SELF_EMPLOYED, CONTRACT]),
            timeOfEmploymentGreaterThan(11),
            creditScoreBetween(399, 499),
        ],
    },
]


const lowLoanAmount = base.map(product => {

    return {
        ...product,
        criteria: [
            ...product.criteria,
            loanAmountBetween(5000, 12500),
            termIsBetween(24, 48),
            assetType(["Motor Vehicle", "Electric Motor Vehicle", "Motorbike"])
        ]
    }
})

const highLoanAmountBikes = base.map(product => {

    return {
        ...product,
        name: "Silver - Motorbike",
        criteria: [
            ...product.criteria,
            loanAmountBetween(12500, 20000),
            termIsBetween(36, 60),
            assetType(["Motorbike"])
        ]
    }
})

const highLoanAmountMotor = base.map(product => {
    return {
        ...product,
        criteria: [
            ...product.criteria,
            loanAmountBetween(12500, 30000),
            termIsBetween(36, 60),
            assetType(["Motor Vehicle", "Electric Motor Vehicle"])
        ]
    }
})

export default [
    ...lowLoanAmount,
    ...highLoanAmountBikes,
    ...highLoanAmountMotor
]

