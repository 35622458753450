import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, MenuItem, TextField } from "@mui/material";

import {
  updateAsset,
  assetSelector,
  saveAsset,
} from "../store/slices/applicationFormSlice";

import { handleMixpanelQuoteEvent } from "src/utils/mixPanelTrackEvents";

import { assetTypes } from "../assetTypes.json";
import consumerAssetTypes from "src/utils/consumerAssetTypes";
import { saveValue, userSelector } from "src/store/slices/userSlice";
import mixpanel from "src/services/mixpanel";

export default function AssetType({ applicationType }) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { applicationId } = useParams();
  const { assetType, _id } = useSelector(assetSelector);

  const assetTypesList =
    applicationType === "commercial" ? assetTypes : consumerAssetTypes;

  function handleUpdateAssetDetails(event) {
    handleMixpanelQuoteEvent(applicationId, "asset type", assetType);
    dispatch(updateAsset({ _id, assetType: event.target.value }));
    if (event.target.value === "Motorbike" && user.status === "new") {
      dispatch(saveValue({ walkthroughIndex: 1 }));
      mixpanel.track(`Demo: Step 2 - Living arrangements`);
    }
  }

  return (
    <TextField
      fullWidth
      className="second-step"
      style={{ background: "#ffffff" }}
      select
      value={assetType || ""}
      label="Asset type"
      size="small"
      onChange={(event) => {
        handleUpdateAssetDetails(event);
      }}
    >
      {assetTypesList.map((asset, i) => (
        <MenuItem key={asset.label} value={asset.label}>
          {asset.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
