import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  applicationsSelector,
  getQuoteList,
  quotesSelector,
  saveValue,
} from "../store/slices/listsSlice";
import _ from "lodash";
import { useEffect } from "react";
import { userSelector } from "src/store/slices/userSlice";
import { useNavigate, userNavigate } from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";
import mixpanel from "src/services/mixpanel";
import StartApplication from "src/components/StartApplication";

const referrerViewColumns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "humanId",
    headerName: "ID",
    type: "string",
    width: 100,
    editable: false,
  },
  {
    field: "startDate",
    headerName: "Date",
    type: "string",
    width: 80,
    editable: false,
  },
  {
    field: "time",
    headerName: "Time",
    type: "string",
    width: 80,
    editable: false,
  },

  {
    field: "quoteType",
    headerName: "Quote type",
    type: "string",
    width: 110,
    editable: true,
  },
  {
    field: "assetValue",
    headerName: "Asset value",
    type: "string",
    width: 110,
    editable: false,
  },

  {
    field: "assetType",
    headerName: "Asset type",
    type: "string",
    width: 250,
    editable: false,
  },
  {
    field: "customer",
    headerName: "Customer",
    width: 250,
    editable: false,
  },
  // {
  //   field: "entityName",
  //   headerName: "Entity",
  //   width: 250,
  //   // editable: true,
  // },
  // {
  //   field: "firstName",
  //   headerName: "First name",
  //   width: 150,
  //   // editable: true,
  // },
  // {
  //   field: "lastName",
  //   headerName: "Last name",
  //   width: 150,
  //   // editable: true,
  // },


  // {
  //   field: "term",
  //   headerName: "Term",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },
  // {
  //   field: "serviceFees",
  //   headerName: "3rd Party fees",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },


  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
];

const masterViewColumns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "humanId",
    headerName: "ID",
    type: "string",
    width: 90,
    editable: false,
  },
  {
    field: "startDate",
    headerName: "Date",
    type: "string",
    width: 80,
    editable: false,
  },
  {
    field: "time",
    headerName: "Time",
    type: "string",
    width: 80,
    editable: false,
  },

  {
    field: "referrer",
    headerName: "Referrer",
    type: "string",
    width: 180,
    editable: false,
  },
  {
    field: "quoteType",
    headerName: "Quote type",
    type: "string",
    width: 110,
    editable: true,
  },
  {
    field: "assetValue",
    headerName: "Asset value",
    type: "string",
    width: 110,
    editable: false,
  },

  {
    field: "assetType",
    headerName: "Asset type",
    type: "string",
    width: 250,
    editable: false,
  },
  {
    field: "customer",
    headerName: "Customer",
    width: 250,
    editable: false,
  },
  // {
  //   field: "entityName",
  //   headerName: "Entity",
  //   width: 250,
  //   // editable: true,
  // },
  // {
  //   field: "firstName",
  //   headerName: "First name",
  //   width: 150,
  //   // editable: true,
  // },
  // {
  //   field: "lastName",
  //   headerName: "Last name",
  //   width: 150,
  //   // editable: true,
  // },


  // {
  //   field: "term",
  //   headerName: "Term",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },
  // {
  //   field: "serviceFees",
  //   headerName: "3rd Party fees",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },


  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
];

export default function QuotesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const quotes = useSelector(quotesSelector);
  // const quotes = response.quotes
  const { isLoading, page } = quotes

  console.log({ quotes, page })

  const [rows, setRows] = useState([]);

  // const [page, setPage] = useState(0);

  // const queryOptions = React.useMemo(
  //   () => ({
  //     cursor: mapPageToNextCursor.current[page - 1],
  //     pageSize: 10,
  //   }),
  //   [page],
  // );

  // const { isLoading, data, pageInfo } = useQuery(queryOptions);

  const handlePageChange = (newPage) => {
    console.log({ newPage })
    // We have the cursor, we can allow the page transition.
    // if (newPage === 0 || quotes.nextPage) {
    mixpanel.track('Change quotes list page', {
      page: newPage + 1
    });
    handleGetAllApplications(user, newPage + 1);
    dispatch(saveValue({ page: newPage + 1 }))
    // }
  };

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(
    quotes?.totalDocs || 0,
  );

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      quotes?.totalDocs !== undefined
        ? quotes?.totalDocs
        : prevRowCountState,
    );
  }, [quotes?.totalDocs, setRowCountState]);

  function handleGetAllApplications(userId, cursor) {
    dispatch(getQuoteList({ userId, cursor }));
  }

  useEffect(() => {
    // console.log(user);
    console.log("mapPageToNextCursor", quotes?.page);
    if (user) handleGetAllApplications(user, page || 1);
    // dispatch(saveValue({ page: newPage }))
  }, [user]);

  useEffect(() => {

    setRows(
      quotes?.docs?.map((doc) => {
        // const newDoc = _.merge({}, doc);

        const date = new Date(doc.createdAt);
        // console.log("doc", doc);
        return {
          humanId: doc.humanId,
          customer: doc?.entities?.[0]?.entityName ?
            doc?.entities?.[0]?.entityName :
            `${doc?.customers?.[0]?.firstName || "No"} ${doc?.customers?.[0]?.lastName || "customer"}`,
          assetType: doc?.asset?.assetType,
          assetValue: `$${doc?.asset?.assetValue?.toLocaleString()}`,
          loanAmount: doc?.loanDetails?.loanAmount,
          term: doc?.loanDetails?.term,
          id: doc._id,
          startDate: moment(date).format("D-M-YY"),
          time: moment(date).format("h:mm a"),
          referrer: `${doc?.user?.firstName} ${doc?.user?.lastName}`,
          quoteType: doc?.applicationType?.[0]?.toUpperCase() + doc?.applicationType?.slice(1),
        };
      })
    );
    // console.log("rows", rows);
  }, [quotes]);

  useEffect(() => {
    mixpanel.track_pageview({
      "page": "Quotes list",
    });
  }, [])

  return (
    <Grid
      container
      item
      direction="column"
      spacing={0}
      style={{ padding: "30px" }}
    // style={{ marginTop: "64px", }}
    >
      {window.innerWidth > 700 ? <>
        <Grid item >
          <Typography
            variant="h1"
            style={{ fontSize: "32px", fontWeight: 800, letterSpacing: "-1.5px", marginBottom: "10px" }}
          >
            Quotes
          </Typography>
        </Grid>


        <Grid item >
          <DataGrid
            autoHeight
            autoPageSize
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
            onRowClick={(event) => {
              console.log(event);
              mixpanel.track('Quotes list: clicked quote', {
                applicationId: event.id
              });
              navigate(`/quote/${event.id}`);
            }}
            rows={rows || []}
            columns={user.userType === 'master' ? masterViewColumns : referrerViewColumns}
            rowsPerPageOptions={[10]}
            // checkboxSelection
            disableSelectionOnClick
            style={{ background: "#ffffff" }}
            // initialState={initialState}
            pagination
            pageSize={10}
            rowCount={rowCountState}
            paginationMode="server"
            onPageChange={handlePageChange}
            page={page - 1 || 0}
            loading={isLoading}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50px',
                    marginTop: '156px',
                    fontSize: "20px"
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "20px",
                      marginBottom: "30px"
                    }}
                  >You don't have any quotes. Add a new quote!</Typography>
                  <StartApplication buttonText={"Start a new quote"} />
                </Stack>
              )
            }}
            sx={{
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                width: '7px', height: "7px"
              },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                backgroundColor: '#999',
              },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                background: '#616161',
              },
            }}
          />
        </Grid>
      </>
        : <>
          {/* <Typography variant="h3">Sorry! :(</Typography> */}
          <Typography style={{ marginTop: 10 }}>The quoting tool is pretty advanced tech and requires a Desktop/Laptop/Tablet device to work at it's best. But we've got our boffins working on it and it will be mobile ready in no time!</Typography>
          <Typography style={{ marginTop: 60, fontWeight: 900, fontSize: 20 }}>Try it on a bigger device and see how advanced it is.</Typography>
          <Typography style={{ marginTop: 60 }}>Otherwise, you can chat to us by click on the purple button below.</Typography>
        </>
      }
    </Grid>
  );
}

