import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { Grid, Typography, TextField, Paper } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import {
  saveValue,
  userSelector,
  validateEmail,
  updateUser,
  updatePhoneNumber,
  changePassword,
  getUser,
} from "../store/slices/userSlice";

import regex from "../utils/regex";
import { current } from "@reduxjs/toolkit";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function UserProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const user = useSelector(userSelector);
  const { changePasswordSuccess, isChangingPassword, changePasswordFail } =
    useSelector(userSelector);

  // console.log("user", user);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState(" ");
  const [lastNameErrortext, setLastNameErrorText] = useState(" ");
  const [email, setEmail] = useState(user?.email?.address || "");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState(" ");
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [changePasswordButtonText, setChangePasswordButtonText] =
    useState("Change password");
  const [changePasswordButtonColor, setChangePasswordButtonColor] =
    useState("secondary");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState(" ");
  const [canSubmit, setCanSubmit] = useState(false);

  async function handleUpdateUser() {
    // dispatch(saveValue({ isCreatingAccount: true }));
    await dispatch(
      updateUser({
        firstName,
        lastName,
      })
    ).unwrap();
  }

  function handleFirstName(event) {
    setFirstName(event.target.value);

    if (regex.name.test(event.target.value)) {
      setFirstNameError(false);

      return setFirstNameErrorText(" ");
    }

    setFirstNameError(true);
    setFirstNameErrorText(
      "Name can contain only letters, dashes (-) or spaces."
    );
  }

  function handleLastName(event) {
    setLastName(event.target.value);

    if (regex.name.test(event.target.value)) {
      setLastNameError(false);

      return setLastNameErrorText(" ");
    }

    setLastNameError(true);
    setLastNameErrorText(
      "Name can contain only letters, dashes (-) or spaces."
    );
  }

  function handleEmail(event) {
    setEmail(event.target.value.trim());

    if (regex.email.test(event.target.value.toLowerCase())) {
      setEmailError(false);
      dispatch(saveValue({ emailInUse: false }));
      return setEmailErrorText(" ");
    }

    setEmailError(true);
    setEmailErrorText("Invalid email");
  }

  async function handleCheckEmail() {
    await dispatch(validateEmail(email)).unwrap();
  }

  function handleCurrentPassword(event) {
    console.log(event.target.value);
    setCurrentPassword(event.target.value);
  }

  function handleNewPassword(event) {
    setNewPassword(event.target.value);

    console.log(event.target.value, regex.password.test(event.target.value));
    regex.password.test(event.target.value)
      ? setNewPasswordError(false)
      : setNewPasswordError(true);
  }

  function handlePhoneNumber(event) {
    setPhoneNumber(event.target.value);

    // if (regex.email.test(event.target.value.toLowerCase())) {
    //   setEmailError(false);
    //   dispatch(saveValue({ emailInUse: false }));
    //   return setEmailErrorText(" ");
    // }

    // setEmailError(true);
    // setEmailErrorText("Invalid email");
  }

  async function handleChangePassword() {
    dispatch(saveValue({ isChangingPassword: true }));
    await dispatch(
      changePassword({
        newPassword,
        currentPassword,
      })
    ).unwrap();
  }

  async function handleUpdatePhoneNumber() {
    // dispatch(saveValue({ isCreatingAccount: true }));
    await dispatch(
      updatePhoneNumber({
        phoneNumber,
      })
    ).unwrap();
  }

  useEffect(() => {
    console.log(user);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user?.email?.address || "");
    setPhoneNumber(user?.phoneNumber?.number || "");
  }, [user]);

  useEffect(() => {
    if (
      currentPassword &&
      newPassword &&
      !currentPasswordError &&
      !newPasswordError
    )
      return setCanSubmit(true);

    setCanSubmit(false);
  }, [currentPassword, currentPasswordError, newPassword, newPasswordError]);

  useEffect(() => {
    console.log("changePasswordSuccess", changePasswordSuccess);
    if (changePasswordSuccess) {
      setCurrentPassword("");
      setNewPassword("");
      setChangePasswordButtonText("Password changed succesfully");
      setChangePasswordButtonColor("success");
      setTimeout(() => {
        setChangePasswordButtonText("Change password");
        setChangePasswordButtonColor("secondary");
        dispatch(saveValue({ changePasswordSuccess: false }));
      }, 4000);
    }
  }, [changePasswordSuccess]);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        style={{ marginTop: "64px" }}
      >
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={10}
          xl={8}
          height="100vh"
          // justifyContent="center"
          alignItems="center"
          direction="column"
          // textAlign="center"
          style={{ padding: "0 100px", marginTop: "64px" }}
        >
          <Paper style={{ padding: "40px" }}>
            <Typography
              variant="h5"
              fontWeight="800"
              style={{ letterSpacing: "-1.2px" }}
            >
              User profile
            </Typography>
            {/* <br />
          <br /> */}
            {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
            This is it.
          </Typography> */}
            <br />
            {/* <br /> */}

            <br />
            <Typography style={{ textAlign: "left", margin: "20px 0 10px" }}>
              Organisation
            </Typography>
            <Typography
              variant="body2"
              style={{ textAlign: "left", margin: "0 0 40px" }}
            >
              {user?.organisation?.name}
            </Typography>
            <Typography style={{ textAlign: "left", marginBottom: "10px" }}>
              Personal details
            </Typography>
            <TextField
              fullWidth
              // id="outlined-basic"
              type="text"
              name="firstName"
              label="First name"
              autocomplete="given-name"
              // variant="filled"
              // variant="outlined"
              size="small"
              value={firstName}
              inputProps={{ maxLength: 24 }}
              onChange={handleFirstName}
              margin="dense"
              error={firstNameError}
              helperText={firstNameErrorText}
              onBlur={handleUpdateUser}
            />

            <TextField
              fullWidth
              // id="outlined-basic"
              type="text"
              name="lastName"
              label="Last name"
              autocomplete="family-name"
              // variant="filled"
              // variant="outlined"
              size="small"
              value={lastName}
              inputProps={{ maxLength: 24 }}
              onChange={handleLastName}
              margin="dense"
              helperText={lastNameErrortext}
              error={lastNameError}
              onBlur={handleUpdateUser}
            />

            <TextField
              fullWidth
              // id="outlined-basic"
              type="tel"
              name="phone number"
              label="Phone number"
              autoComplete='off'
              // variant="tel"
              // variant="outlined"
              size="small"
              value={phoneNumber}
              onChange={handlePhoneNumber}
              margin="dense"
              helperText={phoneNumberErrorText}
              error={phoneNumberError}
              inputProps={{
                maxLength: 15, autoComplete: 'chrome-off', type: "tel", form: {
                  autocomplete: 'off',
                },
              }}
              onBlur={handleUpdatePhoneNumber}
            />
            <form>
              <TextField
                fullWidth
                // id="outlined-basic"
                type="email"
                name="email"
                label="Email"
                // autocomplete="email"
                // variant="filled"
                // variant="outlined"
                size="small"
                disabled
                value={email}
                onChange={handleEmail}
                margin="dense"
                helperText={emailErrorText}
                error={emailError}
                onBlur={handleCheckEmail}
                inputProps={{ maxLength: 50 }}
              />

              <Typography style={{ textAlign: "left", margin: "20px 0 10px" }}>
                Change password
              </Typography>

              <TextField
                fullWidth
                // id="outlined-basic"
                type="password"
                name="password"
                label="Current password"
                autocomplete="current-password"
                // variant="filled"
                // variant="outlined"
                size="small"
                value={currentPassword}
                onChange={handleCurrentPassword}
                error={changePasswordFail}
                margin="dense"
                helperText="Password does not match"
                inputProps={{ maxLength: 32 }}
              // onBlur={() => {}}
              />

              <TextField
                fullWidth
                // id="outlined-basic"
                type="password"
                name="new password"
                label="New password"
                autocomplete="new-password"
                // variant="filled"
                // variant="outlined"
                size="small"
                value={newPassword}
                onChange={handleNewPassword}
                error={newPasswordError}
                margin="dense"
                helperText="Must conatain an uppercase letter, a number, a special character and be 8 or more characters in length."
                inputProps={{ maxLength: 32 }}
              // onBlur={() => {}}
              />
            </form>

            <LoadingButton
              // fullWidth
              // endIcon={<SendOutlinedIcon />}
              loading={isChangingPassword}
              // loadingIndicator="Generating..."
              loadingPosition="end"
              variant="contained"
              disabled={!canSubmit && !changePasswordSuccess}
              color={changePasswordButtonColor}
              style={{ margin: "20px 0 40px", justifySelf: "start" }}
              onClick={handleChangePassword}
            >
              {changePasswordButtonText}
            </LoadingButton>
          </Paper>
        </Grid>
        {/* <Grid
          container
          item
          xs={12}
          sm={6}
          spacing={2}
          height="100vh"
          justifyContent="center"
          // alignItems="center"
          direction="column"
          style={{ padding: "100px" }}
        >
           <Typography variant="h4" fontWeight="800" style={{ letterSpacing: "-2px", textAlign: "left" }}>
            Financeable
          </Typography>
          <br />
          <br /> 
          <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}> 
            This is it.
          </Typography>
          <br />
          <br />
          <Typography variant="h3">
            The last finance platform you'll ever sign up for.
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
}
