const moment = require("moment");

export default {
  standard: {
    label: "Standard",
    object: "asset",
    attribute: "assetValue",
    condition: "is",
    value: true,
  },
  propertyOwner: {
    label: "Property owner",
    object: "customerProfile",
    attribute: "livingArrangements",
    condition: "exists",
    value: "Property Owner",
  },
  hasDefault: {
    label: "Has default",
    object: "creditProfile",
    attribute: "hasAdverseCredit",
    condition: "exists",
    value: true,
  },
  noDefault: {
    label: "Has default",
    object: "creditProfile",
    attribute: "hasAdverseCredit",
    condition: "doesntExist",
    value: false,
  },
  dateOfBirthLessThan: (value) => {
    const years = moment().subtract(value, "years").format("YYYY-MM-DD")
    return {
      label: `Age of customer <${value} years`,
      object: "customer",
      attribute: "dateOfBirth",
      condition: "greaterThan",
      value: years
    }
  },
  dateOfBirthGreaterThan: (value) => {
    const years = moment().subtract(value, "years").format("YYYY-MM-DD")

    return {
      label: `Age of customer >${value} years`,
      object: "customer",
      attribute: "dateOfBirth",
      condition: "lessThan",
      value: years
    }
  },
  dateOfBirthBetween: (min, max) => {
    const minYears = moment().subtract(min, "years").format("YYYY-MM-DD")
    const maxYears = moment().subtract(max, "years").format("YYYY-MM-DD")

    return {
      label: `Age of customer ${min}-${max} years`,
      object: "customer",
      attribute: "dateOfBirth",
      condition: "betweenLow",
      value: {
        min: maxYears,
        max: minYears,
      },
    }
  },
  // noDeposit: {
  //   label: "No deposit",
  //   object: "loanDetails",
  //   attribute: "deposit",
  //   condition: "zero",
  // },
  nonGST: {
    label: "Not GST registered",
    object: "entity",
    attribute: "gst",
    condition: "isNot",
  },
  termIsLessThan: (value) => {
    return {
      label: `Term <${value} months`,
      object: "loanDetails",
      attribute: "term",
      condition: "lessThan",
      value
    }
  },
  termIsBetween: (min, max) => {
    return {
      label: `Term ${min} - ${max} months`,
      object: "loanDetails",
      attribute: "term",
      condition: "betweenLow",
      value: {
        min,
        max,
      },
    }
  },
  termIsLessThanOrEqualTo: (value) => {
    return {
      label: `Term max ${value} months`,
      object: "loanDetails",
      attribute: "term",
      condition: "lessThanOrEqualTo",
      value
    }
  },
  termIsGreaterThan: (value) => {
    return {
      label: `Term >${value} months`,
      object: "loanDetails",
      attribute: "term",
      condition: "greaterThan",
      value
    }
  },
  depositGreaterThanOrEqualTo: (value) => {
    return {
      label: "20% deposit",
      object: "loanDetails",
      attribute: "deposit",
      condition: "greaterThanOrEqualTo",
      value
    }
  },
  nonPropertyOwner: {
    label: "Not a property owner",
    object: "customerProfile",
    attribute: "livingArrangements",
    condition: "doesntExist",
    value: "Property Owner",
  },
  eachCreditScoreGreaterThan: (value) => {
    return {
      label: `Credit score >${value}`,
      object: "creditProfile",
      attribute: "score",
      condition: "eachIsGreaterThan",
      value,
    };
  },
  creditScoreGreaterThan: (value) => {
    return {
      label: `Credit score >${value}`,
      object: "creditProfile",
      attribute: "score",
      condition: "greaterThan",
      value
    }
  },
  creditScoreLessThan: (value) => {
    return {
      label: `Credit score <${value}`,
      object: "creditProfile",
      attribute: "score",
      condition: "lessThan",
      value
    }
  },
  creditScoreBetween: (min, max) => {
    return {
      label: `Credit score ${min} - ${max}`,
      object: "creditProfile",
      attribute: "score",
      condition: "between",
      value: {
        min,
        max,
      },
    };
  },
  employmentType: (value) => {
    return {
      label: "Employment type",
      object: "employer",
      attribute: "employmentType",
      condition: "hasOne",
      value,
    };
  },
  timeOfEmploymentGreaterThan: (value) => {
    return {
      label: `Employed >${value} months`,
      object: "employer",
      attribute: "timeOfEmployment",
      condition: "greaterThan",
      value,
    };
  },
  livingArrangements: (value) => {
    return {
      label: "Living arrangements",
      object: "customerProfile",
      attribute: "livingArrangements",
      condition: "hasOne",
      value,
    };
  },
  typeOfSale: (value) => {
    return {
      label: "Type of sale",
      object: "asset",
      attribute: "typeOfSale",
      condition: "hasOne",
      value,
    };
  },
  typeOfSaleMany: (value) => {
    return {
      label: "Type of sale",
      object: "asset",
      attribute: "typeOfSale",
      condition: "hasOne",
      value,
    };
  },
  hasDeposit: (value) => {
    // console.log({ value })
    return {
      label: `${value}% deposit`,
      object: "loanDetails",
      attribute: "deposit",
      condition: "greaterThanOrEqualTo",
      value,
    };
  },
  depositBetween: (min, max) => {
    return {
      label: `Deposit ${min}-${max}% `,
      object: "loanDetails",
      attribute: "deposit",
      condition: "between",
      value: {
        min,
        max,
      },
    };
  },
  noDeposit: (value) => {
    return {
      label: `No deposit`,
      object: "loanDetails",
      attribute: "deposit",
      condition: "zero",
      value,
    };
  },
  assetValueGreaterThan: function (value) {
    return {
      label: `Asset value >$${value}`,
      object: "asset",
      attribute: "assetValue",
      condition: "greaterThan",
      value,
    };
  },
  assetValueBetween: function (min, max) {
    return {
      label: `Asset value $${min} - $${max}`,
      object: "asset",
      attribute: "assetValue",
      condition: "between",
      value: {
        min,
        max,
      },
    };
  },
  assetType: function (value) {
    return {
      label: "Asset Type",
      object: "asset",
      attribute: "assetType",
      condition: "hasOne",
      value,
    };
  },
  ageOfAssetNewOrDemo: {
    label: `Asset New/Demo`,
    object: "asset",
    attribute: "ageOfAsset",
    condition: "hasOne",
    value: ["New", "Demo"]
  },
  ageOfAssetGreaterThan: function (value) {
    const monthsToYears = moment().subtract(value, "months").format("YYYY");
    return {
      label: `Asset age >${value} mths`,
      object: "asset",
      attribute: "ageOfAsset",
      condition: "lessThan",
      value: monthsToYears,
    };
  },
  ageOfAssetGreaterThanOrEqualTo: function (value) {
    const monthsToYears = moment().subtract(value, "months").format("YYYY");
    return {
      label: `Asset age >${value} mths`,
      object: "asset",
      attribute: "ageOfAsset",
      condition: "lessThanOrEqualTo",
      value: monthsToYears,
    };
  },
  ageOfAssetLessThan: function (value) {
    const monthsToYears = moment().subtract(value, "months").format("YYYY");
    return {
      label: `Asset age <${value} mths`,
      object: "asset",
      attribute: "ageOfAsset",
      condition: "greaterThan",
      value: monthsToYears,
    };
  },
  ageOfAssetLessThanOrEqualTo: function (value) {
    const monthsToYears = moment().subtract(value, "months").format("YYYY");

    return {
      label: `Asset age <${value} mths`,
      object: "asset",
      attribute: "ageOfAsset",
      condition: "greaterThanOrEqualTo",
      value: monthsToYears,
    };
  },
  ageOfAssetBetween: function (min, max) {
    const monthsToYearsMin = moment().subtract(min, "months").format("YYYY");
    const monthsToYearsMax = moment().subtract(max, "months").format("YYYY");
    return {
      label: `Asset age ${min} - ${max} mths`,
      object: "asset",
      attribute: "ageOfAsset",
      condition: "betweenLow",
      value: {
        min: monthsToYearsMax,
        max: monthsToYearsMin,
      },
    };
  },
  ageOfAssetAtEndGreaterThan: function (value) {
    return {
      label: `End of term age >${value} yrs`,
      object: "asset",
      attribute: "ageOfAssetAtEnd",
      condition: "greaterThan",
      value,
    };
  },
  ageOfAssetAtEndLessThan: function (value) {
    return {
      label: `End of term age <${value} yrs`,
      object: "asset",
      attribute: "ageOfAssetAtEnd",
      condition: "lessThan",
      value,
    };
  },
  ageOfAssetAtEndBetween: function (min, max) {
    return {
      label: `End of term age ${min} - ${max}yrs`,
      object: "asset",
      attribute: "ageOfAssetAtEnd",
      condition: "between",
      value: {
        min,
        max,
      },
    };
  },
  loanAmountGreaterThan: function (value) {
    return {
      label: `Loan amount >$${value}`,
      object: "loanDetails",
      attribute: "loanAmount",
      condition: "greaterThan",
      value,
    };
  },
  loanAmountLessThan: function (value) {
    return {
      label: `Loan amount <$${value}`,
      object: "loanDetails",
      attribute: "loanAmount",
      condition: "lessThan",
      value,
    };
  },
  loanAmountBetween: function (min, max) {
    return {
      label: `Loan amount $${min} - $${max}`,
      object: "loanDetails",
      attribute: "loanAmount",
      condition: "betweenLow",
      value: {
        min,
        max,
      },
    };
  },

  timeInBusinessBetween: function (min, max) {
    return {
      label: `Time in business ${min} - ${max} mths`,
      object: "entity",
      attribute: "timeInBusiness",
      condition: "betweenLow",
      value: {
        min: moment(moment().add(-max, "M")).format("YYYY-MM-DD"),
        max: moment(moment().add(-min, "M")).format("YYYY-MM-DD"),
      },
    };
  },
  timeInBusinessLessThan: function (value) {
    return {
      label: `Time in business <${value} mths`,
      object: "entity",
      attribute: "timeInBusiness",
      condition: "greaterThan",
      value: moment(moment().add(-value, "M")).format("YYYY-MM-DD"),
    };
  },
  timeInBusinessGreaterThan: function (value) {
    return {
      label: `Time in business >${value} mths`,
      object: "entity",
      attribute: "timeInBusiness",
      condition: "lessThan",
      value: moment(moment().add(-value, "M")).format("YYYY-MM-DD"),
    };
  },
  gstRegistrationGreaterThan: function (value) {
    return {
      label: `GST reg. >${value} mths`,
      object: "entity",
      attribute: "gst",
      condition: "lessThan",
      value: moment(moment().add(-value, "M")).format("YYYY-MM-DD"),
    };
  },
  gstRegistrationLessThan: function (value) {
    return {
      label: `GST reg. <${value} mths`,
      object: "entity",
      attribute: "gst",
      condition: "greaterThan",
      value: moment(moment().add(-value, "M")).format("YYYY-MM-DD"),
    };
  },
  gstRegistrationBetween: function (min, max) {
    return {
      label: `GST reg. ${min} - ${max} mths`,
      object: "entity",
      attribute: "gst",
      condition: "betweenLow",
      value: {
        min: moment(moment().add(-max, "M")).format("YYYY-MM-DD"),
        max: moment(moment().add(-min, "M")).format("YYYY-MM-DD"),
      },
    };
  },
  customerDefaultAmountLessThan: (value) => {
    return {
      label: `Customer defaults value <$${value}`,
      object: "customer",
      attribute: "defaultValue",
      condition: "lessThan",
      value: value,
    };
  },
  brokerageAmount: (min, max) => {
    return {
      label: `Brokerage amount`,
      object: "loanDetails",
      attribute: "brokerageRate",
      condition: "betweenLow",
      value: {
        min,
        max,
      },
    };
  },
  compound: (obj) => {
    return {
      label: obj.name,
      object: "loanDetails",
      conditional: obj.conditional,
      condition: "compound",
      primary: obj.primary,
      criteria: obj.criteria,
    };
  },
};
