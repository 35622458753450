import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useDispatch, useSelector } from 'react-redux';
import { userSelector, saveValue as saveUserValue } from 'src/store/slices/userSlice';

import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import {
    referQuote,
    addEmail,
    addPhoneNumber,
    applicationSelector,
    updateCustomer,
    userDetailsSelector,
    saveValue
} from 'src/store/slices/applicationFormSlice';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'src/services/mixpanel';

import AddOrganisationDetails from "./AddOrganisationDetails"
import GoogleAddressSearch from "./GoogleAddressSearch"
import ReferralAgreement from 'src/components/ReferralAgreement';
import { updateOrganisation } from 'src/store/slices/organisationSlice';
import readibleAbnString from 'src/utils/readableABNString';

const dollarStringOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
}

export default function Refer({ productsMap, quoteDetails }) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const user = useSelector(userSelector)
    const application = useSelector(applicationSelector)
    const {
        hasReferedQuote
    } = useSelector(userDetailsSelector)

    const { openReferModal } = user
    const customerId = application?.customers?.[0]?._id

    const [firstName, setFirstName] = useState(application?.customers?.[0]?.firstName)
    const [lastName, setLastName] = useState(application?.customers?.[0]?.lastName)
    const [number, setNumber] = useState(application?.customers?.[0]?.phoneNumbers?.[0]?.number)
    const [email, setEmail] = useState(application?.customers?.[0]?.emails?.[0]?.address)
    const [sendToCustomer, setSendToCustomer] = useState(false)

    const [businessAddress, setBusinessAddress] = useState()
    const [businessDetails, setBusinessDetails] = useState()
    const [hasAddedBusinessDetails, setHasAddedBusinessDetails] = useState(false)
    const [referralStep, setReferralStep] = useState(0)

    useEffect(() => {
        setFirstName(application?.customers?.[0]?.firstName)
        setLastName(application?.customers?.[0]?.lastName)
        setNumber(application?.customers?.[0]?.phoneNumbers?.[0]?.number)
        setEmail(application?.customers?.[0]?.emails?.[0]?.address)
    }, [application?.customers])

    const handleClose = () => {
        dispatch(saveValue({ openReferModal: false }))
    };

    const products = productsMap
        .filter(p => p.qualification === 0 || p.qualification === 1)

    function handleProductMatchesExact() {
        return products.filter(p => p.qualification === 0).length
    }


    function handleProductMatchesNear() {
        return products.filter(p => p.qualification === 1).length
    }

    function handleMaxRepayments() {
        return Math.max.apply(Math, products.map((p) => { return p.repayments; }))
    }

    function handleMinRepayments() {
        // console.log(Math.min.apply(Math, products.map((p) => { return p.repayments })))
        return Math.min.apply(Math, products.map((p) => { return p.repayments; }))
    }

    function handleAverageLoanAmount() {
        const sum = products.reduce((a, b) => a + b.repayments, 0);
        return (sum / products.length) * quoteDetails.term || 0
    }

    function handleMaxRate() {
        return Math.max.apply(Math, products.map((p) => { return p.rate; })).toFixed(2)
    }

    function handleMinRate() {
        return Math.min.apply(Math, products.map((p) => { return p.rate; })).toFixed(2)
    }

    async function handleReferal() {
        // await dispatch(updateCustomer({
        //     customerId,

        // })).unwrap(),
        //     await dispatch(addPhoneNumber({
        //         customerId,

        //     })).unwrap(),
        //     await dispatch(addEmail({
        //         customerId,

        //     })).unwrap()
        const data = {
            applicationId: application._id,
            firstName,
            lastName,
            number,
            customerId,
            address: email,
            status: "referral",
            totalLoanAmount: handleAverageLoanAmount(),
            minRepayments: handleMinRepayments(),
            maxRepayments: handleMaxRepayments(),
            minRate: handleMinRate(),
            maxRate: handleMaxRate()
        }

        mixpanel.track('Referred customer', data);
        await dispatch(referQuote(data)).unwrap()
    }

    function handleClearForm() {
        dispatch(saveUserValue({ openReferModal: false }))
        setTimeout(() => {
            // setFirstName(null)
            // setLastName(null)
            // setNumber(null)
            // setEmail(null)
            setSendToCustomer(null)
            dispatch(saveValue({
                hasReferedQuote: null,
            }))
            navigate("/quotes")
        }, 200)
    }

    function handleBusinessAddress(address) {
        console.log({ address })
        setBusinessAddress(address)
    }

    function handleBusinessDetails(details) {
        console.log({ details })
        setBusinessDetails(details)
    }

    function handleSignReferralAggrementSuccess(step) {
        setReferralStep(step)
    }

    return (
        <Dialog open={openReferModal} onClose={handleClose} aria-labelledby="form-dialog-title">
            {!hasReferedQuote ? <DialogTitle id="form-dialog-title">Referral form</DialogTitle> :
                <DialogTitle id="form-dialog-title">Referral has been sent</DialogTitle>}

            <DialogContent style={{ width: "600px" }}>

                {/* {!user?.referrer && !hasAddedBusinessDetails && <> */}
                {(!user.referrer && referralStep === 0) && <>
                    <Typography style={{ margin: "0 0 10px 0", fontSize: "18px" }}>
                        Add your business details below
                    </Typography>

                    <Typography style={{ margin: "0 0 10px 0", fontSize: "14px" }}>
                        We'll need them for things like paying your fees. You'll only need to do this part one time.
                    </Typography>
                    <Grid container spacing={2} >
                        <Grid item md={12} style={{ margin: "10px 0 0" }}>
                            {businessDetails ?
                                <Stack alignItems="center" direction="row">
                                    <Typography>
                                        <strong>Name: </strong>
                                        {businessDetails.EntityName} ({readibleAbnString(businessDetails.Abn)})

                                    </Typography>
                                    <span style={{ marginLeft: "10px", textAlign: "right", fontSize: "14px", color: "#6A0BE3", cursor: "pointer" }}
                                        onClick={() => setBusinessDetails(null)}>change</span>
                                </Stack> :
                                <AddOrganisationDetails handleAddBusinessDetails={handleBusinessDetails} />
                            }
                        </Grid>
                        <Grid item md={12} style={{ margin: "10px 0 0" }}>
                            {businessAddress ?
                                <Stack alignItems="center" direction="row">
                                    <Typography>
                                        <strong>Address: </strong>
                                        {businessAddress.description}

                                    </Typography>
                                    <span style={{ marginLeft: "10px", textAlign: "right", fontSize: "14px", color: "#6A0BE3", cursor: "pointer" }}
                                        onClick={() => setBusinessAddress(null)}>change</span>
                                </Stack> :
                                <GoogleAddressSearch handleCreateAddress={handleBusinessAddress} />
                            }
                        </Grid>

                    </Grid>
                </>}

                {(!user.referrer && referralStep === 1)
                    // (!user.referrer && !user?.organisation?.abn)
                    && <>
                        <ReferralAgreement handleSuccess={handleSignReferralAggrementSuccess} />
                    </>}


                {(user.referrer || referralStep === 2) && <>
                    {hasReferedQuote ?
                        <>
                            <Typography style={{ margin: "0 0 10px 0", fontSize: "24px" }}>
                                Thanks for the referral!
                            </Typography>
                            <Typography style={{ margin: "0 0 5px 0", fontSize: "18px" }}>
                                Dhiren from the Financeable team will be in touch with {firstName} right
                                away to get this loan done as quickly as financeable-y possible!
                            </Typography>
                        </> :
                        <Grid container spacing={3}>
                            <Grid item sm={6}>
                                <Typography style={{ margin: "0 0 5px 0", fontSize: "18px" }}>Indicative quote</Typography>

                                {/* <Typography style={{ margin: "0 0 5px 0" }}>
                                <strong style={{ fontSize: "14px" }}>
                                    Product fit
                                </strong>
                                <br></br>
                                Exact: {handleProductMatchesExact()} {handleProductMatchesExact() < 4 && `/ Close: ${handleProductMatchesNear() > 9 ? "+10" : handleProductMatchesNear()}`}
                            </Typography> */}
                                <Typography style={{ margin: "0 0 5px 0" }}><strong style={{ fontSize: "14px" }}>Interest rate</strong><br></br>
                                    {handleMinRate()} - {handleMaxRate()}%
                                </Typography>
                                <Typography style={{ margin: "0 0 5px 0" }}> <strong style={{ fontSize: "14px" }}>Repayments (mth)</strong> <br></br>
                                    {handleMinRepayments().toLocaleString("en-US", dollarStringOptions)} - {handleMaxRepayments().toLocaleString("en-US", dollarStringOptions)}
                                </Typography>
                                <Typography style={{ margin: "0 0 5px 0" }}><strong style={{ fontSize: "14px" }}>Repayments (wk) </strong><br></br>
                                    {(handleMinRepayments() / 4).toLocaleString("en-US", dollarStringOptions)} - {(handleMaxRepayments() / 4).toLocaleString("en-US", dollarStringOptions)}
                                </Typography>
                                <Typography style={{ margin: "0 0 5px 0" }}><strong style={{ fontSize: "14px" }}>Total loan amount (avg)</strong> <br></br>
                                    {handleAverageLoanAmount().toLocaleString("en-US", dollarStringOptions)}
                                </Typography>

                            </Grid>
                            <Grid item sm={6} >
                                <Typography style={{ margin: "0 0 8px 0", fontSize: "18px" }}>Customer details</Typography>
                                <Typography style={{ margin: "0 0 -5px 0", fontSize: "12px" }}>All fields required</Typography>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="First name"
                                    type="name"
                                    placeholder="John"
                                    fullWidth
                                    autoComplete="off"
                                    value={firstName}
                                    size="small"
                                    onChange={event => setFirstName(event.target.value)}
                                    onBlur={() => mixpanel.track('Refer: Added first name', {
                                        applicationId: application._id,
                                        value: firstName
                                    })}
                                />
                                <TextField
                                    margin="dense"
                                    id="name"
                                    label="Last name"
                                    type="name"
                                    placeholder="Smith"
                                    fullWidth
                                    autoComplete="off"
                                    value={lastName}
                                    size="small"
                                    onChange={event => setLastName(event.target.value)}
                                    onBlur={() => mixpanel.track('Refer: Added last name', {
                                        applicationId: application._id,
                                        value: lastName
                                    })}
                                />
                                <TextField
                                    margin="dense"
                                    id="name"
                                    label="Phone number"
                                    type="tel"
                                    placeholder="0400 000 000"
                                    fullWidth
                                    autoComplete="off"
                                    value={number}
                                    size="small"
                                    onChange={event => setNumber(event.target.value)}
                                    onBlur={() => mixpanel.track('Refer: Added phone number', {
                                        applicationId: application._id,
                                        value: number
                                    })}
                                />
                                <TextField

                                    margin="dense"
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    placeholder="name@email.com"
                                    fullWidth
                                    autoComplete="off"
                                    value={email}
                                    size="small"
                                    onChange={event => setEmail(event.target.value)}
                                    onBlur={() => mixpanel.track('Refer: Added email', {
                                        applicationId: application._id,
                                        value: email
                                    })}
                                />
                                <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
                                    <Checkbox style={{ padding: "9px 9px 9px 0" }} checked={sendToCustomer} onChange={event => setSendToCustomer(event.target.value)} />
                                    <Typography style={{ fontSize: "12px" }}>Send a copy to {firstName || "the customer"}?</Typography>

                                </FormControl>
                            </Grid>

                        </Grid>


                    }
                </>
                }

            </DialogContent>

            {
                (user?.referrer || referralStep === 2) &&
                <DialogActions style={{ padding: "20px" }}>

                    {hasReferedQuote ? <Button
                        onClick={() => {
                            handleClearForm()
                        }}
                        color="secondary"
                        variant="contained"
                    >Close</Button> :
                        <>
                            <Button onClick={() => dispatch(saveUserValue({ openReferModal: false }))} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={handleReferal}
                                color="secondary"
                                variant="contained"
                                disabled={!firstName || !lastName || !number || !email}
                            >
                                Send referral
                            </Button>
                        </>
                    }
                </DialogActions>
            }

            {
                (!user?.referrer && referralStep === 1) &&
                <DialogActions style={{ padding: "20px" }}>
                    <>
                        <Button
                            onClick={() => {
                                handleClearForm()
                            }}
                                color="primary"
                            variant="contained"
                        >Cancel</Button>
                    </>
                </DialogActions>
            }

            {
                (!user?.referrer && referralStep === 0) &&
                <DialogActions style={{ padding: "20px" }}>

                    <>
                        <Button
                            onClick={
                                () => dispatch(saveUserValue({ openReferModal: false }))
                            }
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={async () => {
                                await dispatch(updateOrganisation({
                                    businessAddress,
                                    businessDetails
                                })).unwrap()
                                setReferralStep(1)
                            }}
                            color="secondary"
                            variant="contained"
                            disabled={!businessAddress || !businessDetails}
                        >
                            Next
                        </Button>
                    </>

                </DialogActions>
            }
        </Dialog >
    );
}
