import { typeOfSaleList, livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

const {
  PROPERTY_OWNER,
  RENTING,
  BOARDING,
  LIVING_WITH_PARENTS
} = livingArrangementsTypes

const {
  SELF_EMPLOYED,
  FULL_TIME,
  PART_TIME,
  CONTRACT,
  CASUAL,
  BENEFITS
} = employmentTypes

const {
  termIsBetween,
  assetType,
  loanAmountBetween,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
} = criteria

export default [
  {
    name: "Mid Prime",
    rate: 22.99,
    productType: "Adverse",
    criteria: [
      loanAmountBetween(8000, 50000),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
      ]),
      termIsBetween(36, 60),
      livingArrangements([
        PROPERTY_OWNER,
        RENTING,
        BOARDING,
        LIVING_WITH_PARENTS
      ]),
      employmentType([
        FULL_TIME,
        PART_TIME,
        CASUAL,
        CONTRACT
      ]),
      timeOfEmploymentGreaterThan(6),
      creditScoreGreaterThan(500),
    ],
  },
]
