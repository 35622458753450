import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Box, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactSignatureCanvas from "react-signature-canvas";
import { submitReferralAgreement, userSelector } from "src/store/slices/userSlice";

export default function ReferralAgreement({ businessDetails, businessAddress, handleSuccess }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  let sigCanvas = useRef()
  const user = useSelector(userSelector)

  const [signature, setSignature] = useState()
  const [submitted, setSubmitted] = useState(false)

  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  console.log({ params });

  useEffect(() => {
    // if (!customerId || !applicationId) navigate("/404")
  }, [])

  function trimSignature() {
    setSignature(sigCanvas.getTrimmedCanvas()
      .toDataURL('image/jpg'))
  }

  async function handleSubmitReferralAgreement() {
    await dispatch(
      submitReferralAgreement({ userId: user._id, buffer: signature })
    ).unwrap()
    handleSuccess(2)
    setSubmitted(true)
  }

  return <Grid
    container
    item
    // xs={12}
    style={{
      // padding: "30px 30px 0",
      // background: "#efefef",
      // minHeight: "calc(100vh - 64px)"
    }}
  >
    <Grid container item xs={12} justifyContent="center">
      <Grid item direction="column" sm={12} >

        {!submitted &&
          <>
            <Typography
              variant="h1"
              style={{
                fontSize: "24px",
                marginBottom: "20px"
              }}
            >
              Referrer Agreement
            </Typography>
            <Typography
              variant="h2"
              style={{
                fontSize: "18px",
                marginBottom: "30px"
              }}>
              Please read the agreement then sign below using the signing pad.
            </Typography>

            <Accordion style={{ marginBottom: "40px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: "5px 30px" }}
              >
                <Typography>Show Referrer Agreement</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "20px 30px" }}>

                <Typography style={{ marginBottom: "5px", fontSize: 22, fontWeight: 900 }}>
                  Financeable Pty Ltd Limited Affiliate Referral Agreement
                </Typography>
                <Typography style={{ marginTop: "20px" }}>
                  BETWEEN:

                </Typography>
                <Typography  >
                  Financeable Pty Ltd ABN: 24 646 900 736 ("us/we")
                </Typography>

                <Typography>
                  AND
                </Typography>
                <Typography style={{ marginBottom: "20px" }}>
                  {businessDetails?.EntityName} ("you")
                </Typography>

                <Typography style={{ fontSize: 13 }}>Broker Referrer Appointment Offer</Typography>
                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  We would be pleased to accept your referrals of consumers seeking finance to which the National Credit Code applies.
                  This agreement is an offer to appoint you as a referrer for that purpose and to set the strict terms that will apply to each referral.
                  This offer is based on the information you have provided to us about your business. If any aspect of that changes, you must inform us immediately.
                  When you accept this offer, including the Referral Terms and Conditions below, it automatically creates an agreement between us.
                  You may accept this offer by either –
                  • acknowledging acceptance by signing this offer and returning it me by mail or email; or
                  • by making the first referral to us in accordance with Schedule 1 to the Referrer Terms and Conditions.
                  In making referrals to us, you will be acting only as an intermediary under the National Consumer Credit Protection Act and not providing credit assistance.
                  You must make referrals to us strictly in accordance with Schedule 1 to the Referrer Terms.
                </Typography>

                <Typography style={{ fontSize: 13 }}>APPOINTMENT OF YOU AS REFERRER</Typography>
                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  From the date you accept these terms and conditions, we agree to accept consumer credit customer referrals from you.
                  <ul style={{ marginLeft: 0, paddingLeft: 18 }}>
                    <li>You are not our agent or representative.</li>
                    <li>In making referrals to us, you will be acting only as an intermediary under the National Consumer Credit Protection Act (‘NCCP Act’) and not providing credit assistance.</li>
                    <li>This Agreement may be terminated by either party giving 30 days' written notice of termination to the other. We may also terminate this Agreement without notice if we reasonably believe you are not complying with these terms and conditions.</li>
                  </ul>
                </Typography>

                <Typography style={{ fontSize: 13 }}>REPRESENTATIONS AND WARRANTIES</Typography>
                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  You represent and warrant to us that you hold an Australian Credit Licence for the credit activities of providing credit services. You must inform us immediately if any of following occurs:
                  <ul style={{ marginLeft: 0, paddingLeft: 18 }}>
                    <li>you, or any of your directors or employees, are banned from engaging in a credit activity under a state or territory law, or under Part 2-4 of the NCCP Act; or</li>
                    <li>your Australian Credit Licence is amended or cancelled for any reason; or</li>
                    <li>you change your business name and/or address.</li>
                  </ul>
                  You must provide us with reasonable assistance when requested to help resolve issues that arise out of consumer complaints regarding referrals.
                  You must provide us with reasonable assistance when requested to confirm your compliance with this Agreement and the NCCP Act.
                </Typography>

                <Typography style={{ fontSize: 13 }}>YOUR OBLIGATIONS</Typography>
                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  It is an essential term of this Agreement that you strictly comply with Schedule 1 and all written criteria, instructions and directions from us in relation to referrals.
                </Typography>

                <Typography style={{ fontSize: 13 }}>REMUNERATION AND BENEFITS</Typography>
                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  We will pay you remuneration and provide benefits (if any) in accordance with Schedule 2 to these terms. We may vary the amount of, or basis for calculating, remuneration, or both, or the provision of benefits, from time to time by notice in writing to you.
                </Typography>

                <Typography style={{ fontSize: 13 }}>INDEMNITY BY REFERRER</Typography>
                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  You indemnify us for all loss which may be incurred by us in respect of any claims made by a consumer or any other person arising out of any unauthorised actions by you or your representatives.
                  Neither termination of this Agreement, nor any variation of it, will affect your obligations in respect of any prior referral given to us, and your liability arising out of this Agreement will constitute a continuing liability.
                </Typography>

                <Typography style={{ fontSize: 13 }}>WHOLE AGREEMENT</Typography>
                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  This Agreement:
                  <ul style={{ marginLeft: 0, paddingLeft: 18 }}>
                    <li>records our entire agreement under which we will accept your referral of consumers seeking finance to which the National Credit Code applies; and</li>
                    <li>supersedes all previous negotiations, understandings and agreements on that subject.</li>
                  </ul>
                </Typography>

                <Typography style={{ fontSize: 13 }}>INVALIDITY</Typography>
                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  Even if a part of this Agreement is for any reason invalid or unenforceable, the remaining portion remains in full effect as if each party had agreed to it without the invalid portion.
                </Typography>

                <Typography style={{ fontSize: 13 }}>GOVERNING LAW AND JURISDICTION</Typography>
                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  This document is governed by the laws of New South Wales.
                  The parties submit to the jurisdiction of its courts.
                </Typography>

                <Typography style={{ fontSize: 13, fontWeight: 900 }}>SCHEDULE 1</Typography>
                <Typography style={{ fontSize: 13 }}>GOVERNING LAW AND JURISDICTION</Typography>
                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  <ol style={{ marginLeft: 0, paddingLeft: 18 }}>
                    <li>When referring a consumer to us under this Agreement, you must:</li>
                    <ol style={{ marginLeft: 0, paddingLeft: 18 }}>
                      <li>only act as an intermediary in referring consumers to us,</li>
                      <li>only inform a consumer we are able to provide a particular credit activity or a class of credit activity, not a specific product</li>
                      <li>refrain from discussing with the consumer his or her credit requirements or objectives or financial circumstances</li>
                      <li>provide us with a consumer’s name and contact details within 5 business days of obtaining those details from the consumer</li>
                      <li>not give us any other information about a consumer other than his/her contact details and a short description of the purpose for which the consumer may want credit or a consumer lease (if known)</li>
                      <li>disclose to a consumer (prior to giving the referral) any remuneration or other benefits you or an associate of yours may receive from us under Schedule 2 to this Agreement</li>
                      <li>obtain each consumer’s written consent to give us his/her contact details</li>
                      <li>not require a consumer to pay anyone a fee for the referral to us.</li>
                    </ol>
                    <li>You must:</li>
                    <ol style={{ marginLeft: 0, paddingLeft: 18 }}>
                      <li>permit only your staff to engage in this conduct on your behalf; and</li>
                      <li>ensure your staff are instructed and trained to conduct referral activity strictly in accordance with the above conduct requirements.</li>
                    </ol>
                    <li>We will:</li>
                    <ol style={{ marginLeft: 0, paddingLeft: 18 }}>
                      <li>contact the consumer within 10 business days of receiving consumer referral details from you;</li>
                      <li>inform the consumer that their contact details were obtained from you;</li>
                      <li>explain the financial benefits, if any, that you may receive for the referral; and</li>
                      <li>specifically ask the consumer whether they agree to continue with the conversation.</li>
                    </ol>
                  </ol>
                </Typography>
                <Typography style={{ fontSize: 13, fontWeight: 900 }}>SCHEDULE 2</Typography>
                <Typography style={{ fontSize: 13, marginBottom: 10, }}>REMUNERATION & BENEFITS</Typography>
                <table style={{ fontSize: 13, marginBottom: 20, border: "1px solid", padding: 0, borderCollapse: "collapse" }}>
                  <tr >
                    <td style={{ verticalAlign: "top", border: "1px solid", padding: 5}}>Review Date</td>
                    <td style={{ verticalAlign: "top", border: "1px solid", padding: 5}}>Ongoing</td>
                  </tr>
                  <tr >
                    <td style={{ verticalAlign: "top", border: "1px solid", padding: 5}}>Services to be provided</td>
                    <td style={{ verticalAlign: "top", border: "1px solid", padding: 5}}>Introduction of commercial and consumer credit applications</td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", border: "1px solid", padding: 5}}>Financeable Facilities to be provided</td>
                    <td style={{ verticalAlign: "top", border: "1px solid", padding: 5}}>Access to Financeable staff and systems as determined by management</td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", border: "1px solid", padding: 5}}>Remuneration Arrangements</td>
                    <td style={{ verticalAlign: "top", border: "1px solid", padding: 5}}>
                      <ul style={{ marginLeft: 0, paddingLeft: 18 }}>
                        <li>Commission will be determined on a contract by contract basis.</li>
                        <li>Commission will be payable based on the Financeable referral commission plan (50% of the up front commission).</li>
                      </ul>

                    </td>
                  </tr>
                  <tr>
                  <td style={{ verticalAlign: "top", border: "1px solid", padding: 5 }}>Clawback Arrangement</td>
                  <td style={{ verticalAlign: "top", border: "1px solid", padding: 5 }}>
                      <ul style={{ marginLeft: 0, paddingLeft: 18 }}>
                        <li>Where repossession of the vehicle finance contract occurs in the first 12 months of the contract term, 100% of commission and origination fees will be clawed back.</li>
                        <li>Where a loan terminates within the first 6 months of the contract term, 100% of commission and origination fees will be clawed back.</li>
                      </ul>
                    </td>
                  </tr>
                </table>

                <Typography style={{ marginBottom: "10px", fontSize: 13 }}>
                  We will require a tax invoice for commission upon settlement of each finance contract. Commission is payable monthly in arrears.
                </Typography>

              </AccordionDetails>
            </Accordion>


            {/* <Grid container item xs={12} justifyContent="center" >
              <Grid item sm={12}>
                <Typography style={{ marginBottom: "30px", fontSize: "18px", fontWeight: 700 }}>
                  I confirm that I am authorised to provide the personal details presented and
                  I consent to my information being checked with the document issuer or
                  official record holder for the purpose of confirming my identity.
                </Typography>
              </Grid>
            </Grid> */}
            <Grid container item xs={12} justifyContent="center">
              <Grid item sm={12}>

                <Box style={{
                  margin: "0 0 20px 0",
                  dotSize: 1,
                  // padding: "0 15px",
                  // width: "500px",
                  // borderBottom: "2px solid #333333",
                  zIndex: 10,
                  position: "relative",

                  // fontWeight: 700
                }}>Signature:</Box>

                <ReactSignatureCanvas
                  ref={ref => sigCanvas = ref}
                  penColor='#2946bb'
                  onEnd={() => trimSignature()}
                  canvasProps={{
                    className: 'sigCanvas',
                    style: {
                      width: "100%",
                      // height: 180,
                      aspectRatio: "2/1",
                      position: "relative",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      borderRadius: "8px",
                      boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`
                    }
                  }} />
                <Box style={{
                  margin: "-50px auto 50px",
                  padding: "0",
                  width: "95%",
                  borderBottom: "2px solid #333333",
                  zIndex: 10,
                  position: "relative",

                }}>
                  {/* <Typography style={{ height: "30px" }}>Signature:</Typography> */}
                  {/* <Typography style={{ fontSize: "32px", userSelect: "none" }}>x</Typography> */}
                </Box>
                <Button variant="link" size="small" onClick={() => {
                  sigCanvas.clear()
                  setSignature(null)
                }} style={{ float: "right" }}>Clear signature</Button>
                <Grid item sm={12}>
                  <LoadingButton
                    fullWidth
                    disabled={!signature}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSubmitReferralAgreement()}
                    // onClick={() => createPrivacy({ customerId, applicationId, signature: sigCanvas.toDataUrl() })}
                    style={{ margin: "30px 0 50px" }}
                  >
                    Accept Agreement
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        }

        {submitted &&
          <Grid container item direction="column" xs={12}>
            <Typography
              variant="h1"
              style={{
                fontSize: "24px",
                marginBottom: "30px"
              }}>
              Agreement Signed Successfully
            </Typography>
            <Typography
              variant="h2"
              style={{
                fontSize: "18px",
                marginBottom: "10px"
              }}
            >
              Too easy! You're one step closer and not far to go now.

            </Typography>
          </Grid>
        }
      </Grid>
    </Grid>
  </ Grid>
}