import { typeOfSaleList, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";
import midPrime from "./midPrime";
import flexPrime from "./flexPrime";
import specialist from "./specialist";
import miniMoto from "./miniMoto";

const { SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL, BENEFITS } = employmentTypes

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  compound
} = criteria

export default {
  date: "31 Aug 2023",
  lender: "Gedda Money",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos/gedda.png",
  fees: [
    {
      name: "Establishment fee",
      value: 725,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        assetValueBetween(8000, 14999.99),
        employmentType([FULL_TIME, PART_TIME, CASUAL, CONTRACT])
      ],
    },
    {
      name: "Establishment fee",
      value: 1495,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        assetValueBetween(15000, 50000),
        employmentType([FULL_TIME, PART_TIME, CASUAL, CONTRACT])
      ],
    },
    {
      name: "Establishment fee",
      value: 695,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        loanAmountBetween(2000, 8000),
        employmentType([BENEFITS])
      ],
    },
    {
      name: "Origination fee",
      value: 550,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        loanAmountBetween(2000, 8000),
        employmentType([BENEFITS])
      ],
    },
    {
      name: "Establishment fee",
      value: 995,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        assetValueBetween(8000, 14999.99),
        employmentType([BENEFITS])
      ],
    },
    {
      name: "Origination fee",
      value: 770,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        loanAmountBetween(8001, 12000),
        employmentType([BENEFITS])
      ],
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        assetValueBetween(8000, 14999.99)
      ],
    },
    {
      name: "Origination fee",
      value: 1210,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        assetValueBetween(15000, 50000)
      ],
    },
    {
      name: "Monthly account fee",
      value: 15.00,
      capitalised: false,
      frequency: "monthly",
    },
    // {
    //   name: "Risk fee",
    //   value: 2,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreGreaterThan(600)],
    // },
    // {
    //   name: "Risk fee",
    //   value: 3,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreBetween(550, 600)],
    // },
    // {
    //   name: "Risk fee",
    //   value: 4,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreBetween(500, 550)],
    // },
    // {
    //   name: "Risk fee",
    //   value: 4.5,
    //   calc: "percent",
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [creditScoreBetween(100, 499)],
    // },
  ],
  brokerage: [],
  loading: [
    {
      name: "Age of asset at start 36-95 mths",
      value: 3,
      valueType: "percentage",
      criteria: [ageOfAssetBetween(36, 95)],
    },
    {
      name: "Age of asset at start >95 mths",
      value: 3,
      valueType: "percentage",
      criteria: [ageOfAssetGreaterThan(95)],
    },
  ],
  info: [
    `Accepted: Paid financial defaults considered any product other than Mid prime`,
    `Knockouts`,
`Failure to demonstrate debt servicing`,
`More than 3 current payday loans (BNPL/PAYDAY)`,
`Dishonours on financial commitments`,
`Temporary or Bridging VISAS (Other VISAS accepted case-by-case).•Account clearing (cash withdrawals) without acceptable explanation`
],
  productTiers: [
    ...midPrime,
    ...flexPrime,
    ...specialist,
    ...miniMoto
  ],
};