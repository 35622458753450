import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import _ from "lodash";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

// import {
//   saveValue,
//   userDetailsSelector,
// } from "../store/slices/applicationFormSlice";

export default function Main(props) {
  return (
    <>
      <Container maxWidth="lg" 
      // style={{ padding: "0 50px" }}
      >
        {/* <Grid container style={{ height: "100%" }}> */}
        <Grid item xs={12}>
          {/* <Application {...props} /> */}
          <Outlet />
        </Grid>
        {/* </Grid> */}
      </Container>
    </>
  );
}
