import React from "react";
import Grid from "@mui/material/Grid";
import PreAuthTopMenu from "../layouts/PreAuthTopMenu";
import PreAuthTopMenuLogoOnly from "../layouts/PreAuthTopMenuLogoOnly";
import { Container } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

export default function Main(props) {
  const { pathname } = useLocation();

  console.log(pathname);

  return (
    <>

      {pathname !== "/register" && pathname !== "/privacy" && <PreAuthTopMenu />}
      {pathname === "/privacy" && <PreAuthTopMenuLogoOnly />}
      <Container style={{ maxWidth: "100%", padding: 0 }}>
        <Outlet />
      </Container>
    </>
  );
}
