import { livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";
import b from "./b"

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes
const { FULL_TIME, PART_TIME, CASUAL, CONTRACT, SELF_EMPLOYED } = employmentTypes


const {
  termIsGreaterThan,
  typeOfSale,
  assetValueBetween,
  termIsBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  loanAmountBetween,
  creditScoreGreaterThan,
  livingArrangements,
  employmentType,
  compound,
  timeOfEmploymentGreaterThan,
  ageOfAssetBetween,
} = criteria;

function bTierSelfEmployed(b) {
  return b.map(product => {

    return {
      ...product,
      criteria: [
        ...product.criteria,
        employmentType([SELF_EMPLOYED]),
        timeOfEmploymentGreaterThan(24),
      ]
    }
  })
}

function bTierOtherEmploymentTypes(b) {
  return b.map(product => {

    return {
      ...product,
      criteria: [
        ...product.criteria,
        employmentType([FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
        timeOfEmploymentGreaterThan(12),
      ]
    }
  })
}

export default {
  date: "23 Aug 2023",
  lender: "Pepper",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FPepper_Money.gif",
  fees: [
    {
      name: "Establishment fee",
      value: 474,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Authorised seller")],
    },
    {
      name: "Private sale fee",
      value: 600,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")]
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
    },
    {
      name: "Monthly Account fee",
      value: 8.90,
      capitalised: false,
      frequency: "monthly",
    },
  ],
  brokerage: [
  ],
  loading: [
    {
      name: "Term >60-84 months",
      value: 0.5,
      valueType: "percentage",
      criteria: [termIsBetween(61, 85)],
    }
  ],
  info: [
    "Current bankrupts are not accepted. Ex-bankrupts must be discharged more than 2 years to be considered.",
    "C Tier customers are not eligible for spousal self-declaration.",
    "Loan Terms minimum 12 months",
    "Maximum term with balloon 60 months",
    "Maximum term (without balloon) 84 months."
  ],
  productTiers: [
    ...bTierOtherEmploymentTypes(b),
    ...bTierSelfEmployed(b),
    {
      name: "A Tier - Motor Vehicle New/Demo",
      rate: 9.79,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 150000),
        ageOfAssetLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Motor Vehicle", "Electric Motor Vehicle"
        ]),
        creditScoreGreaterThan(750)
      ],
    },
    {
      name: "A Tier - Motor Vehicle 12-71 months old",
      rate: 11.09,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 150000),
        ageOfAssetBetween(12, 71),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Motor Vehicle", "Electric Motor Vehicle"
        ]),
        creditScoreGreaterThan(750)
      ],
    },
    {
      name: "A Tier - Motor Vehicle 71-119 months old",
      rate: 13.09,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 150000),
        ageOfAssetBetween(71, 119),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Motor Vehicle", "Electric Motor Vehicle"
        ]),
        creditScoreGreaterThan(750)
      ],
    },
    {
      name: "A Tier - Motor Vehicle >119 months old",
      rate: 14.59,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 150000),
        ageOfAssetGreaterThan(119),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Motor Vehicle", "Electric Motor Vehicle"
        ]),
        creditScoreGreaterThan(750)
      ],
    },



    {
      name: "C Tier - Motor Vehicle New/Demo",
      rate: 15.09,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        ageOfAssetLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
        timeOfEmploymentGreaterThan(12),
        assetType([
          "Motor Vehicle", "Electric Motor Vehicle"
        ]),
        creditScoreGreaterThan(500)
      ],
    },
    {
      name: "C Tier - Motor Vehicle 12-71 months old",
      rate: 16.09,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        ageOfAssetBetween(12, 71),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
        timeOfEmploymentGreaterThan(12),
        assetType([
          "Motor Vehicle", "Electric Motor Vehicle"
        ]),
        creditScoreGreaterThan(500)
      ],
    },
    {
      name: "C Tier - Motor Vehicle 71-119 months old",
      rate: 16.59,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        ageOfAssetBetween(71, 119),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
        timeOfEmploymentGreaterThan(12),
        assetType([
          "Motor Vehicle", "Electric Motor Vehicle"
        ]),
        creditScoreGreaterThan(500)
      ],
    },
    {
      name: "C Tier - Motor Vehicle >119 months old",
      rate: 16.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        ageOfAssetGreaterThan(119),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
        timeOfEmploymentGreaterThan(12),
        assetType([
          "Motor Vehicle", "Electric Motor Vehicle"
        ]),
        creditScoreGreaterThan(500)
      ],
    },







    {
      name: "A Tier - Caravan New/Demo",
      rate: 9.79,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 110000),
        ageOfAssetLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Caravan"
        ]),
        creditScoreGreaterThan(750)
      ],
    },
    {
      name: "A Tier - Caravan 12-71 months old",
      rate: 11.34,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 110000),
        ageOfAssetBetween(12, 71),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Caravan"
        ]),
        creditScoreGreaterThan(750)
      ],
    },
    {
      name: "A Tier - Caravan 71-119 months old",
      rate: 13.84,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 110000),
        ageOfAssetBetween(71, 119),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Caravan"
        ]),
        creditScoreGreaterThan(750)
      ],
    },
    {
      name: "A Tier - Caravan >119 months old",
      rate: 15.34,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 110000),
        ageOfAssetGreaterThan(119),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Caravan"
        ]),
        creditScoreGreaterThan(750)
      ],
    },













    {
      name: "A Tier - Recreational/Marine/Misc. New/Demo",
      rate: 11.79,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        ageOfAssetLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Motorbike",
          "Trailered boat",
          "Jetski/PWC",
          "ATV/Off-Road Bike",
          "Tractor",
          "Horse float",
          "Ride on mower"
        ]),
        creditScoreGreaterThan(750)
      ],
    },
    {
      name: "A Tier - Recreational/Marine/Misc. 12-71 months old",
      rate: 12.09,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        ageOfAssetBetween(12, 71),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Motorbike",
          "Trailered boat",
          "Jetski/PWC",
          "ATV/Off-Road Bike",
          "Tractor",
          "Horse float",
          "Ride on mower"
        ]),
        creditScoreGreaterThan(750)
      ],
    },
    {
      name: "A Tier - Recreational/Marine/Misc. 71-119 months old",
      rate: 14.34,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        ageOfAssetBetween(71, 119),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Motorbike",
          "Trailered boat",
          "Jetski/PWC",
          "ATV/Off-Road Bike",
          "Tractor",
          "Horse float",
          "Ride on mower"
        ]),
        creditScoreGreaterThan(750)
      ],
    },
    {
      name: "A Tier - Recreational/Marine/Misc. >119 months old",
      rate: 15.84,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        ageOfAssetGreaterThan(119),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT]),
        timeOfEmploymentGreaterThan(24),
        assetType([
          "Motorbike",
          "Trailered boat",
          "Jetski/PWC",
          "ATV/Off-Road Bike",
          "Tractor",
          "Horse float",
          "Ride on mower"
        ]),
        creditScoreGreaterThan(750)
      ],
    },







    {
      name: "C Tier - Motorbike New/Demo",
      rate: 17.09,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        ageOfAssetLessThan(12),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
        timeOfEmploymentGreaterThan(12),
        assetType([
          "Motorbike"
        ]),
        creditScoreGreaterThan(500)
      ],
    },
    {
      name: "C Tier - Motorbike 12-71 months old",
      rate: 18.94,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        ageOfAssetBetween(12, 71),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
        timeOfEmploymentGreaterThan(12),
        assetType([
          "Motorbike"
        ]),
        creditScoreGreaterThan(500)
      ],
    },
    {
      name: "C Tier - Motorbike 71-119 months old",
      rate: 15.59,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        ageOfAssetBetween(71, 119),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
        timeOfEmploymentGreaterThan(12),
        assetType([
          "Motorbike"
        ]),
        creditScoreGreaterThan(500)
      ],
    },
    {
      name: "C Tier - Motorbike >119 months old",
      rate: 20.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 50000),
        ageOfAssetGreaterThan(119),
        livingArrangements([PROPERTY_OWNER]),
        employmentType([SELF_EMPLOYED, FULL_TIME, PART_TIME, CONTRACT, CASUAL]),
        timeOfEmploymentGreaterThan(12),
        assetType([
          "Motorbike"
        ]),
        creditScoreGreaterThan(500)
      ],
    },
  ],
};