import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";
import TopMenu from "../layouts/TopMenu";
import { Box } from "@mui/material";

const pathRegex = new RegExp("dashboard/application/");

export default function Main(props) {

  return (
    <>
      <TopMenu />
      {/* <Box sx={{ display: "flex", margin: "auto" }}> */}

      <Container
        maxWidth="lg"
        style={{
          // border: "1px solid #d5d5d5",
          borderRadius: "4px",
          margin: "64px auto 0",
          // background: "#ffffff",
          padding: 0,
        }}
      >
        {/* <Grid container style={{ height: "100%" }}> */}
        {/* <Grid item xs={12}> */}
        {/* <Application {...props} /> */}
        <Outlet />
        {/* </Grid> */}
        {/* </Grid> */}
      </Container>
      {/* </Box> */}
    </>
  );
}
