import { typeOfSaleList, livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

const {
  PROPERTY_OWNER,
  RENTING,
  BOARDING,
  LIVING_WITH_PARENTS
} = livingArrangementsTypes

const {
  SELF_EMPLOYED,
  FULL_TIME,
  PART_TIME,
  CONTRACT,
  CASUAL,
  BENEFITS
} = employmentTypes

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  termIsLessThanOrEqualTo,
  compound
} = criteria

export default [
  {
    name: "Economy",
    rate: 24.00,
    productType: "Adverse",
    info: [
      "100% income from government benefits",
      "Adverse more than 6 months",
      "Ex-bankkrupt more than 1 day",
    ],
    criteria: [
      loanAmountBetween(5000, 10000),
      termIsLessThanOrEqualTo(36),
      employmentType([BENEFITS]),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Caravan",
        "Trailered boat",
        "Motorbike",
      ]),
    ],
  },
]
