import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";
import mixpanel from "src/services/mixpanel";

import Entity from "../components/Entity";
import AssetValue from "src/components/AssetValue";
import AssetType from "src/components/AssetType";
import TypeOfSale from "src/components/TypeOfSale";
import AgeOfAsset from "src/components/AgeOfAsset";
import Term from "src/components/Term";
import Balloon from "src/components/Balloon";
import CreditScore from "src/components/CreditScore";
import EmploymentType from "src/components/EmploymentType";
import LengthOfEmployment from "src/components/LengthOfEmployment";
import DateOfBirth from "src/components/DateOfBirth";
import WelcomeModal from "src/components/WelcomeModal";

import {
  saveValue,
  getApplication,
  applicationSelector,
  userDetailsSelector,
  clearState,
} from "../store/slices/applicationFormSlice";

import generateArrayOfYears from "src/utils/generateArrayOfYears";
import {
  Alert,

  Button,

} from "@mui/material";

import ProductSelector from "../components/ProductSelector";

import { ArrowDropDownCircleRounded, ArrowDropDownOutlined, AttachMoney, Percent } from "@mui/icons-material";
import RadioButtonGroup from "src/components/RadioButtonGroup";
import Desposit from "src/components/Deposit";
import { resetState, saveValue as saveUserValue } from "src/store/slices/userSlice";
import Refer from "src/components/Refer";
import PageLoader from "src/components/PageLoader";
import LivingArrangements from "src/components/LivingArrangements";
import Walkthrough from "src/components/Walkthrough";

const formFieldsStyle = {
  margin: "0 0 14px",
}

export default function Application() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disclaimer, setDisclaimer] = useState(localStorage.disclaimer)
  const { applicationId } = useParams();

  const { uploadInvoiceLoading, gotASICData, gettingQuote } =
    useSelector(userDetailsSelector);
  const application = useSelector(applicationSelector);

  async function handleApplicationLoad() {
    dispatch(saveValue({ gettingQuote: true }))
    // console.log("applicationId", applicationId);
    // console.log("application", application);
    // if (!application._id) {
    // if (!applicationId) {
    // return await dispatch(startApplication()).unwrap();
    // }
    // console.log("applicationId", applicationId);
    if (applicationId) await dispatch(getApplication(applicationId)).unwrap();
    // updateQuote();
  }



  useEffect(() => {
    mixpanel.track_pageview({
      "page": "Quote",
      applicationId: applicationId,
      applicationType: application.applicationType
    });

    handleApplicationLoad();

    return () => {
      dispatch(clearState());
    };
  }, [applicationId]);

  const calculatorRef = useRef(null);

  useEffect(() => {
    const inactivityTimer = setTimeout(() => {
      localStorage.clear();
      navigate("/login");
      dispatch(resetState());
    }, 3600000)
    return () => {
      clearTimeout(inactivityTimer);
    }
  }, [application])

  const isCommercial = application.applicationType === "commercial"
  const isConsumer = application.applicationType === "consumer"

  const quoteTitle = `${application?.applicationType?.charAt(0).toUpperCase() + application?.applicationType?.slice(1)} quote`

  useEffect(() => {
    localStorage.setItem("disclaimer", true)
  }, [disclaimer])

  return (
    <>
      <Walkthrough />
      {/* {!disclaimer &&
        <Alert
          severity="warning"
          variant="outlined"
          action={
            <Button color="inherit" size="small" onClick={() => setDisclaimer(true)}>
              don't show again
            </Button>
          }
        >
          Quotes are still subject to lender approval and discretion.
        </Alert>
      } */}
      <Grid
        container
        item
        style={{
          // marginTop: "64px",
          // padding: "30px 0px 100px"
        }}
      >

        <Grid container item sm={4} md={3} lg={3}
          ref={calculatorRef}
          style={{
            padding: "6px 0",
          }} >
          <Box

            style={{
              overflow: "hidden auto",
              height: "calc(100vh - 105px)",
              position: "fixed",
              width: `${calculatorRef?.current?.offsetWidth}px`,
              paddingBottom: "50px"
            }}>
            <Grid style={{ maxWidth: `${calculatorRef?.current?.offsetWidth}px`, padding: "0 20px", }}>
              <Typography variant="h6" style={{ marginBottom: "15px" }}>{quoteTitle}</Typography>

              <Grid container item direction="row" spacing={1}>
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 14px" }}>
                  <AssetValue />
                </Grid>
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 14px" }}>
                  <Desposit />
                </Grid>
              </Grid>

              <Grid item md={12} sm={12} style={formFieldsStyle}>
                <AssetType applicationType={application.applicationType} />
              </Grid>
              <Grid item md={12} sm={12} style={formFieldsStyle}>
                <TypeOfSale />
              </Grid>

              <Grid item md={12} sm={12} style={formFieldsStyle}>
                <AgeOfAsset />
              </Grid>

              {/* <Grid container spacing={1} style={{ background: "white", padding: "15px" }}> */}
              <Grid container item direction="row" spacing={3}>
                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 10px 0" }}>
                  <Term />
                </Grid>

                <Grid item md={6} sm={12} xs={12} style={{ margin: "0 0 10px 0" }}>
                  <Balloon />
                </Grid>
              </Grid>

              {isCommercial &&
                <Grid container item direction="row" spacing={1} >
                  <Grid item md={8} sm={12} xs={12} style={formFieldsStyle}>
                    <LivingArrangements />
                  </Grid>
                  <Grid item md={4} sm={12} xs={12} style={formFieldsStyle}>
                    <CreditScore />
                  </Grid>
                </Grid>
              }

              {!isCommercial && <><Grid container item direction="row" spacing={1} >
                <Grid item md={12} sm={12} xs={12} style={formFieldsStyle}>
                  <LivingArrangements />
                </Grid>


              </Grid>

                <Grid container item direction="row" spacing={1} >
                  <Grid item md={8} sm={12} xs={12} style={formFieldsStyle}>
                    <DateOfBirth />
                  </Grid>
                  <Grid item md={4} sm={12} xs={12} style={formFieldsStyle}>
                    <CreditScore />
                  </Grid>
                </Grid>
              </>
              }

              {isConsumer && <Grid sm={12} item style={formFieldsStyle}>
                <EmploymentType />
              </Grid>
              }

              {isConsumer && <Grid sm={12} item style={{ margin: "0 0 10px 0" }}>
                <LengthOfEmployment />
              </Grid>
              }

              {isCommercial && <Grid sm={12} item style={formFieldsStyle}>
                {/* <Typography variant="subtitle2">Commercial Entity</Typography> */}
                <Entity application={application} />
              </Grid>
              }
              {/* <Grid sm={12} item style={{ margin: "30px 0 0 0" }}>
                {application.status === "quote" && <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    dispatch(saveUserValue({ openReferModal: true }))
                    mixpanel.track_pageview({
                      "page": "Refer customer",
                    });
                  }}
                >
                  Refer to financeable
                </Button>}
              </Grid> */}
            </Grid>
          </Box>
        </Grid>

        {
          gettingQuote ? <Grid
            container
            item
            style={{
              marginTop: "64px",
              // padding: "30px 0px 100px",
              // height: "calc(100vh - 100px)",
              justifyContent: "center",
              // alignItems: "center"
            }}
          >
            <PageLoader text="Quote loading..." />
          </Grid> :
            <Grid conatiner item sm={8} md={9} lg={9} style={{
              padding: 0,
              // background: "#f5f5f5"
            }}>
              <Box
                style={{
                  // background: color.lightPink
                  padding: "10px 20px",
                  // width: "calc(25vw - 60px)",
                  position: "relative",
                  // overflowY: "auto",
                  // overflowX: "hidden",
                  // height: "calc(100vh - 105px)",
                  scrollbarColor: "white rebeccapurple",
                  scrollbarWidth: "thin",
                  // marginTop: "-55px",
                }}
              >
                <ProductSelector color="secondary" />
              </Box>
            </Grid>
        }
      </Grid >
      <WelcomeModal />
    </>
  );
}
