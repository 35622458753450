import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isNumber } from "lodash";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  createEntity,
  entitySelector,
  updateEntity,
  saveValue,
  userDetailsSelector,
} from "../store/slices/applicationFormSlice";

import { getBusinessByABN, getBusinessByName } from "../services/abr";
import readableABNString from "../utils/readableABNString";
import readableACNString from "../utils/readableACNString";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Skeleton,
  Stack,
} from "@mui/material";
import { isDigitsOnly } from "src/utils/isDigitsOnly";
import { LoadingButton } from "@mui/lab";

export default function Entity({ handleAddBusinessDetails, application }) {
  const dispatch = useDispatch();

  const { gettingASICData, gettingEntityDetails } = useSelector(userDetailsSelector);

  // const entity = useSelector(entitySelector);
  // const { turnover, industryType } = useSelector(entitySelector);

  // console.log(entity);

  // const { turnover, industryType } = entity;

  const [businessSearchValue, setBusinessSearchValue] = useState("");
  const [businessSearchInputText, setBusinessSearchInputText] = useState("");
  const [newBusinessValue, setNewBusinessValue] = useState("");
  const [turnoverValue, setTurnoverValue] = useState({});
  const [industryTypeValue, setIndustryTypeValue] = useState({});
  const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);
  const [ABREntityDetails, setABREntityDetails] = useState({});

  // const parseTimeInBusiness = moment(entity?.timeInBusiness, "DD-MM-YYYY");

  const handleEntity = (update) => {
    // console.log("update phoneNumber");
    dispatch(updateEntity(update));
  };

  const debounced = useMemo(
    () => debounce(async (inputValue) => {
      // console.log("inputValue", inputValue);
      if (inputValue) {
        console.log(inputValue?.replace(/\s/g, '') * 1)
        if (isNumber(inputValue?.replace(/\s/g, '') * 1)) {

          const businessByABN = await getBusinessByABN(inputValue.replace(/\s/g, '') * 1);
          console.log({ businessByABN })
          setLoadingBusinessSearch(false);
          if (businessByABN.Abn.length > 1) {
            return setEntityOptions([businessByABN]);
          }
        }

        setLoadingBusinessSearch(true);
        const businesses = await getBusinessByName(inputValue);
        console.log(businesses)
        if (businesses?.Names?.length > 0) {
          setLoadingBusinessSearch(false);
          return setEntityOptions(
            businesses.Names.map((i) => {
              // return { label: `${i.Name} ${i.Abn}`, Abn: i.Abn, value: i };
              return i;
            })
          );
        }



        console.log(entityOptions)


        setEntityOptions([
          {
            Name: "No results found",
          },
        ]);
      }
    }, 1000), []);

  const handleEntityDetails = async (details) => {
    // dispatch(saveValue({ gettingEntityDetails: true }));
    console.log("details", details);
    const abrData = await getBusinessByABN(details.Abn);
    // console.log("ABREntityDetails", abrData);
    setABREntityDetails(abrData);
    handleAddBusinessDetails(abrData)
    // dispatch(saveValue({ gettingEntityDetails: false }));
  };

  useEffect(() => {
    debounced(businessSearchInputText);

    // return () => debounced.cancel();
  }, [businessSearchValue, businessSearchInputText]);

  // useEffect(() => {
  //   if (!gettingEntityDetails) {
  //     setBusinessSearchInputText("");
  //     setBusinessSearchValue("");
  //   }
  // }, [gettingEntityDetails]);

  return (
    <>
      <Autocomplete
        autoComplete
        autoSelect={true}
        name="Business Search"
        // {...props}
        value="test"
        // inputValue={businessSearchValue}
        noOptionsText="Type to start search"
        loadingText="Searching..."
        defaultValue=""
        // clearOnBlur={true}
        blurOnSelect={true}
        onChange={(event, newValue, reason) => {
          // console.log(newValue, entity?.entityName, reason);
          if (reason === "select-option") {

            // if (newValue) {
            setNewBusinessValue(newValue);
            setBusinessSearchValue(
              newValue?.Name || newValue?.entityName
            );

            return;
            // }

            // setBusinessSearchValue("");
            // setBusinessSearchInputText("");
          }



          if (reason === "blur") {
            // setBusinessSearchInputText("");
            handleEntityDetails(newValue);
          }
          console.log({ businessSearchValue })
        }}
        filterOptions={(x) => x}
        onInputChange={(event, newInputValue) => {
          // console.log({
          //   newInputValue: Number(newInputValue * 1) ? newInputValue * 1 : newInputValue,
          //   businessSearchInputText,
          //   // newInputValue,
          // });
          const value = Number(newInputValue * 1) ? newInputValue * 1 : newInputValue
          setBusinessSearchInputText(value);
          if (newInputValue === "") setEntityOptions([]);
        }}
        getOptionLabel={(option) => {
          if (option.EntityName) {
            return option.EntityName
              ? `${option.EntityName} ${readableABNString(option.Abn)}`
              : "";
          }

          return option.Name
            ? `${option.Name} ${readableABNString(option.Abn)}`
            : "";
        }}
        // inputValue={businessSearchInputText}
        options={entityOptions}
        loading={loadingBusinessSearch}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            type="text"
            
            // label="Asynchronous"
            label="Search your business by name or ABN"
            placeholder="Start typing business name or ABN"
            style={{ background: "#ffffff" }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingBusinessSearch ? <CircularProgress /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}
