export default [
    {
        "label": "Motor Vehicle",
        "icon": "DirectionsCar",
        "category": 1,
        "value": ""
    },
    {
        "label": "Electric Motor Vehicle",
        "icon": "DirectionsCar",
        "category": 1,
        "value": ""
    },
    {
        "label": "Luxury Vehicle",
        "icon": "DirectionsCar",
        "category": 1,
        "value": ""
    },
    {
        "label": "Motorbike",
        "icon": "Fastfood",
        "category": 3,
        "value": ""
    },
    {
        "label": "ATV/Off-Road Bike",
        "icon": "Fastfood",
        "category": 3,
        "value": ""
    },
    {
        "label": "Tractor",
        "icon": "Fastfood",
        "category": 3,
        "value": ""
    },
    {
        "label": "Ride on mower",
        "icon": "Fastfood",
        "category": 3,
        "value": ""
    },
    {
        "label": "Horse float",
        "icon": "Build",
        "category": 2,
        "value": ""
    },
    {
        "label": "Trailer",
        "icon": "Build",
        "category": 2,
        "value": ""
    },
    {
        "label": "Caravan",
        "icon": "Build",
        "category": 2,
        "value": ""
    },
    {
        "label": "Motor home",
        "icon": "Build",
        "category": 2,
        "value": ""
    },
    {
        "label": "Trailered boat",
        "icon": "Build",
        "category": 2,
        "value": ""
    },
    {
        "label": "Jetski/PWC",
        "icon": "Build",
        "category": 2,
        "value": ""
    },
]
