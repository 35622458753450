import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";

console.log(process.env.REACT_APP_API_URL);

function headers(token) {
  if (token) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

export const createOrganisation = createAsyncThunk(
  "organisation/create",
  async (data, { rejectWithValue }) => {
    const token = localStorage.token;
    console.log("create org data", data);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/organisation/`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("organisation res", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateOrganisation = createAsyncThunk(
  "organisation/create",
  async (data, { rejectWithValue }) => {
    const token = localStorage.token;
    console.log("update org data", data);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/organisation/`,
        method: "PUT",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("organisation res", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getOrganisation = createAsyncThunk(
  "organisation/get",
  async (abn, { rejectWithValue }) => {
    const token = localStorage.token;
    console.log("get org", abn);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/organisation/${abn}`,
        method: "GET",
        headers: headers(token),
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("organisation res", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateAddress = createAsyncThunk(
  "address/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.token;
    console.log("get org", data);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/organisation/address`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("organisation res", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateEmail = createAsyncThunk(
  "address/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.token;
    console.log("get org", data);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/organisation/email`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("organisation res", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updatePhoneNumber = createAsyncThunk(
  "address/update",
  async (data, { rejectWithValue }) => {
    const token = localStorage.token;
    console.log("get org", data);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/organisation/phone-number`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("organisation res", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const requestToJoin = createAsyncThunk(
  "organisation/request",
  async (data, { rejectWithValue }) => {
    const token = localStorage.token;
    console.log("request org", data);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/organisation/request`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("organisation res", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const inviteUsers = createAsyncThunk(
  "organisation/invite",
  async (data, { rejectWithValue }) => {
    const token = localStorage.token;
    console.log("invite org", data);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/organisation/invite`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("organisation res", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  isCreating: false,
  isNew: null,
  requestSuccess: false,
}

export const organisationSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {
    saveValue: (state, action) => {
      console.log(action.payload);
      return _.merge(state, action.payload);
    },
    resetState: () => initialState,
  },
  extraReducers: {
    [createOrganisation.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // const { user, token } = payload.data;
      // state.token = token;
      // state.user = user;
      console.log(JSON.stringify(state, null, 4));
      state = _.merge(state, payload.data);
      state.isCreating = false;
      state.isNew = true;
      console.log(JSON.stringify(state, null, 4));
    },
    [createOrganisation.pending]: (state) => {
      state.isFetching = true;
      state.isCreating = true;
    },
    [createOrganisation.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [getOrganisation.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // const { user, token } = payload.data;
      // state.token = token;
      // state.user = user;
      state = _.merge(state, payload.data);
    },
    [getOrganisation.pending]: (state) => {
      state.isFetching = true;
      // state.isCreating = true;
    },
    [getOrganisation.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [updateAddress.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // const { user, token } = payload.data;
      // state.token = token;
      // state.user = user;
      state = _.merge(state, payload.data);
    },
    [updateAddress.pending]: (state) => {
      state.isFetching = true;
      // state.isCreating = true;
    },
    [updateAddress.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [updateEmail.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // const { user, token } = payload.data;
      // state.token = token;
      // state.user = user;
      state = _.merge(state, payload.data);
    },
    [updateEmail.pending]: (state) => {
      state.isFetching = true;
      // state.isCreating = true;
    },
    [updateEmail.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [updatePhoneNumber.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // const { user, token } = payload.data;
      // state.token = token;
      // state.user = user;
      state = _.merge(state, payload.data);
    },
    [updatePhoneNumber.pending]: (state) => {
      state.isFetching = true;
      // state.isCreating = true;
    },
    [updatePhoneNumber.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [requestToJoin.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // const { user, token } = payload.data;
      // state.token = token;
      // state.user = user;
      state = _.merge(state, payload.data);
      state.requestSuccess = true;
    },
    [requestToJoin.pending]: (state) => {
      state.isFetching = true;
      state.isCreating = true;
    },
    [requestToJoin.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [inviteUsers.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      // const { user, token } = payload.data;
      // state.token = token;
      // state.user = user;
      state = _.merge(state, payload.data);
      state.inviteUsersSuccess = true;
    },
    [inviteUsers.pending]: (state) => {
      state.isFetching = true;
      // state.isCreating = true;
    },
    [inviteUsers.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },
  },
});

export const { saveValue, resetState } = organisationSlice.actions;

// export const store = configureStore({
//   reducer: {
//     applications: applicationsSlice.reducer,
//   },
// });

export const organisationSelector = (state) => state.organisation;
