import criteria from "src/utils/criteria";
import { livingArrangementsTypes, typeOfSaleList } from "src/constants";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } = livingArrangementsTypes

const {
  nonGST,
  typeOfSale,
  hasDeposit,
  assetValueBetween,
  assetType,
  ageOfAssetAtEndGreaterThan,
  ageOfAssetAtEndLessThan,
  ageOfAssetAtEndBetween,
  ageOfAssetBetween,
  ageOfAssetGreaterThan,
  loanAmountLessThan,
  loanAmountBetween,
  timeInBusinessBetween,
  timeInBusinessLessThan,
  timeInBusinessGreaterThan,
  gstRegistrationLessThan,
  gstRegistrationGreaterThan,
  gstRegistrationBetween,
  creditScoreGreaterThan,
  livingArrangements,
  termIsLessThan,
  creditScoreLessThan
} = criteria;

const auto = ["Motor Vehicle (<4.5 tonnes)", "Electric Motor Vehicle (<4.5 tonnes)"]
const primary = [
  "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
  "Commercial (Heavy) Vehicles (>12 Tonnes)",
  "Earth Moving & Construction (wheeled or tracked)",
  "Agricultural & Landscaping Equipment (wheeled or tracked)",
  "Trailers and Caravans",
  "Warehouse Equipment (wheeled or tracked)",
  "Medical Equipment",
  "Mini Buses & Route Buses (<25 seats)"
]

const secondary = [
  "Industrial Plant & Printing",
  "Manufacturing Equipment",
]

const tertiary = [
  "IT, Computer & Audio Equipment",
  "Office Equipment",
  "Office Furniture",
  "Restaurant & Catering Equipment",
  "Health & Beauty Equipment",
  "Solar",
]

function base(l) {
  return l.map(product => {

    return {
      ...product,
      productType: "Low doc",
      criteria: [
        ...product.criteria,
        timeInBusinessGreaterThan(23),
        gstRegistrationGreaterThan(0),
        livingArrangements([PROPERTY_OWNER]),
        termIsLessThan(61)
      ]
    }
  })
}

export default {
  date: "11 Jul 2023",
  lender: "Captial Finance",
  productName: "STREAMLINE",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos/capital-finance.svg",
  fees: [
    {
      name: "Document fee - Private sale",
      value: 695,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Document fee",
      value: 495,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Authorised seller")],
    },
  ],
  brokerage: [
    {
      value: 4,
      criteria: [assetValueBetween(5000, 50000)],
    },
    {
      value: 4,
      criteria: [assetValueBetween(50000, 150000)],
    },
  ],
  loading: [
    {
      name: "Asset age 4-6 years",
      value: 0.25,
      valueType: "percentage",
      criteria: [
        assetType([
          ...auto,
          ...primary
        ]),
        ageOfAssetBetween(48, 72),
      ],
    },
    {
      name: "Asset age >6 years",
      value: 0.75,
      valueType: "percentage",
      criteria: [
        assetType([
          ...auto,
          ...primary
        ]),
        ageOfAssetGreaterThan(72),
      ],
    },
    // {
    //   name: "Asset age at end >15 yrs",
    //   value: 2,
    //   valueType: "percentage",
    //   criteria: [
    //     assetType([
    //       ...auto,
    //       ...primary
    //     ]),
    //     ageOfAssetAtEndGreaterThan(15),
    //   ],
    // },
    {
      name: "Private sale",
      value: 0.5,
      capitalised: true,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
  ],
  info: [
    `Existing customer - Max Loan amount $150K for motor vehicles and Primary Assets. Max $100K for Secondary Assets. Max $75K for Tertiary AssetsExisting customer limits`,
    `New customer - Max loan amount $100K for Motor Vehicles. Max $55K for Primary, Secondary and Tertiary assets`,
  ],
  productTiers: [
    ...base(rates()),
  ],
};

function rates() {
  return [
    {
      name: "Auto",
      rate: 8.35,
      criteria: [
        loanAmountBetween(15000, 34999.99),
        assetType(auto),
        ageOfAssetAtEndLessThan(15),
      ],
    },
    {
      name: "Auto",
      rate: 8.15,
      criteria: [
        loanAmountBetween(35000, 150000),
        assetType(auto),
        ageOfAssetAtEndLessThan(15),
      ],
    },

    {
      name: "Primary",
      rate: 12.50,
      criteria: [
        loanAmountBetween(5000, 9999.99),
        assetType(primary),
        ageOfAssetAtEndLessThan(15),
      ],
    },
    {
      name: "Primary",
      rate: 9.60,
      criteria: [
        loanAmountBetween(10000, 20000),
        assetType(primary),
        ageOfAssetAtEndLessThan(15),
      ],
    },
    {
      name: "Primary",
      rate: 8.40,
      criteria: [
        loanAmountBetween(20000, 55000),
        assetType(primary),
        ageOfAssetAtEndLessThan(15),
      ],
    },
    {
      name: "Primary",
      rate: 8.15,
      criteria: [
        loanAmountBetween(55000, 150000),
        assetType(primary),
        ageOfAssetAtEndLessThan(15),
      ],
    },


    {
      name: "Secondary",
      rate: 13.00,
      criteria: [
        loanAmountBetween(5000, 10000),
        assetType(secondary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Secondary",
      rate: 10.05,
      criteria: [
        loanAmountBetween(10000, 20000),
        assetType(secondary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Secondary",
      rate: 8.65,
      criteria: [
        loanAmountBetween(20000, 55000),
        assetType(secondary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Secondary",
      rate: 8.40,
      criteria: [
        loanAmountBetween(55000, 150000),
        assetType(secondary),
        ageOfAssetAtEndLessThan(5),
      ],
    },


    {
      name: "Tertiary",
      rate: 14.00,
      criteria: [
        loanAmountBetween(5000, 10000),
        assetType(tertiary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Tertiary",
      rate: 10.55,
      criteria: [
        loanAmountBetween(10000, 20000),
        assetType(tertiary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Tertiary",
      rate: 9.55,
      criteria: [
        loanAmountBetween(20000, 55000),
        assetType(tertiary),
        ageOfAssetAtEndLessThan(5),
      ],
    },
    {
      name: "Tertiary",
      rate: 9.40,
      criteria: [
        loanAmountBetween(55000, 150000),
        assetType(tertiary),
        ageOfAssetAtEndLessThan(5),
      ],
    },


  ]
}