import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  applicationsSelector,
  getAllApplications,
  getQuoteList,
  quotesSelector,
  saveValue,
} from "../store/slices/listsSlice";
import _ from "lodash";
import { useEffect } from "react";
import { userSelector } from "src/store/slices/userSlice";
import { useNavigate, userNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import mixpanel from "src/services/mixpanel";

const referrerViewColumns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "humanId",
    headerName: "ID",
    type: "string",
    width: 100,
    editable: false,
  },
  {
    field: "startDate",
    headerName: "Date",
    type: "string",
    width: 80,
    editable: false,
  },
  {
    field: "time",
    headerName: "Time",
    type: "string",
    width: 80,
    editable: false,
  },
  {
    field: "quoteType",
    headerName: "Quote type",
    type: "string",
    width: 110,
    editable: true,
  },

  // {
  //   field: "loanAmount",
  //   headerName: "Loan amount",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },
  {
    field: "assetValue",
    headerName: "Asset value",
    type: "string",
    width: 110,
    editable: false,
  },

  {
    field: "assetType",
    headerName: "Asset type",
    type: "string",
    width: 250,
    editable: false,
  },
  {
    field: "customer",
    headerName: "Customer",
    width: 250,
    editable: false,
  },
  // {
  //   field: "entityName",
  //   headerName: "Entity",
  //   width: 250,
  //   // editable: true,
  // },
  // {
  //   field: "firstName",
  //   headerName: "First name",
  //   width: 150,
  //   // editable: true,
  // },
  // {
  //   field: "lastName",
  //   headerName: "Last name",
  //   width: 150,
  //   // editable: true,
  // },


  // {
  //   field: "term",
  //   headerName: "Term",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },
  // {
  //   field: "serviceFees",
  //   headerName: "3rd Party fees",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },


  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
];

const masterViewColumns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "humanId",
    headerName: "ID",
    type: "string",
    width: 90,
    editable: false,
  },
  {
    field: "startDate",
    headerName: "Date",
    type: "string",
    width: 80,
    editable: false,
  },
  {
    field: "time",
    headerName: "Time",
    type: "string",
    width: 80,
    editable: false,
  },

  {
    field: "referrer",
    headerName: "Referrer",
    type: "string",
    width: 180,
    editable: false,
  },
  {
    field: "quoteType",
    headerName: "Quote type",
    type: "string",
    width: 110,
    editable: true,
  },
  {
    field: "assetValue",
    headerName: "Asset value",
    type: "string",
    width: 110,
    editable: false,
  },

  {
    field: "assetType",
    headerName: "Asset type",
    type: "string",
    width: 250,
    editable: false,
  },
  {
    field: "customer",
    headerName: "Customer",
    width: 250,
    editable: false,
  },
  // {
  //   field: "entityName",
  //   headerName: "Entity",
  //   width: 250,
  //   // editable: true,
  // },
  // {
  //   field: "firstName",
  //   headerName: "First name",
  //   width: 150,
  //   // editable: true,
  // },
  // {
  //   field: "lastName",
  //   headerName: "Last name",
  //   width: 150,
  //   // editable: true,
  // },


  // {
  //   field: "term",
  //   headerName: "Term",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },
  // {
  //   field: "serviceFees",
  //   headerName: "3rd Party fees",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },


  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
];

export default function QuotesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const applications = useSelector(applicationsSelector);
  // const quotes = response.quotes
  const { isLoading, page } = applications

  console.log({ applications, page })

  const [rows, setRows] = useState([]);

  // const [page, setPage] = useState(0);

  // const queryOptions = React.useMemo(
  //   () => ({
  //     cursor: mapPageToNextCursor.current[page - 1],
  //     pageSize: 10,
  //   }),
  //   [page],
  // );

  // const { isLoading, data, pageInfo } = useQuery(queryOptions);

  const handlePageChange = (newPage) => {
    console.log({ newPage })
    // We have the cursor, we can allow the page transition.
    // if (newPage === 0 || applications.nextPage) {
    handleGetAllApplications(user, newPage + 1);
    dispatch(saveValue({ page: newPage + 1 }))
    // }
  };

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(
    applications?.totalDocs || 0,
  );

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      applications?.totalDocs !== undefined
        ? applications?.totalDocs
        : prevRowCountState,
    );
  }, [applications?.totalDocs, setRowCountState]);

  function handleGetAllApplications(userId, cursor) {
    dispatch(getAllApplications({ userId, cursor }));
  }

  useEffect(() => {
    // console.log(user);
    console.log("mapPageToNextCursor", applications?.page);
    if (user) handleGetAllApplications(user, page || 1);
    // dispatch(saveValue({ page: newPage }))
  }, [user]);

  useEffect(() => {
    mixpanel.track_pageview({
      "page": "Referrals list",
    });
  }, []);

  useEffect(() => {
    setRows(
      applications?.docs?.map((doc) => {
        // const newDoc = _.merge({}, doc);

        const date = new Date(doc.createdAt);
        // console.log("doc", doc);
        return {
          humanId: doc.humanId,
          customer: doc?.entities?.[0]?.entityName ?
            doc?.entities?.[0]?.entityName :
            `${doc?.customers?.[0]?.firstName || "No"} ${doc?.customers?.[0]?.lastName || "customer"}`,
          assetType: doc?.asset?.assetType,
          assetValue: `$${doc?.asset?.assetValue.toLocaleString()}`,
          loanAmount: doc?.loanDetails?.loanAmount,
          term: doc?.loanDetails?.term,
          id: doc?._id,
          startDate: moment(date).format("D-M-YY"),
          time: moment(date).format("h:mm a"),
          referrer: `${doc?.user?.firstName} ${doc?.user?.lastName}`,
          quoteType: doc?.applicationType?.[0]?.toUpperCase() + doc?.applicationType?.slice(1),
        };
      })
    );
    // console.log("rows", rows);
  }, [applications]);

  return (
    <Grid
      container
      item
      direction="column"
      spacing={0}
      style={{ padding: "30px" }}
    // style={{ marginTop: "64px", }}
    >
      <Grid item >
        <Typography
          variant="h1"
          style={{ fontSize: "32px", fontWeight: 800, letterSpacing: "-1.5px", marginBottom: "10px" }}
        >
          Referrals
        </Typography>
      </Grid>
      <Grid item >
        <DataGrid
          autoHeight
          autoPageSize
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          // onRowClick={(event) => {
          //   console.log(event);
          //   navigate(`/dashboard/quote/${event.id}`);
          // }}
          rows={rows || []}
          columns={user.userType === 'master' ? masterViewColumns : referrerViewColumns}
          rowsPerPageOptions={[10]}
          // checkboxSelection
          disableSelectionOnClick
          style={{ background: "#ffffff" }}
          // initialState={initialState}
          pagination
          pageSize={10}
          rowCount={rowCountState}
          paginationMode="server"
          onPageChange={handlePageChange}
          page={page - 1 || 0}
          loading={isLoading}
          sx={{
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '7px', height: "7px"
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              backgroundColor: '#999',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              background: '#616161',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
