import { typeOfSaleList, livingArrangementsTypes, employmentTypes } from "src/constants";
import criteria from "src/utils/criteria";

const {
  PROPERTY_OWNER,
  RENTING,
  BOARDING,
  LIVING_WITH_PARENTS
} = livingArrangementsTypes

const {
  SELF_EMPLOYED,
  FULL_TIME,
  PART_TIME,
  CONTRACT,
  CASUAL,
  BENEFITS
} = employmentTypes

const {
  propertyOwner,
  nonPropertyOwner,
  nonGST,
  typeOfSale,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  employmentType,
  timeOfEmploymentGreaterThan,
  livingArrangements,
  creditScoreBetween,
  termIsLessThanOrEqualTo,
  compound
} = criteria

export default [
  {
    name: "Bronze",
    rate: 28.50,
    info: [
      "Adverse more than 6 months",
      "Ex-bankkrupt more than 1 day"
    ],
    productType: "Adverse",
    criteria: [
      loanAmountBetween(8000, 25000),
      termIsLessThanOrEqualTo(60),
      employmentType([FULL_TIME, PART_TIME, SELF_EMPLOYED, CASUAL, CONTRACT]),
      timeOfEmploymentGreaterThan(2),
      assetType([
        "Motor Vehicle",
        "Electric Motor Vehicle",
        "Caravan",
        "Trailered boat",
        "Motorbike",
      ]),
    ],
  },
]