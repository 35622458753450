import { typeOfSaleList } from "src/constants";
import criteria from "src/utils/criteria";


const {
  typeOfSale,
  assetValueBetween,
  assetType,
  ageOfAssetLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  gstRegistrationGreaterThan,
  creditScoreGreaterThan
} = criteria;

export default {
  date: "17 Apr 2023",
  lender: "GRENKE",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fgrenke.gif",
  fees: [
    {
      name: "Authorised supplier",
      value: 595,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE)]
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 10,
      valueType: "percentage",
      criteria: [assetValueBetween(500, 20000)],
    },
    {
      value: 4,
      max: 6,
      valueType: "percentage",
      criteria: [assetValueBetween(20000, 100000)],
    },
    {
      value: 4,
      max: 4,
      valueType: "percentage",
      criteria: [assetValueBetween(100000, 500000)],
    },
  ],
  loading: [
  ],
  info: [
    `Rate loads are at the discretion of the lender`,
    `Property requirement is not mandatory however lender may condition for deposit`,
    `Bank Statements are not mandatory however applications >$25K may require at the discretion of lender`
  ],
  productTiers: [
    {
      name: "Classic lease",
      rate: 12.90,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(500, 10000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Classic lease",
      rate: 11.90,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Classic lease",
      rate: 9.50,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 35000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Classic lease",
      rate: 9.90,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(35000, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Classic lease",
      rate: 9.50,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(50000, 75000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Classic lease",
      rate: 9.50,
      productType: "Full doc",
      criteria: [
        loanAmountBetween(75000, 500000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(12),
        ageOfAssetLessThan(12),
        typeOfSale([typeOfSaleList.DEALER_SALE]),
        assetType([
          "Medical Equipment",
          "Manufacturing Equipment",
          "Industrial Plant & Printing",
          "Warehouse Equipment (wheeled or tracked)",
          "IT, Computer & Audio Equipment",
          "Office Equipment",
          "Restaurant & Catering Equipment",
          "Office Furniture",
          "Health & Beauty Equipment",
          "Solar",
        ]),
        creditScoreGreaterThan(550)
      ],
    },
  ],
};