import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleMixpanelQuoteEvent } from "src/utils/mixPanelTrackEvents";
import { useParams } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";

import {
  updateAsset,
  assetSelector,
  saveAsset,
} from "../store/slices/applicationFormSlice";

import { typeOfSaleList } from "src/constants";

export default function TypeOfSale() {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const { typeOfSale, _id } = useSelector(assetSelector);

  function handleUpdateAssetDetails(event) {
    handleMixpanelQuoteEvent(applicationId, "type of sale", event.target.value)
    dispatch(saveAsset({ typeOfSale: event.target.value }))
    if (_id) dispatch(updateAsset({ _id, typeOfSale: event.target.value }));
  };

  return <TextField
    fullWidth
    style={{ background: "#ffffff" }}
    select
    value={typeOfSale || ""}
    label="Type of sale"
    size="small"
    onChange={handleUpdateAssetDetails}
  >
    {Object.keys(typeOfSaleList).map((item, i) => {
      if (i === 0) return { label: typeOfSaleList[item], default: true }
      return { label: typeOfSaleList[item] }
    }).map((asset, i) => (
      <MenuItem default={asset.default} value={asset.label}>
        {asset.label}
      </MenuItem>
    ))}
  </TextField>
}
