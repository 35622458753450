import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector, saveValue, updateUser } from 'src/store/slices/userSlice';
import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import mixpanel from "src/services/mixpanel";

export default function AlertDialog() {
    const dispatch = useDispatch()
    const user = useSelector(userSelector);
    const [open, setOpen] = useState(user.status === "new");
    // const [open, setOpen] = useState(true);

    function handleSkip() {
        setOpen(false);
        dispatch(updateUser({
            status: "active"
        }))
        mixpanel.track(`Demo: Skipped`)
    };

    function startWalkthrough() {
        setOpen(false);
        dispatch(saveValue({ runWalkthrough: true }))
        mixpanel.track(`Demo: Step 1 - Asset type`)
    };

    return (

        <Dialog
            open={open}
            onClose={handleSkip}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {/* <DialogTitle id="alert-dialog-title">
                {user?.firstName ? `Welcome ${user.firstName}!` : "Welcome!"}
            </DialogTitle> */}
            <DialogContent>
                <Stack alignItems="center" justifyContent="center" marginBottom={2} style={{ height: 150, background: "rgb(10,18,30)" }}>
                    <Typography
                        color="white"
                        style={{ fontWeight: "bold", fontSize: "32px", letterSpacing: "-1px" }}
                    >
                        {user?.firstName ? `Welcome ${user.firstName}! 👋👋` : "Welcome! 👋👋"}
                    </Typography>
                </Stack>

                <Typography marginBottom={1.2} fontSize={15}>
                    We're excited that you're using Financeable for the first time. 🎉
                </Typography>
                <Typography marginBottom={1.2} fontSize={15}>
                    Starting from scratch is the worst! So let us show you how
                    it works by doing a <strong>quick demo consumer asset quote together</strong>.
                </Typography>
                {/* <Typography marginBottom={1.2} fontSize={15}>
                    I know you're tempted to skip but, even though it's really easy to use,
                    there's some tips and tricks that can help you get the most out of it right away.
                </Typography> */}
                <Typography fontSize={15} marginBottom={1.2}>
                    You'll learn how to:
                    <ul style={{ margin: 0, paddingLeft: 20 }}>
                        <li>use the calculator</li>
                        <li>view product criterias</li>
                        <li>sort products based on rate</li>
                        <li>filter products based on criteria</li>
                        <li>update the profile for a better product fit</li>
                    </ul>
                </Typography>
                <Typography fontSize={15}>We promise it'll
                    be worth it! 🤞</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSkip}>Skip</Button>
                <Button variant='contained' color='secondary' onClick={startWalkthrough} autoFocus>
                    Start demo
                </Button>
            </DialogActions>
        </Dialog >

    );
}