import { useState } from "react";
import { startCase } from "lodash";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Checkbox, Input } from "@mui/material";
import { userSelector } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "75vh",
      width: 250,
    },
  },
};

const params = [
  ["fit", "asc"],
  ["repayments", "asc"],
  ["rate", "asc"],
  ["loanAmount", "asc"],
  ["fees", "asc"],
  // ["qualification", "asc"],
];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export default function ProductSelectorOrderBy({ handleOrderBy, value, screenSize }) {
  const user = useSelector(userSelector)

  //   const handleChange = (event) => {
  //     const {
  //       target: { value },
  //     } = event;
  //     setPersonName(
  //       // On autofill we get a the stringified value.
  //       typeof value === "string" ? value.split(",") : value
  //     );
  //   };

  return (
    <FormControl
      // fullWidth
      className="four-step"
      size="small"
      justifySelf="end"
      sx={{ m: 1, width: 120 }}
      style={{ margin: "5px 0 10px 0", width: screenSize.dynamicWidth < 900 ? 120 : 160 }}
    >
      <InputLabel id="demo-multiple-chip-label">Order by</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple={user.status !== "new"}
        // multiple={false}
        value={value}
        onChange={handleOrderBy}
        style={{ marginTop: "10px", padding: "0 15px" }}
        input={<Input id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => `${selected.length} selected` || undefined}
        MenuProps={MenuProps}
      >
        {params.map((param) => (
          <MenuItem
            key={param[0]}
            value={param}
          //   style={getStyles(name, , theme)}
          >
            <Checkbox checked={value?.includes(param)} />
            {startCase(param[0])}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
