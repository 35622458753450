import { typeOfSaleList, livingArrangementsTypes } from "src/constants";
import criteria from "src/utils/criteria";

const {
    PROPERTY_OWNER,
    RENTING,
    BOARDING,
    LIVING_WITH_PARENTS
} = livingArrangementsTypes

const {
    propertyOwner,
    nonPropertyOwner,
    nonGST,
    typeOfSale,
    hasDeposit,
    gstRegistrationBetween,
    gstRegistrationGreaterThan,
    termIsGreaterThan,
    assetValueBetween,
    assetType,
    ageOfAssetBetween,
    ageOfAssetLessThan,
    ageOfAssetGreaterThan,
    ageOfAssetAtEndLessThan,
    loanAmountBetween,
    timeInBusinessGreaterThan,
    creditScoreGreaterThan,
    employmentType,
    timeOfEmploymentGreaterThan,
    livingArrangements,
    creditScoreBetween,
    compound,
    dateOfBirthGreaterThan,
    dateOfBirthLessThan
} = criteria

export default [
    {
        name: "AA Tier Under 23 Years Old - Motor Vehicle New",
        rate: 9.99,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 30000),
            ageOfAssetLessThan(12),
            dateOfBirthLessThan(23),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
            timeOfEmploymentGreaterThan(12),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreBetween(701, 810)
        ],
    },
    {
        name: "AA Tier Under 23 Years Old - Motor Vehicle 12-60 Months Old",
        rate: 10.79,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 30000),
            ageOfAssetBetween(12, 59),
            dateOfBirthLessThan(23),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
            timeOfEmploymentGreaterThan(12),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreBetween(701, 810)
        ],
    },
    {
        name: "AA Tier Under 23 Years Old - Motor Vehicle 60-119 Months Old",
        rate: 11.49,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 30000),
            ageOfAssetBetween(60, 119),
            dateOfBirthLessThan(23),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
            timeOfEmploymentGreaterThan(12),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreBetween(701, 810)
        ],
    },
    {
        name: "AA Tier Under 23 Years Old - Motor Vehicle >119 Months Old",
        rate: 11.99,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 30000),
            ageOfAssetGreaterThan(119),
            dateOfBirthLessThan(23),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
            timeOfEmploymentGreaterThan(12),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreBetween(701, 810)
        ],
    },

    {
        name: "AA Tier Over 23 Years Old - Motor Vehicle New",
        rate: 9.99,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 75000),
            ageOfAssetLessThan(12),
            dateOfBirthGreaterThan(23),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
            timeOfEmploymentGreaterThan(12),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreBetween(701, 810)
        ],
    },
    {
        name: "AA Tier Over 23 Years Old - Motor Vehicle 12-60 Months Old",
        rate: 10.79,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 75000),
            ageOfAssetBetween(12, 59),
            dateOfBirthGreaterThan(23),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
            timeOfEmploymentGreaterThan(12),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreBetween(701, 810)
        ],
    },
    {
        name: "AA Tier Over 23 Years Old - Motor Vehicle 60-119 Months Old",
        rate: 11.49,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 75000),
            ageOfAssetBetween(60, 119),
            dateOfBirthGreaterThan(23),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
            timeOfEmploymentGreaterThan(12),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreBetween(701, 810)
        ],
    },
    {
        name: "AA Tier Over 23 Years Old - Motor Vehicle >119 Months Old",
        rate: 11.99,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 75000),
            ageOfAssetGreaterThan(119),
            dateOfBirthGreaterThan(23),
            livingArrangements([
                PROPERTY_OWNER,
                RENTING,
                BOARDING,
                LIVING_WITH_PARENTS
            ]),
            employmentType(["Self employed", "Full-time", "Part-time", "Contract", "Casual"]),
            timeOfEmploymentGreaterThan(12),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreBetween(701, 810)
        ],
    }
]