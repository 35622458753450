import { typeOfSaleList } from "src/constants";
import criteria from "src/utils/criteria";
import platinum from "./platinum"
import gold from "./gold";
import silver from "./silver";
import bronze from "./bronze";
import budget from "./budget";

const {
  typeOfSale,
  typeOfSaleMany,
} = criteria

export default {
  date: "6 Apr 2023",
  lender: "Greenlight",
  type: "consumer",
  logoURL: "https://assets.financeable.com.au/lender-logos%2FGreenlight.gif",
  fees: [
    {
      name: "Establishment fee",
      value: 940,
      capitalised: true,
      frequency: "upfront",
      criteria: [
        typeOfSaleMany([
          typeOfSaleList.DEALER_SALE,
          typeOfSaleList.BALLOON_RESIDUAL_REFINANCE,
          typeOfSaleList.MID_TERM_REFINANCE,
          typeOfSaleList.SALE_BUYBACK_EQUITY
        ])]
    },
    {
      name: "Private fee",
      value: 940,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)]
    },
    // {
    //   name: "Equalisation fee",
    //   value: 599,
    //   capitalised: true,
    //   frequency: "upfront",
    //   criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE), assetType(["Motor Vehicle", "Electric Motor Vehicle", "Caravan"])],
    // },
    {
      name: "Monthly account fee",
      value: 10.00,
      capitalised: false,
      frequency: "monthly",
    },
    {
      name: "PPSR",
      value: 50,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [],
  loading: [],
  info: [
    `Max term of 3 years for net amount finance less than or equal to $10,000`,
    `Lenders fees are 10% of NAF up to a maximum of $940`,
    `No grey imports`,
    `Adverse:`,
    `- Unpaid vehicle finance defaults <= 24 months;`,
    `- Unpaid court judgements <= 24 months`,
    `- Paid court judgements to a financier <= 24 months`,
    `- Current Bankrupts, Part X or Current Part IX less 12 months`,
    `- Any defaults since discharged bankruptcy`,
  ],
  productTiers: [
    ...platinum,
    ...gold,
    ...silver,
    ...bronze,
    ...budget
  ],
};