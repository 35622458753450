import { configureStore } from "@reduxjs/toolkit";
import { formdataSlice } from "./slices/applicationFormSlice";
import { listsSlice } from "./slices/listsSlice";
import { customerSlice } from "./slices/customerSlice";
import { userSlice } from "./slices/userSlice";
import { organisationSlice } from "./slices/organisationSlice";

const store = configureStore({
  reducer: {
    userDetails: formdataSlice.reducer,
    lists: listsSlice.reducer,
    user: userSlice.reducer,
    customer: customerSlice.reducer,
    organisation: organisationSlice.reducer,
  },
});
export default store;
