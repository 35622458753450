export function evaluateCriteria(productCriteria, objects) {
  const result = productCriteria.map((criteria) => {
    if (objects[criteria.object]) {
      if (
        objects[criteria.object][criteria.attribute] ||
        objects[criteria.object][criteria.attribute] === 0 ||
        (!objects[criteria.object][criteria.attribute] && criteria.condition === "isNot")
      ) {

        if (conditions[criteria.condition](
          criteria,
          objects[criteria.object][criteria.attribute]
        )) {
          criteria.result = true;

          return criteria;
        }
      } else if (conditions[criteria.condition](
        criteria,
        objects[criteria.object]
      )) {

        criteria.result = true;

        return criteria;
      }

      if (objects[criteria.object][criteria.attribute] === undefined) {
        criteria.result = undefined;

        return criteria
      }

      criteria.result = false;

      return criteria;
    }

    criteria.result = undefined;

    return criteria;
  });

  return result;
}

export function calculateExtras(loading, objects) {

  return loading.filter((loadingItem) => {

    if (loadingItem && loadingItem.criteria) {
      const isMatch = loadingItem.criteria.reduce((boolean, criteria) => {
        if (objects[criteria.object]) {
          return conditions[criteria.condition](
            criteria,
            objects[criteria.object][criteria.attribute] ||
            objects[criteria.object]
          )
            ? boolean
            : false;
        }
      }, true);

      if (isMatch) return loadingItem;
    }

    return null;
  });
}

export function calculateLoading(loading, objects) {
  return loading.map((loadingItem) => {
    // console.log({ loadingItem })
    if (loadingItem && loadingItem.criteria) {
      const result = loadingItem.criteria.reduce((boolean, criteria) => {
        if (objects[criteria.object]) {

          return conditions[criteria.condition](
            criteria,
            objects[criteria.object][criteria.attribute] ||
            objects[criteria.object]
          )
            ? boolean
            : false;

        }
      }, true);

      loadingItem.result = result;
      return loadingItem;
    }
  });
}

export function calculateFees(loading, objects) {

  return loading.filter((loadingItem) => {
    if (!loadingItem.criteria) return true;

    return loadingItem.criteria.reduce((boolean, criteria) => {

      if (objects[criteria.object]) {
        return conditions[criteria.condition](
          criteria,
          objects[criteria.object][criteria.attribute]
        )
          ? boolean
          : false;
      }
    }, true);
  });
}

export const conditions = {
  is: ({ value }, attr) => {
    return value;
  },
  isNot: ({ value }, attr) => {
    return !attr;
  },
  zero: ({ value }, attr) => {
    return attr < 1 || !attr;
  },
  equal: ({ value }, attr) => {
    return value === attr;
  },
  notEqual: ({ value }, attr) => {
    return value !== attr;
  },
  lessThan: ({ value }, attr) => {

    return attr < value;
  },
  greaterThan: ({ value }, attr) => {
    return attr > value;
  },
  lessThanOrEqualTo: ({ value }, attr) => {
    return attr < value || value === attr;
  },
  greaterThanOrEqualTo: ({ value }, attr) => {
    return attr > value || value === attr;
  },
  betweenLow: ({ value }, attr) => {
    const { min, max } = value;
    return attr >= min && attr <= max;
  },
  between: ({ value }, attr) => {
    const { min, max } = value;
    return attr >= min && attr <= max;
  },
  hasOne: ({ value }, attr) => {
    // console.log({ value, attr })
    return value.includes(attr);
  },
  eachIsGreaterThan: (criteria, objects) => {

    const results = objects.map((items) =>
      (items === undefined) ? undefined : items[criteria.attribute] > criteria.value
    );

    if (results.includes(undefined)) return undefined
    return !results.includes(false)
  },
  sumIsGreaterThan: (criteria, objects) => {

    if (objects.some((item) => item?.[criteria.attribute] === undefined))
      return undefined;
    const results = objects.reduce((accum, item) => {

      if (item?.[criteria.attribute]) return item?.[criteria.attribute] + accum;

      return accum;
    }, 0);

    return results > criteria.value * objects.length;
  },
  exists: (criteria, objects) => {
    const results = objects?.map((item) => {

      if (item?.[criteria.attribute] === undefined) return undefined;
      if (item?.[criteria.attribute])
        return criteria.value === item?.[criteria.attribute];
    });

    if (
      results.length > 0 &&
      results.includes(undefined) &&
      !results.includes(true) &&
      !results.includes(false)
    )
      return undefined;

    if (results.length > 0 && results.includes(true)) return true;

    if (results.length > 0 && results.includes(false)) return false;

    return undefined;
  },
  doesntExist: (criteria, objects) => {

    const results = objects?.map((item) => {
      if (item?.[criteria.attribute] === undefined) return undefined;
      if (item) return criteria.value === item?.[criteria.attribute];
    });

    if (
      results.length > 0 &&
      results.includes(undefined) &&
      !results.includes(true) &&
      !results.includes(false)
    )
      return undefined;

    if (results.length > 0 && results.includes(true)) return false;

    if (results.length > 0 && results.includes(false)) return true;

    return undefined;
  },
};
