import { typeOfSaleList } from "src/constants";
import criteria from "src/utils/criteria";

const {
    propertyOwner,
    nonPropertyOwner,
    nonGST,
    typeOfSale,
    hasDeposit,
    gstRegistrationBetween,
    gstRegistrationGreaterThan,
    termIsGreaterThan,
    assetValueBetween,
    assetType,
    ageOfAssetBetween,
    ageOfAssetLessThan,
    ageOfAssetAtEndLessThan,
    loanAmountBetween,
    timeInBusinessGreaterThan,
    creditScoreGreaterThan,
    employmentType,
    timeOfEmploymentGreaterThan,
    livingArrangements,
    creditScoreBetween,
    compound
} = criteria

export default [
    {
        name: "Tier B - Motor Vehicle New/Demo",
        rate: 10.99,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 125000),
            ageOfAssetLessThan(12),
            livingArrangements(["Property owner", "Renting",]),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
            creditScoreBetween(600, 749),
        ],
    },
    {
        name: "Tier B - Motor Vehicle 12-36 months old",
        rate: 11.49,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 125000),
            ageOfAssetBetween(12, 36),
            livingArrangements(["Property owner", "Renting",]),
            creditScoreBetween(600, 749),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
        ],
    },
    {
        name: "Tier B - Motor Vehicle 37-72 months old",
        rate: 11.99,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 125000),
            ageOfAssetBetween(37, 72),
            livingArrangements(["Property owner", "Renting",]),
            creditScoreBetween(600, 749),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
        ],
    },
    {
        name: "Tier B - Motor Vehicle 72-120 months old",
        rate: 13.49,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 125000),
            ageOfAssetBetween(72, 120),
            livingArrangements(["Property owner", "Renting",]),
            creditScoreBetween(600, 749),
            assetType([
                "Motor Vehicle", "Electric Motor Vehicle"
            ]),
        ],
    },



    {
        name: "Tier B - Caravan New/Demo",
        rate: 10.99,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 110000),
            ageOfAssetLessThan(12),
            livingArrangements(["Property owner", "Renting",]),
            assetType([
                "Caravan",
            ]),
            creditScoreBetween(600, 749),
        ],
    },
    {
        name: "Tier B - Caravan 12-36 months old",
        rate: 11.49,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 110000),
            ageOfAssetLessThan(12),
            livingArrangements(["Property owner", "Renting",]),
            creditScoreBetween(600, 749),
            assetType([
                "Caravan",
            ]),
        ],
    },
    {
        name: "Tier B - Caravan 37-72 months old",
        rate: 11.99,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 110000),
            ageOfAssetBetween(37, 72),
            livingArrangements(["Property owner", "Renting",]),
            creditScoreBetween(600, 749),
            assetType([
                "Caravan",
            ]),
        ],
    },
    {
        name: "Tier B - Caravan 72-120 months old",
        rate: 13.49,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(5000, 110000),
            ageOfAssetBetween(72, 120),
            livingArrangements(["Property owner", "Renting",]),
            creditScoreBetween(600, 749),
            assetType([
                "Caravan",
            ]),
        ],
    },

    {
        name: "Tier B - Motorbike New/Demo",
        rate: 10.99,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(3000, 55000),
            ageOfAssetLessThan(12),
            livingArrangements(["Property owner", "Renting",]),
            assetType([
                "Motorbike",
            ]),
            creditScoreBetween(600, 749),
        ],
    },
    {
        name: "Tier B - Motorbike 12-36 months old",
        rate: 11.49,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(3000, 55000),
            ageOfAssetLessThan(12),
            livingArrangements(["Property owner", "Renting",]),
            creditScoreBetween(600, 749),
            assetType([
                "Motorbike",
            ]),
        ],
    },
    {
        name: "Tier B - Motorbike 37-72 months old",
        rate: 11.99,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(3000, 55000),
            ageOfAssetBetween(37, 72),
            livingArrangements(["Property owner", "Renting",]),
            creditScoreBetween(600, 749),
            assetType([
                "Motorbike",
            ]),
        ],
    },
    {
        name: "Tier B - Motorbike 72-120 months old",
        rate: 13.49,
        productType: "Low doc",
        criteria: [
            loanAmountBetween(3000, 55000),
            ageOfAssetBetween(72, 120),
            livingArrangements(["Property owner", "Renting",]),
            creditScoreBetween(600, 749),
            assetType([
                "Motorbike",
            ]),
        ],
    },
]