import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";

// console.log(process.env.REACT_APP_API_URL);

function headers(token) {
  if (token) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

export const getUser = createAsyncThunk(
  "registeruser",
  async ({ }, { rejectWithValue }) => {
    const token = localStorage.token;
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/user`,
        method: "GET",
        headers: headers(token),
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  "registeruser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/user/register/`,
        method: "POST",
        headers: headers(),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("userResponse", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (data, { rejectWithValue }) => {
    console.log("user registration data", data);
    const token = localStorage.token;
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/user`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("userResponse", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "login/user",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/user/login/`,
        method: "POST",
        headers: headers(),
        data,
      })
      // .catch((err) => {
      //   console.log("err", err.response);
      //   return rejectWithValue(err);
      // });
      // console.log("login response", response);
      if (response.status === "error") {
        console.log("userResponse", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotpassword/user",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/forget_password/`,
        {
          method: "POST",
          headers: headers(),
          body: JSON.stringify({
            email,
          }),
        }
      ).catch((err) => {
        console.log("err", err);
      });
      const userResponse = await response.json();
      if (userResponse.status === "error") {
        console.log("userResponse", userResponse);
        return rejectWithValue(userResponse);
      }
      return userResponse;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "updatepassword/user",
  async ({ email, password }, { rejectWithValue, getState }) => {
    console.log("update email", email);
    try {
      const { userDetails } = getState();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user-credentials/password/update`,
        {
          method: "POST",
          headers: headers(userDetails.token),
          data: {
            email,
            password,
          },
        }
      ).catch((err) => {
        console.log("err", err);
      });
      const userResponse = await response.json();
      // console.log("update", userResponse);
      if (userResponse.status === "error") {
        console.log("userResponse", userResponse);
        return rejectWithValue(userResponse);
      }
      return userResponse;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const validateToken = createAsyncThunk(
  "validate",
  async (token, { rejectWithValue, getState }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/user/validate`,
        method: "GET",
        headers: headers(token),
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("validate token response:", response);
      if (response.status === "error") {
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const validateEmail = createAsyncThunk(
  "validate/email",
  async (email, { rejectWithValue, getState }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/user/email/validate/${email}`,
        method: "GET",
        headers: headers(),
      }).catch((err) => {
        console.log("err", err);
      });
      console.log("validate email response:", response);
      if (response.status === "error") {
        console.log("response", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const requestResetPassword = createAsyncThunk(
  "reset-password/request",
  async (email, { rejectWithValue, getState }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/user-credentials/reset-password/request/${email}`,
        method: "GET",
        headers: headers(),
      }).catch((err) => {
        console.log("err", err);
      });
      console.log("reset password response:", response);
      if (response.status === "error") {
        console.log("response", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "reset-password/reset",
  async (data, { rejectWithValue, getState }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/user-credentials/reset-password/reset`,
        method: "POST",
        headers: headers(),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      console.log("reset password response:", response);
      if (response.status === "error") {
        console.log("response", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updatePhoneNumber = createAsyncThunk(
  "user/update-phoneNumber",
  async (data, { rejectWithValue }) => {
    console.log("user update phoneNumber data", data);
    const token = localStorage.token;
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/user/phone-number`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("userResponse", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user-credentials/change-password",
  async (data, { rejectWithValue }) => {
    console.log("user update phoneNumber data", data);
    const token = localStorage.token;
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/user-credentials/change`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("userResponse", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateSettings = createAsyncThunk(
  "settings/update",
  async (data, { rejectWithValue }) => {
    console.log("settings update data", data);
    const token = localStorage.token;
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/settings`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("userResponse", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getAllTransactions = createAsyncThunk(
  "transactions/get",
  async ({ }, { rejectWithValue }) => {
    const token = localStorage.token;
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/transactions/all`,
        method: "GET",
        headers: headers(token),
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("userResponse", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const createSubscription = createAsyncThunk(
  "subscription/create",
  async (data, { rejectWithValue }) => {
    const token = localStorage.token;
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/billing/subscription`,
        method: "POST",
        headers: headers(token),
        data,
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("userResponse", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getCustomerBilling = createAsyncThunk(
  "customer-billing/get",
  async (data, { rejectWithValue }) => {
    const token = localStorage.token;
    console.log("calling");
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/billing/customer`,
        method: "GET",
        headers: headers(token),
      }).catch((err) => {
        console.log("err", err);
      });
      // console.log("signUp response:", userResponse);
      if (response.status === "error") {
        console.log("userResponse", response);
        return rejectWithValue(response);
      }
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const submitReferralAgreement = createAsyncThunk(
  "document/privacy",
  async (data, { rejectWithValue, getState }) => {
    const token = localStorage.token;

    try {
      const privacy = await axios({
        url: `${process.env.REACT_APP_API_URL}/document/referral-agreement`,
        method: "POST",
        headers: headers(token),
        data,
      });
      // console.log("privacy:", privacy);
      if (privacy.status === "error") {
        // console.log("privacy", privacy);
        return rejectWithValue(privacy);
      }
      return privacy;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  emailInUse: false,
  isCreatingAccount: false,
  createAccountSuccess: false,
  changePasswordFail: null,
  isChangingPassword: null,
  changePasswordSuccess: false,
  templateSaveSuccess: false,
  transactions: null,
  clientSecret: null,
  isCreatingSubscription: false,
  runWalkthrough: false,
  walkthroughIndex: 0
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveValue: (state, action) => {
      // console.log({ action })
      return _.merge(state, action.payload);
    },
    resetState: () => initialState,
  },
  extraReducers: {
    [getUser.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state = _.merge(state, payload.data);

      // console.log(JSON.stringify(state, null, 4));
    },
    [getUser.pending]: (state) => {
      state.isUpdating = true;
    },
    [getUser.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);

      state.isCreatingAccount = false;
      // state.errorMessage = payload.message;
    },

    [updateUser.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state = _.merge(state, payload.data);
      state.isUpdating = false;
      state.updateSuccess = true;
      // console.log(JSON.stringify(state, null, 4));
    },
    [updateUser.pending]: (state) => {
      state.isUpdating = true;
    },
    [updateUser.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);

      state.isCreatingAccount = false;
      // state.errorMessage = payload.message;
    },

    [registerUser.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isCreatingAccount = false;
      localStorage.token = payload.data.token;
      state = _.merge(state, payload.data.user);
      state.createAccountSuccess = true;
      // console.log(JSON.stringify(state, null, 4));
    },
    [registerUser.pending]: (state) => {
      state.isCreatingAccount = true;
    },
    [registerUser.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);

      state.isCreatingAccount = false;
      // state.errorMessage = payload.message;
    },

    [loginUser.fulfilled]: (state, { payload }) => {
      // console.log("payload:", payload);
      // const { user, token } = payload.data;
      // state.token = token;
      // state.user = user;
      // state.isFetching = false;
      // state.isSuccess = true;
      // state.loginStatus = true;
      state.errorMessage = null
      localStorage.token = payload.data.token;
      state = _.merge(state, payload.data.user);
      // console.log(JSON.stringify(state, null, 4));
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
    },

    [forgotPassword.fulfilled]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.successMessage = payload.message;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [forgotPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [updatePassword.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.email;
      state.successMessage = payload.message;
    },
    [updatePassword.pending]: (state) => {
      state.isFetching = true;
    },
    [updatePassword.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [updatePhoneNumber.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      state = _.merge(state, payload.data);
    },
    [updatePhoneNumber.pending]: (state) => {
      state.isFetching = true;
    },
    [updatePhoneNumber.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [validateToken.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      state = _.merge(state, payload.data);
      // console.log(JSON.stringify(state, null, 4));
    },
    [validateToken.pending]: (state) => {
      state.isFetching = true;
    },
    [validateToken.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [validateEmail.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      state.emailInUse = payload.data.emailInUse;
    },
    [validateEmail.pending]: (state) => {
      state.isFetching = true;
    },
    [validateEmail.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [requestResetPassword.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      // state.emailInUse = payload.data.emailInUse;
    },
    [requestResetPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [requestResetPassword.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [resetPassword.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      localStorage.token = payload.data.token;
      state = _.merge(state, payload.data.user);
    },
    [resetPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      // state.errorMessage = payload.message;
    },

    [changePassword.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload.data);
      localStorage.token = payload.data.token;
      state.isChangingPassword = false;
      state.changePasswordSuccess = true;
      state = _.merge(state, payload.data.user);
    },
    [changePassword.pending]: (state) => {
      state.isChangingPassword = true;
      state.changePasswordFail = true;
    },
    [changePassword.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.isChangingPassword = false;
      state.changePasswordFail = true;
      // state.errorMessage = payload.message;
    },

    [updateSettings.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload.data);
      localStorage.token = payload.data.token;
      state.isChangingPassword = false;
      state.changePasswordSuccess = true;
      state = _.merge(state, payload.data);
      state.templateSaveSuccess = true;
      // console.log(JSON.stringify(state, null, 4));
    },
    [updateSettings.pending]: (state) => {
      state.isChangingPassword = true;
      state.changePasswordFail = true;
    },
    [updateSettings.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
      state.isChangingPassword = false;
      state.changePasswordFail = true;
      // state.errorMessage = payload.message;
    },

    [getAllTransactions.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload.data);
      state = _.merge(state.transactions, payload.data);

      // console.log(JSON.stringify(state, null, 4));
    },
    [getAllTransactions.pending]: (state) => {
      state.isChangingPassword = true;
    },
    [getAllTransactions.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;

      // state.errorMessage = payload.message;
    },

    [createSubscription.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload.data);
      state = _.merge(state, payload.data);
      state.isCreatingSubscription = false;
      // console.log(JSON.stringify(state, null, 4));
    },
    [createSubscription.pending]: (state) => {
      state.isChangingPassword = true;
      state.isCreatingSubscription = true;
    },
    [createSubscription.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isCreatingSubscription = false;

      // state.errorMessage = payload.message;
    },

    [getCustomerBilling.fulfilled]: (state, { payload }) => {
      // console.log("payload", payload.data);

      state.isCreatingSubscription = false;
      state = _.merge(state, payload.data);
      // console.log(JSON.stringify(state, null, 4));
    },
    [getCustomerBilling.pending]: (state) => {
      state.isChangingPassword = true;
      state.isCreatingSubscription = true;
    },
    [getCustomerBilling.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isCreatingSubscription = false;

      // state.errorMessage = payload.message;
    },

    [submitReferralAgreement.fulfilled]: (state, { payload }) => {
      console.log("payload", payload.data);
      state = _.merge(state, payload.data);
    },
    [submitReferralAgreement.pending]: (state) => {
      state.isFetching = true;
    },
    [submitReferralAgreement.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.isFetching = false;
      state.isError = true;
    },

  },
});

export const { saveValue, resetState } = userSlice.actions;

// export const store = configureStore({
//   reducer: {
//     applications: applicationsSlice.reducer,
//   },
// });

export const userSelector = (state) => state.user;
