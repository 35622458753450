import { useEffect, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import _, { debounce, merge } from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, CircularProgress, Hidden, LinearProgress, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  saveAsset,
  startApplication,
  getApplication,
  updateAsset,
  updateLoanDetails,
  applicationSelector,
  assetSelector,
  loanDetailsSelector,
  saveLoanDetails,
  userDetailsSelector,
  createCustomer,
  getApplicationDownloadUrl,
  submitApplication,
  clearState,
  entitySelector,
  customersSelector,
} from "../store/slices/applicationFormSlice";

import commercialProducts from "../products/commercial";
import consumerProducts from "../products/consumer";

import getBalloon from "src/utils/getBalloon";
import calculateRepayments from "src/utils/calculateRepayments";
import {
  evaluateCriteria,
  calculateLoading,
  calculateFees,
  calculateExtras,
} from "src/utils/evaluateCriteria";
import getMonthlyRate from "src/utils/getMonthlyRate";

import ProductSelectorFilterBy from "./ProductSelectorFilterBy";
import Product from "./Product";
import {
  consumerLenderList,
  commercialLenderList,
  productTypes
} from "src/constants";
import ProductSelectorOrderBy from "./ProductSelectorOrderBy";

import { assetTypes } from "../assetTypes"
import { useMemo } from "react";
import Refer from "./Refer";
import calculateComparisonRate from "src/utils/calculateComparisonRate";
import mixpanel from "src/services/mixpanel";
import { saveValue, userSelector } from "src/store/slices/userSlice";

const assetTypesList = assetTypes.map(asset => asset.label)

export default function ProductSelector() {
  const dispatch = useDispatch()
  const [fitFilterValue, setFitFilterValue] = useState("3 criteria off")

  // const { loadingProducts } = useSelector(userDetailsSelector)
  const { _id, applicationType } = useSelector(applicationSelector)
  const isCommercial = applicationType === "commercial"
  const asset = useSelector(assetSelector);
  const loanDetails = useSelector(loanDetailsSelector);
  const entity = useSelector(entitySelector);
  const customers = useSelector(customersSelector);
  const user = useSelector(userSelector)
  const criteriaFilterListDefault = isCommercial ? [
    "ageOfAsset",
    "ageOfAssetAtEnd",
    "assetType",
    "deposit",
    "gst",
    "livingArrangements",
    "loanAmount",
    "score",
    "timeInBusiness",
    "typeOfSale",
  ] : [
    "ageOfAsset",
    "ageOfAssetAtEnd",
    "assetType",
    "dateOfBirth",
    "deposit",
    "employmentType",
    "livingArrangements",
    "loanAmount",
    "score",
    "timeOfEmployment",
    "typeOfSale",
  ]

  const [criteriaFilterValue, setCriteriaFilterValue] = useState(["assetType"])
  const [criteriaFilterList, setCriteriaFilterList] = useState(criteriaFilterListDefault)

  const products = isCommercial ? commercialProducts : consumerProducts
  const lenderList = isCommercial ? commercialLenderList : consumerLenderList

  const {
    assetValue = 0,
    ageOfAsset = "",
    assetType = "",
    privateSale,
    make = "",
    model = "",
    description = "",
    typeOfSale,
    // documentFee: 320,
  } = useSelector(assetSelector);

  const {
    applicationFee = 0,
    balloon = 0,
    term = 0,
    // repayments = 0,
    // loanAmount = 0,
    brokerage = 0,
    rate = 0,
    deposit = 0,
    // fees
  } = useSelector(loanDetailsSelector);

  const [openReferModal, setOpenReferModal] = useState(false);

  const [productsMap, setProductsMap] = useState([]);
  // const [loanAmount, setLoanAmount] = useState(0);
  const [orderByParameters, setOrderByParameters] = useState([
    // ['fit', 'asc'],
    // ['repayments', 'asc'],
  ]);
  const [orderByParametersList, setOrderByParametersList] = useState([
    ["fit", "rate"],
    ["asc", "asc"],
  ]);

  const [filterByParameters, setFilterByParameters] = useState(lenderList);

  const [filterByProductType, setFilterByProductType] = useState(productTypes);
  // const [filterByAssetType, setFilterByAssetType] = useState(assetTypesList);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return (() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

  const handleLoanAmountCommercial = (fees, brokerage) => {
    // console.log({ assetValue, deposit, fees, brokerage }, (assetValue - deposit) + ((assetValue - deposit) * (brokerage || 0 / 100)) + fees)
    return (assetValue - deposit) + ((assetValue - deposit) * (brokerage / 100)) + fees;
  };

  const handleLoanAmountConsumer = (fees) => {
    // console.log({ deposit })
    return (
      assetValue - deposit + fees
    );
  };

  function evaluateProducts() {
    const customerProfile = customers?.map(
      (customer) => customer.customerProfile
    )[0];

    const creditProfile = customers?.map((customer) => customer.creditProfile)[0];
    const employer = customers?.map((customer) => customer.employers[0])[0];

    const productList = products?.map((product) => {
      return product?.productTiers.map((tier) => {

        if (tier) {
          const defaultBrokerage = calculateExtras(product.brokerage, {
            asset,
            loanDetails,
            entity,
          })[0];

          const FEES = product.fees

          const newLoanDetails = { loanAmount: assetValue - deposit, term, deposit, balloon }

          const FEESLIST = calculateFees(FEES, {
            asset,
            loanDetails: newLoanDetails,
            entity,
            customer: customers?.[0],
            customerProfile,
            creditProfile,
            employer,
          });

          // calculate any dynamic fees
          const feesList = FEESLIST.map(fee => {
            if (fee.calc === "percent") {
              const feeValue = fee.value * 1
              const newFeeValue = feeValue / 100 * newLoanDetails.loanAmount

              return { ...fee, value: newFeeValue }
            }
            return fee
          })

          const monthlyFees = feesList.find(fee => fee.frequency === "monthly")

          const totalMonthlyFees = monthlyFees?.value ? monthlyFees?.value * term : 0

          const feesTotal = feesList.reduce(
            (accum, fee) => {
              if (fee.percentage) {
                return (fee.value / 100 * newLoanDetails.loanAmount) + accum
              }

              // if (fee.frequency === "monthly") return fee.value * term + accum
              if (fee.frequency === "monthly") return accum

              if (fee.capitalised) return fee.value + accum

              return accum
            }, 0);

          const loanAmount = isCommercial ?
            handleLoanAmountCommercial(
              feesTotal,
              defaultBrokerage?.value,
            ) :
            handleLoanAmountConsumer(
              feesTotal
            )

          const loadingList = calculateLoading(product.loading, {
            asset,
            loanDetails: {
              ...loanDetails,
              ...{
                loanAmount: loanAmount,
                brokerageRate: defaultBrokerage?.value,
                deposit: 100 - (assetValue - deposit) / assetValue * 100
              },
            },
            entity,
            customerProfile,
          });

          const loadingTotal = loadingList.reduce((accum, item) => {
            return item.result === true ? item.value + accum : accum;
          }, 0);

          let RATE

          // if(product.lender === "MoneyPlace") console.log(tier.criteria)

          if (Array.isArray(tier.rate)) {
            // console.log(tier.criteria)
            const scoreCriteria =
              tier.criteria.find(criteria =>
                criteria.attribute === "score")

            // console.log({ scoreCriteria })

            if (scoreCriteria) {
              if (
                scoreCriteria.value.min &&
                creditProfile?.score >= scoreCriteria.value.min &&
                creditProfile?.score < scoreCriteria.value.max) {
                const percentage = (
                  ((creditProfile.score - scoreCriteria.value.max) /
                    (scoreCriteria.value.max - scoreCriteria.value.min) *
                    100) + 100
                )
                RATE = percentage / 100 * (tier.rate[1] - tier.rate[0]) + tier.rate[0]
              } else {
                RATE = tier.rate[0]
              }
            }
          } else {
            // console.log("not array", tier.rate)
            RATE = tier.rate
          }

          // console.log({ RATE })

          const quoteDetails = calculateQuote(
            loanAmount,
            balloon,
            term,
            RATE + loadingTotal
          );

          const comparisonRateRaw = calculateComparisonRate(
            term,
            -quoteDetails.repayments,
            loanAmount - feesTotal,
            balloon,
            0,
            RATE / 100
          )

          const comparisonRate = comparisonRateRaw * 12 * 100

          // console.log(RATE + loadingTotal, comparisonRate)

          const LOAN_DETAILS = {
            ...loanDetails,
            ...{
              loanAmount: loanAmount,
              repayments: quoteDetails.repayments,
              deposit: 100 - (assetValue - deposit) / assetValue * 100
            },
          };

          const productDetails = {
            lender: product.lender,
            productName: tier.name,
            name: tier.name,
            logo: product.logoURL,

          };

          const criteriaResults = evaluateCriteria(tier.criteria, {
            asset,
            loanDetails: LOAN_DETAILS,
            entity,
            customer: customers?.[0],
            customerProfile,
            creditProfile,
            employer,
          }).filter((result) => {
            // console.log({ result })
            if (!result.criteria) return result;


            if (result.criteria.reduce((boolean, criteria) => {
              return criteria.result ? boolean : false;
            }, true)) return result;

            if (!result.criteria.reduce((boolean, criteria) => {
              return criteria.result ? boolean : false;
            }, false) && result.criteria.some(
              (item) => result.primary === item.attribute
            )) {
              return result.criteria.find(
                (item) => result.primary === item.attribute
              );
            }

            // if (
            //   result.criteria.some(
            //     (item) => result.primary === item.attribute && item.result
            //   )

            // )
            //   return result;

            return result;

          })

          // console.log({ criteriaResults })

          const overallResult = criteriaResults.reduce((count, item) => {
            // console.log(item);
            // if (item?.result?.[0]) {
            //   const compoundResult = item.result.reduce((boolean, criteria) => {
            //     return criteria.result ? boolean : false;
            //   }, true);

            //   console.log("compoundResult", compoundResult)

            //   return compoundResult ? ++count : count;
            // }

            return item?.result ? ++count : count;
          }, 0);

          // console.log({ criteriaResults });
          // console.log("feesList", feesList);

          function tierInfo() {
            if (tier?.info?.[0]) return tier?.info

            return []
          }

          return _.merge(productDetails, {
            comparisonRate: isCommercial ? null : comparisonRate,
            financeAmount: 1 * loanAmount,
            loanAmount: quoteDetails.repayments * term,
            repayments: quoteDetails.repayments + (monthlyFees?.value ? monthlyFees?.value : 0),
            brokerage: defaultBrokerage,
            monthlyFees,
            fees: feesList,
            feesTotal: feesTotal,
            loading: { loadingList, loadingTotal },
            criteriaResults,
            // fit: (overallResult / criteriaResults.length) * 100,
            fit: criteriaResults.length - overallResult,
            criteria: `${overallResult}/${criteriaResults.length}`,
            qualification: criteriaResults.length - overallResult,
            // term,
            // assetValue,
            // deposit,
            productType: tier.productType,
            labels: [tier.productType],
            rate: (RATE + loadingTotal) * 1,
            info: [...tierInfo(), ...product?.info],
            date: product.date,
            isCommercial
          }
          );
        }
      });

    });

    const list = _.chain(productList)
      .flatten()
      .filter((product) => product.repayments > 0)
      // .filter((product) => product.fit <= 3)
      .filter((product) => filterByParameters.includes(product?.lender))
      .filter((product) => filterByProductType.includes(product?.productType))
      .filter((product) => filterByCriteria(product, criteriaFilterValue))
      // .filter((product) => filterByFit(product.fit, fitFilterValue))
      // .orderBy(...orderByParametersList)
      .value();

    const groupedList = _.mapValues(_.groupBy(list, 'lender'))
    const prioritisedList = Object.keys(groupedList).reduce((acc, products) => {
      // console.log(groupedList)
      const bestProduct = _.orderBy(groupedList[products],
        ["fit", "rate"],
        ["asc", "asc"])
      // console.log({ bestProduct })
      // console.log({bestProduct})
      // acc.push(groupedList[products][0])
      acc.push(bestProduct[0])
      return acc
    }, [])

    mixpanel.track(`Quote: Filtered products`, {
      applicationId: _id,
      numberOfProducts: prioritisedList?.length,
      lenders: prioritisedList.map(i => i?.lender)
    })

    setProductsMap(_.orderBy(prioritisedList,
      ...orderByParametersList
    ));
  }

  function filterByCriteria(product, criteriaFilterValue) {

    const matches = (Array.isArray(criteriaFilterValue) ? criteriaFilterValue : [criteriaFilterValue]).map((criteria) => {
      return product.criteriaResults.find(result => {
        return criteria === result.attribute

      })?.result
    });

    return matches.every(i => i === true || i === undefined)
  }

  const calculateQuote = (loanAmount, balloon, term, rate) => {
    const monthlyRate = getMonthlyRate(rate);
    const rpmts = calculateRepayments(
      monthlyRate,
      term,
      -loanAmount,
      balloon / 100 * assetValue,
      isCommercial ? 1 : 0
    );
    const roundedRepayments = (Math.round(rpmts * 100) / 100).toFixed(2);



    return {
      repayments: roundedRepayments * 1,
    };
  };

  function handleOrderBy(event) {
    if (user.status === "new" && event?.target?.value?.[0] === "rate") {
      setOrderByParameters([event.target.value]);
      const params = [event.target.value].map((i) => {
        return i[0];
      });
      const order = [event.target.value].map((i) => {
        return i[1];
      });

      setOrderByParametersList([params, order]);
      mixpanel.track(`Demo: Step 4 - Show criteria`)
      return dispatch(saveValue({ walkthroughIndex: 3 }))
    }

    setOrderByParameters(event.target.value);
    const params = event.target.value.map((i) => {
      return i[0];
    });
    const order = event.target.value.map((i) => {
      return i[1];
    });

    setOrderByParametersList([params, order]);
  }

  function handleFilterBy(event) {
    setFilterByParameters(event.target.value);
  }

  function handleFilterByProductType(event) {
    setFilterByProductType(event.target.value);
  }

  function handleFilterByFit(event) {
    setFitFilterValue(event.target.value);
  }

  function handleFilterByCriteria(event) {

    if (user?.status === "new") {
      if (event.target.value === "assetType") {
        setCriteriaFilterValue([event.target.value]);
        if (user?.walkthroughIndex < 6) {
          dispatch(saveValue({ walkthroughIndex: 6 }))
          mixpanel.track(`Demo: Step 7 - Credit score`)
        }
        return
      }
    }
    setCriteriaFilterValue(event.target.value);
  }

  function filterByFit(fit, filterValue) {
    if (parseInt(filterValue) == 100 && fit === 0) return true
    if (filterValue === "Show all") return true
    if (fit <= parseInt(filterValue) && parseInt(filterValue) !== 100) return true
  }

  useEffect(() => {
    evaluateProducts();
  }, [
    customers,
    assetType,
    ageOfAsset,
    balloon,
    term,
    entity,
    orderByParameters,
    filterByParameters,
    filterByProductType,
    fitFilterValue,
    typeOfSale,
    criteriaFilterValue,
    // assetValue,
    deposit,
  ]);

  const debounced = debounce(evaluateProducts, 500)

  useEffect(() => {
    debounced()
  }, [
    assetValue,
    // deposit,
  ]);

  useEffect(() => {
    setOrderByParametersList([
      ['fit', 'asc'],
      ['repayments', 'asc'],
    ])
  }, [])

  const productsList = useMemo(() => productsMap?.slice(0, 50).map((productDetails, i) => (
    <Product key={nanoid()} productDetails={productDetails} screenSize={screenSize} />
  )), [productsMap])

  return (
    <>
      <Stack direction="row"
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "baseline",
          // background: "#fff" 
        }}
      >

        <Stack direction="row">
          <ProductSelectorFilterBy
            list={lenderList}
            handleFilterBy={handleFilterBy}
            value={filterByParameters}
            title="Lenders"
            multiple
            screenSize={screenSize}
          />
          <ProductSelectorFilterBy
            list={productTypes}
            handleFilterBy={handleFilterByProductType}
            value={filterByProductType}
            title="Product type"
            multiple
            screenSize={screenSize}
          />
          {/* <ProductSelectorFilterBy
            list={["Show all", "100% match", "1 criteria off", "2 criteria off", "3 criteria off"]}
            handleFilterBy={handleFilterByFit}
            value={fitFilterValue}
            title="How qualified"
            multiple={false}
          /> */}
          <ProductSelectorFilterBy
            list={criteriaFilterList}
            handleFilterBy={handleFilterByCriteria}
            value={criteriaFilterValue}
            title="Must match"
            multiple
            screenSize={screenSize}
          />
          {/* <ProductSelectorFilterBy
            list={assetTypesList}
            handleFilterBy={handleFilterByAssetType}
            value={filterByAssetType}
            title="Asset type"
            multiple
          /> */}
        </Stack>

        <ProductSelectorOrderBy
          handleOrderBy={handleOrderBy}
          value={orderByParameters}
          screenSize={screenSize}
        />

      </Stack>
      {/* {loadingProducts && <LinearProgress
        style={{
          position: "absolute",
          margin: "-11px 0 5px 0",
          left: "20px",
          right: "20px"
        }}
      />} */}

      <Stack >
        {productsList}
      </Stack>

      {!productsMap?.[0] && <Stack justifyContent="center" alignItems="center"
        style={{ height: "200px" }}
      >
        <Typography>No matching products</Typography>
      </Stack>
      }

      < Refer productsMap={productsMap} quoteDetails={{ term }} openReferModal={openReferModal} />
    </>
  );
}
