import { typeOfSaleList } from "src/constants";
import criteria from "src/utils/criteria";

const {
  livingArrangements,
  termIsLessThan,
  termIsGreaterThan,
  typeOfSale,
  hasDeposit,
  assetValueBetween,
  assetType,
  ageOfAssetGreaterThan,
  ageOfAssetLessThan,
  ageOfAssetBetween,
  ageOfAssetAtEndLessThan,
  loanAmountLessThan,
  loanAmountBetween,
  timeInBusinessLessThan,
  timeInBusinessGreaterThan,
  gstRegistrationLessThan,
  gstRegistrationGreaterThan,
  compound,
  creditScoreGreaterThan
} = criteria;

function propertyOwnerList(l) {
  return l.map(product => {

    return {
      ...product,
      criteria: [
        ...product.criteria,
        loanAmountBetween(2000, 150000),
        livingArrangements(["Property owner"]),
      ]
    }
  })
}

function nonPopertyOwnerList(l) {
  return l.map(product => {

    return {
      ...product,
      criteria: [
        ...product.criteria,
        loanAmountBetween(2000, 75000),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
      ]
    }
  })
}

export default [
  ...propertyOwnerList(base()),
  ...nonPopertyOwnerList(base())
]

function base() {
  return [
    {
      name: "Low doc - Tier 3",
      rate: 8.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599)
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 8.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType([
          "Trailers and Caravans"
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(599)
      ],
    },

    {
      name: "Low doc - Tier 3",
      rate: 8.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType([
          "Industrial Plant & Printing",
        ]),
        ageOfAssetAtEndLessThan(21),
        creditScoreGreaterThan(599)
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 8.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType([
          "Medical Equipment",
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599)
      ],
    },

    {
      name: "Low doc - Tier 3",
      rate: 10.25,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(6),
        assetType([
          "IT, Computer & Audio Equipment",
          "Office Equipment"
        ]),
        creditScoreGreaterThan(599)
      ],
    },

    {
      name: "Low doc - Tier 3",
      rate: 14.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType([
          "Restaurant & Catering Equipment",
        ]),
        ageOfAssetAtEndLessThan(4),
        creditScoreGreaterThan(599)
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 14.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType([
          "Office Furniture",
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599)
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 14.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        assetType([
          "Office Fitout",
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599)
      ],
    },

    {
      name: "Low doc - Tier 3",
      rate: 8.90,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(8),
        assetType([
          "Solar",
        ]),
        creditScoreGreaterThan(599)
      ],
    },

    {
      name: "Low doc - Tier 4",
      rate: 11.10,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(12),
        ageOfAssetAtEndLessThan(8),
        assetType([
          "Other",
        ]),
        creditScoreGreaterThan(599)
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 9.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetGreaterThan(11),
        assetType([
          "Agricultural & Landscaping Equipment (wheeled or tracked)",
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599)
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 9.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetGreaterThan(11),
        assetType([
          "Trailers and Caravans",
        ]),
        ageOfAssetAtEndLessThan(26),
        creditScoreGreaterThan(599)
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 9.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetGreaterThan(11),
        assetType([
          "Industrial Plant & Printing",
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599)
      ],
    },
    {
      name: "Low doc - Tier 3",
      rate: 9.95,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetGreaterThan(11),
        assetType([
          "Medical Equipment",
        ]),
        ageOfAssetAtEndLessThan(6),
        creditScoreGreaterThan(599)
      ],
    },

    {
      name: "Low doc - Tier 4",
      rate: 12.10,
      productType: "Low doc",
      criteria: [
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetGreaterThan(11),
        ageOfAssetAtEndLessThan(8),
        assetType([
          "Other",
        ]),
        creditScoreGreaterThan(599)
      ],
    },
  ]
}